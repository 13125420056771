import React, { useState, useContext } from "react";
import logo from 'assets/reputation/logo.svg';
import { useHistory } from "react-router-dom";
import ContactUs from 'pages/contact_us';
import { EntityRole, LOGOUT_URL } from 'utils/constants'
import { FE_URL } from "utils/constants";
import {Navbar, Container, Nav, NavDropdown, Dropdown, NavItem, NavLink} from 'react-bootstrap';
import {AuthContext} from 'auth_context';
import { generic_post_api } from "api/generic_api";
import { isVendor } from "utils/helpers";

function Header(props) {
    
    const [is_collapsed_view, isCollapsedView] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [displayContactUs, setDisplayContactUs] = useState(false)
    const { logged_in_entity_role, setLoggedInEntityRole } = useContext(AuthContext);
    
    let history = useHistory();

    const logout = () => {

        generic_post_api({}, LOGOUT_URL)
        .then(
            (result) => {
                if (result.status === 200) {
                    localStorage.setItem('token', '');
                    setLoggedInEntityRole(null);
                    history.push("/login");
                }
            }
        )
        .catch(error => {
            localStorage.setItem('token', '');
            setLoggedInEntityRole(null);
            history.push("/login"); 
        });

    }

    const navigate = (page) => {
        history.push(page); 
    }

    return(
        <div>
             <ContactUs 
                displayStatus={ displayContactUs } 
                changeDisplayStatus={ setDisplayContactUs }
            />

            {/* collapseOnSelect alone seems to not work (maybe it does. haven't tested). Added additional logic with 'onSelect' and onClick on Navbar.Toggle to close nav after click */}
            {/* Setting to fixed="top" ensures that expanded menu does not push down content */}
            <Navbar bg="light" id="navBar" fixed="top" collapseOnSelect expanded={expanded} expand="lg"  onToggle={ () =>isCollapsedView(true) } onSelect={ () => setExpanded(false) }> 
                <Container>
                    <Navbar.Brand href="/home" className="me-3 mb-3"><img src={logo} class="brandLogo"/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")}/>
                    
                    {/* Logged out  */}
                    {   (logged_in_entity_role === null || logged_in_entity_role === EntityRole.UNKNOWN) &&

                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link href='/login' eventKey="1" className="me-3">Sign in</Nav.Link>
                                <Nav.Link eventKey="2" onClick={() => setDisplayContactUs(true)} className="me-3">Contact us</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    }
                    {/* Logged out  */}
                    {   (logged_in_entity_role === EntityRole.CODE_USER) &&
                        <>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link href='/code/sf_search' eventKey="1" className="me-3">Search</Nav.Link>
                                <Nav.Link href='/code/insights' eventKey="2" className="me-3">Insights</Nav.Link>
                                {/* <Nav.Link eventKey="4" onClick={() => setDisplayContactUs(true)} className="me-3">Contact us</Nav.Link>
                                <Nav.Link href='/account' eventKey="5" className="me-3">Account</Nav.Link>
                                <Nav.Link eventKey="6" onClick={logout} className="me-3">Log out</Nav.Link> */}
                            </Nav>
                        </Navbar.Collapse>
                        {  !is_collapsed_view ?
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="ml-auto">
                                    <Dropdown as={NavItem}>
                                        <Dropdown.Toggle as={NavLink}><i class="fa-solid fa-sliders fa-lg" /></Dropdown.Toggle>
                                            {/* <img src={settings} class="settingsIcon"/> */}
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => navigate("/account")}>Account</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setDisplayContactUs(true)}>Contact us</Dropdown.Item>
                                            <Dropdown.Item onClick={ logout }>Log out</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Nav>
                            </Navbar.Collapse>
                        :
                            <Navbar.Collapse id="basic-navbar-nav" className="mt-2">
                                <NavDropdown title="Settings" id="basic-nav-dropdown" className="fw-light">
                                    <NavDropdown.Item onClick={() => navigate("/account")} >Account</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => setDisplayContactUs(true)}>Contact us</NavDropdown.Item>
                                    <NavDropdown.Item onClick={ logout }>Log out</NavDropdown.Item>
                                </NavDropdown>
                            </Navbar.Collapse>
                        }
                        </>
                    }
                    
                    {/* logged in agent */}
                    {
                        logged_in_entity_role === EntityRole.AGENT &&
                            <>
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="me-auto">
                                        <Nav.Link eventKey="3" onClick={() => navigate('/')} className="me-3">Dashboard</Nav.Link>

                                        <Dropdown as={NavItem} className="me-3">
                                            <Dropdown.Toggle as={NavLink}>Vendors</Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item eventKey="7" onClick={() => navigate("/agent/favorite_vendors")}>My vendors</Dropdown.Item>
                                                <Dropdown.Item eventKey="8" onClick={() => navigate("/agent/vendor_search")}>Search vendors by name</Dropdown.Item>
                                                <Dropdown.Item eventKey="9" onClick={() => navigate("/agent/vendor_discover")}>Find new vendors</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        {/* <Nav.Link eventKey="4" onClick={() => navigate('/agent/favorite_vendors')} className="me-3">Vendors</Nav.Link> */}
                                        <Nav.Link eventKey="5" onClick={() => navigate("/agent/contacts")} className="me-3">Clients</Nav.Link>
                                        <Nav.Link eventKey="6" onClick={() => navigate("/agent/agent_friends")} className="me-3">Agent network</Nav.Link>
                                        <Nav.Link eventKey="7" onClick={() => navigate("/property_inspector")} className="me-3">Inspector</Nav.Link> 

                                        {/* <Dropdown as={NavItem}>
                                            <Dropdown.Toggle as={NavLink}>Resources</Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item eventKey="7" onClick={() => navigate("/property_inspector")}>Property inspector</Dropdown.Item>
                                                <Dropdown.Item eventKey="8" onClick={() => navigate("/permit_stats")}>Construction stats</Dropdown.Item>
                                                <Dropdown.Item eventKey="9" onClick={() => navigate("/guides")}>Guides</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown> */}
                                    </Nav>
                                </Navbar.Collapse>
                                {  !is_collapsed_view ?
                                    <Navbar.Collapse id="basic-navbar-nav">
                                        <Nav className="ml-auto">
                                            <Dropdown as={NavItem}>
                                                <Dropdown.Toggle as={NavLink}><i class="fa-solid fa-sliders settingsIcon fa-lg" /></Dropdown.Toggle>
                                                    {/* <img src={settings} class="settingsIcon"/> */}
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => navigate("/account")}>Account</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => setDisplayContactUs(true)}>Contact us</Dropdown.Item>
                                                    <Dropdown.Item onClick={ logout }>Log out</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Nav>
                                    </Navbar.Collapse>
                                :
                                    <Navbar.Collapse id="basic-navbar-nav" className="mt-2">
                                        <NavDropdown title="Settings" id="basic-nav-dropdown" className="fw-light">
                                            <NavDropdown.Item onClick={() => navigate("/account")} >Account</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => setDisplayContactUs(true)}>Contact us</NavDropdown.Item>
                                            <NavDropdown.Item onClick={ logout }>Log out</NavDropdown.Item>
                                        </NavDropdown>
                                    </Navbar.Collapse>
                                }
                            </>
                    }

                    {/* logged in client */}
                   {
                        logged_in_entity_role === EntityRole.AGENT_CONTACT &&
                            <>
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="me-auto">
                                        <Nav.Link  eventKey="3" onClick={() => navigate("/")} className="me-3">Dashboard</Nav.Link>
                                        {/* <Nav.Link onClick={() => navigate('/client_vendors')}  className="me-3">Vendors</Nav.Link> */}
                                        <Dropdown as={NavItem} className="me-3">
                                            <Dropdown.Toggle as={NavLink}>Vendors</Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item  eventKey="4" onClick={() => navigate("/contact/agent_vendors")}>Suggested vendors</Dropdown.Item>
                                                <Dropdown.Item  eventKey="5" onClick={() => navigate("/contact/vendor_discover")}>Find vendors by category</Dropdown.Item>
                                                <Dropdown.Item eventKey="8" onClick={() => navigate("/contact/vendor_search")}>Search vendors by name</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Dropdown as={NavItem} className="me-3">
                                            <Dropdown.Toggle as={NavLink}>Resources</Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item  eventKey="6" onClick={() => navigate("/property_inspector")}>Property inspector</Dropdown.Item>
                                                <Dropdown.Item  eventKey="7" onClick={() => navigate("/permit_stats")}>Construction stats</Dropdown.Item>
                                                <Dropdown.Item  eventKey="8" onClick={() => navigate("/guides")}>Guides</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Nav>
                                </Navbar.Collapse>

                                {  !is_collapsed_view ?
                                    <Navbar.Collapse id="basic-navbar-nav">
                                        <Nav className="ml-auto">
                                            <Dropdown as={NavItem}>
                                                <Dropdown.Toggle as={NavLink}><i class="fa-solid fa-sliders settingsIcon fa-lg" /></Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item  eventKey="9" onClick={() => setDisplayContactUs(true)}>Contact us</Dropdown.Item>
                                                    <Dropdown.Item  eventKey="10" onClick={logout}>Log out</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Nav>
                                    </Navbar.Collapse>
                                :
                                    <Navbar.Collapse id="basic-navbar-nav" className="mt-2">
                                        <NavDropdown title="Settings" id="basic-nav-dropdown" className="fw-light">
                                            <NavDropdown.Item onClick={() => setDisplayContactUs(true)}>Contact us</NavDropdown.Item>
                                            <NavDropdown.Item onClick={ logout }>Log out</NavDropdown.Item>
                                        </NavDropdown>
                                    </Navbar.Collapse>
                                }
                            </>
                    }
                    {/* logged in vendor */}
                   {
                        isVendor(logged_in_entity_role) &&
                            <>
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="me-auto">
                                        <Nav.Link  eventKey="3" onClick={() => navigate("/")} className="me-3">Dashboard</Nav.Link>
                                        <Nav.Link  eventKey="4" onClick={() => navigate("/vendor/requests")} className="me-3">Requests</Nav.Link>
                                        <Dropdown as={NavItem} className="me-3">
                                            <Dropdown.Toggle as={NavLink}>Search</Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item  eventKey="5" onClick={() => navigate("/vendor/vendor_search")}>Search by name</Dropdown.Item>
                                                <Dropdown.Item  eventKey="6" onClick={() => navigate("/vendor/vetted_vendors")}>Trusted vendors</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Dropdown as={NavItem} className="me-3">
                                            <Dropdown.Toggle as={NavLink}>Resources</Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item  eventKey="6" onClick={() => navigate("/property_inspector")}>Property inspector</Dropdown.Item>
                                                <Dropdown.Item  eventKey="7" onClick={() => navigate("/permit_stats")}>Construction stats</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Nav>
                                </Navbar.Collapse>

                                {  !is_collapsed_view ?
                                    <Navbar.Collapse id="basic-navbar-nav">
                                        <Nav className="ml-auto">
                                            <Dropdown as={NavItem}>
                                                <Dropdown.Toggle as={NavLink}><i class="fa-solid fa-sliders settingsIcon fa-lg" /></Dropdown.Toggle>
                                                    {/* <img src={settings} class="settingsIcon"/> */}
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => navigate("/vendor/account")}>Account</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => setDisplayContactUs(true)}>Contact us</Dropdown.Item>
                                                    <Dropdown.Item onClick={ logout }>Log out</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Nav>
                                    </Navbar.Collapse>
                                :
                                    <Navbar.Collapse id="basic-navbar-nav" className="mt-2">
                                        <NavDropdown title="Settings" id="basic-nav-dropdown" className="fw-light">
                                            <NavDropdown.Item onClick={() => navigate("/vendor/account")} >Account</NavDropdown.Item>
                                            <NavDropdown.Item onClick={() => setDisplayContactUs(true)}>Contact us</NavDropdown.Item>
                                            <NavDropdown.Item onClick={ logout }>Log out</NavDropdown.Item>
                                        </NavDropdown>
                                    </Navbar.Collapse>
                                }
                            </>
                    }
                </Container>
            </Navbar>
        </div>
    );
    
}

export default Header;