import { generic_get_api } from './generic_api';

import {
    BASIC_ACCOUNT_INFO_URL,
} from 'utils/constants';

export const basicAccountInfo = () => {

    return generic_get_api(BASIC_ACCOUNT_INFO_URL, {})
    .then(data=>data);
}