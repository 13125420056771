/* global google */
// do not delete above line. Needed for google address to function

import React, {Component} from "react";
import ContactUs from 'pages/contact_us';
import {trackEvent, EventNames} from 'utils/mixpanel';
import toolTip_16 from 'assets/reputation/tooltip_blue_i.svg';
import magnifying from 'assets/reputation/magnifying_glass_100_100.png';
import {Button, Spinner, Card, OverlayTrigger, Popover} from 'react-bootstrap';
import { generic_get_api } from "api/generic_api";
import { basicAccountInfo } from "api/basic_account_info";
// import {AuthContext} from 'auth_context';

import {
    PROPERTY_INFO_URL,
    PropertyClassMap,
    FE_URL,
} from 'utils/constants';


class PropertyInspector extends Component {

    constructor(props) {
        super(props);

        let result_type = null;
        let tax_info = null;
        let building_permits = null;
        let plumbing_permits = null;
        let electrical_permits = null;
        let block_lot_units = null;
        let formatted_address = null;

         // to make back button work and search results re-instated
         if (props.location.state){
            if (props.location.state.result_type) {
                result_type = props.location.state.result_type;
            }
            if (props.location.state.tax_info) {
                tax_info = props.location.state.tax_info;
            }
            if (props.location.state.building_permits) {
                building_permits = props.location.state.building_permits;
            }
            if (props.location.state.plumbing_permits) {
                plumbing_permits = props.location.state.plumbing_permits;
            }
            if (props.location.state.electrical_permits) {
                electrical_permits = props.location.state.electrical_permits;
            }
            if (props.location.state.block_lot_units) {
                block_lot_units = props.location.state.block_lot_units;
            }
            if (props.location.state.formatted_address) {
                formatted_address = props.location.state.formatted_address;
            }
         }

        this.state = {
            address: null,
            searching: false,
            searchResult: {
                result_type: result_type,
                tax_info: tax_info,
                building_permits: building_permits,
                plumbing_permits: plumbing_permits,
                electrical_permits: electrical_permits,
                block_lot_units: block_lot_units,
                formatted_address: formatted_address,
            },
        }

        this.setAddress = this.setAddress.bind(this);
        this.searchButtonClick = this.searchButtonClick.bind(this);
        this.unitSearchClick = this.unitSearchClick.bind(this);
        this.navigateToVendorDetail = this.navigateToVendorDetail.bind(this);
    }

    navigateToVendorDetail(vendor_id, role) {
        // to make back button clicks work

        // This error case should really not be happening
        if (this.state.searchResult) {
            this.props.history.replace(
                this.props.history.location.pathname, 
                {
                    result_type: this.state.searchResult.result_type,
                    tax_info: this.state.searchResult.tax_info,
                    building_permits: this.state.searchResult.building_permits,
                    plumbing_permits: this.state.searchResult.plumbing_permits,
                    electrical_permits: this.state.searchResult.electrical_permits,
                    block_lot_units: this.state.searchResult.block_lot_units,
                    formatted_address: this.state.searchResult.formatted_address,
                }
            );

            let details_url = '/vendor_detail?ID=' + vendor_id + "&role=" + role;
            this.props.history.push(details_url);
        }
    }

    setAddress(address) {
        this.setState({
            address: address,
        })
    }

    unitSearchClick(sf_treasurer_property_info_id, unit_number) {

        if (!this.state.address) {
            return;
        }

        this.setState({searching: true});

        let formatted_address = "Unit " + unit_number + ", " + this.state.address.formatted_address

        let request = {
            "requestType": 'propertyId',
            "formattedAddress": formatted_address,
            "propertyId": sf_treasurer_property_info_id,
        }

        generic_get_api(PROPERTY_INFO_URL, request)
        .then(
            data => {
                this.setState({
                    searchResult: {
                        result_type: data.result_type,
                        tax_info: data.tax_info,
                        building_permits: data.building_permits,
                        plumbing_permits: data.plumbing_permits,
                        electrical_permits: data.electrical_permits,
                        block_lot_units: data.block_lot_units,
                        formatted_address: data.formatted_address,
                    },
                    searching: false,
                });
                window.scrollTo(0, 0);
            }
        )
        .catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            }
            this.setState({searching: false});
        });
    }

    searchButtonClick() {
        if (!this.state.address) {
            return;
        }
        this.setState({searching: true});

        let request = {
            "requestType": 'address',
            "streetNumber": this.state.address.streetNumber,
            "streetName": this.state.address.streetName,
            "formattedAddress": this.state.address.formatted_address,
            "city": this.state.address.city,
            "state": this.state.address.state,
        }

        generic_get_api(PROPERTY_INFO_URL, request)
        .then(
            data => {
                this.setState({
                    searchResult: {
                        result_type: data.result_type,
                        tax_info: data.tax_info,
                        building_permits: data.building_permits,
                        plumbing_permits: data.plumbing_permits,
                        electrical_permits: data.electrical_permits,
                        block_lot_units: data.block_lot_units,
                        formatted_address: data.formatted_address,
                    },
                    searching: false,
                });
            })
        .catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            }
            this.setState({searching: false});
        });
    }
    // static contextType = AuthContext;

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'property_inspector'})
        document.title = "Property Information"

        basicAccountInfo()
        .then(data=>{
        })
        .catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            }
        });
    }

    render() {
        const {searchResult} = this.state;
        return(
            <div class="propertyInfoEnvelope topOfPageMargin">
                <div class="propertyInfo">
                    
                    <ContactUs 
                        displayStatus={this.state.displayContactUs} 
                        changeDisplayStatus={this.setContactUsDisplay}
                    />

                    <div class="container propertyInspectorHeader">
                        <div class="display-6 mb-3">Construction permit inspector</div>

                        <Card>
                            <Card.Body>
                                <Card.Text className="ms-3">
                                    <p>Get a detailed view of a San Francisco property's permit history and assessor recorder information.</p>
                                    <p>Permit data includes completed, canceled and expired permits, starting from ca. 1980.</p>
                                </Card.Text>
                                <div class="container">
                                    <div class="row ">
                                    <AddressSection 
                                        setAddress={ this.setAddress }
                                        address={ this.state.address }
                                        searchButtonClick={ this.searchButtonClick }
                                        searching={ this.state.searching }
                                        />
                                    </div>
                                </div>
                                </Card.Body>
                            </Card>
                    </div>

                    { searchResult && searchResult.result_type == 'address_match' &&
                        <>
                            <div class="container resultHeaderSection">
                                <div class="col_2 fs-4 text-info-emphasis ">
                                    {searchResult.formatted_address}&nbsp;
                                        <OverlayTrigger key="propertyInfoDisclaimer" placement="auto"
                                            overlay={ 
                                                <Popover id="propertyInfoDisclaimer">
                                                    <Popover.Header as="h3">Data disclaimer</Popover.Header>
                                                    <Popover.Body>
                                                        This data is provided for planning and educational purposes only. 
                                                        It relies on a variety of outside data sources, their correctness and completeness. 
                                                        Missing, mismatched or outdated data may be possible. The output of the tool is not an assessment 
                                                        of the property nor its condition.
                                                    </Popover.Body>
                                                </Popover>
                                            }>
                                                <img src={toolTip_16} class="tooltipImage mb-1"/>
                                        </OverlayTrigger>
                                </div>
                            </div>
                            <TaxInfo
                                tax_info={searchResult.tax_info} 
                            />

                            {searchResult.building_permits && searchResult.building_permits && searchResult.building_permits &&
                                
                                <div class="container col_2 permitOverviewSection">
                                    <div class="row gy-3">
                                        <div class="col-12 col-md-4"> 
                                            <Card bg="light">
                                                <Card.Body>
                                                    <Card.Text><div fw-bold><a href="#buildingPermits">Building permits ({searchResult.building_permits.length})</a></div></Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div class="col-12 col-md-4">
                                            <Card bg="light">
                                                <Card.Body>
                                                    <Card.Text><div fw-bold><a href="#plumbingPermits">Plumbing permits ({searchResult.plumbing_permits.length})</a></div></Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div class="col-12 col-md-4">
                                            <Card bg="light">
                                                <Card.Body>
                                                    <Card.Text><div fw-bold><a href="#electricalPermits">Electrical permits ({searchResult.electrical_permits.length})</a></div></Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            }
                            <PermitSection 
                                permitList={searchResult.building_permits}
                                sectionTitle={'Building permits'}
                                sectionId={'buildingPermits'}
                                navigateToVendorDetail={this.navigateToVendorDetail}
                            />

                            <PermitSection 
                                permitList={searchResult.plumbing_permits}
                                sectionTitle={'Plumbing permits'}
                                sectionId={'plumbingPermits'}
                                navigateToVendorDetail={this.navigateToVendorDetail}
                            />

                            <PermitSection 
                                permitList={searchResult.electrical_permits}
                                sectionTitle={'Electrical permits'}
                                sectionId={'electricalPermits'}
                                navigateToVendorDetail={this.navigateToVendorDetail}
                            />
                        </>
                    }
                    { searchResult && searchResult.result_type == 'no_match' &&
                        <div class="container text-center mt-4">
                             <div>
                                {searchResult.formatted_address}
                            </div>
                            <div class="fw-bold">We didn't find any results.</div>
                        </div>
                    }
                    { searchResult && searchResult.result_type == 'units_list' &&
                        <div class="resultHeaderSection  container">
                             <div>
                                {searchResult.formatted_address}
                            </div>
                            <div class="fw-bold">We found multiple matching units</div>
                           
                            <div class="container gx-0 mt-3"> 
                                <div class="row gy-2">
                                    {
                                        searchResult.block_lot_units.map(
                                            (block_lot_unit) => {
                                                return(                                             
                                                    <div class="col-6 col-md-3 text-decoration-underline" role="button" onClick={() => this.unitSearchClick(block_lot_unit.sf_treasurer_property_info_id, block_lot_unit.unit)}>
                                                        {block_lot_unit.unit} &nbsp;<img src={magnifying} class="apartmentMag" /> 
                                                    </div>                   
                                                );
                                            }
                                        )
                                    }
                                 </div>
                            </div>
                        </div>
                    }
                    <div class="disclaimer">
                       
                    </div>
                </div>
            </div>
        )
    }
}

function PermitSection(props) {
    const { permitList, sectionTitle, sectionId, navigateToVendorDetail } = props;

    if (!permitList || permitList.length <= 0) {
        return null;
    }

    return (
        <div class="container permitSection">
            <div class="fs-5 fw-bold"><a id={sectionId}/>{sectionTitle}</div>
            {
                permitList.map(
                    (permit) => {
                        return( <div class="permitWrapper">
                                    <div><hr></hr></div>
                                    <Permit 
                                        permit={permit} 
                                        navigateToVendorDetail={navigateToVendorDetail}
                                    />
                                </div>
                        )
                    }
                )
            }
        </div>
    )
}

function Permit(props) {
    const { permit, navigateToVendorDetail } = props;

    if (!permit) { return null; }

    return(
            <div class="container gx-0">
                <div class="row gy-3">
                    <div class="col-6 col-md-3">
                        <div class="container">
                            <div class="row">Permit number</div>
                            <div class="row fw-bold">{permit.permit_number}</div>
                        </div>
                    </div>
                    <div class="col-6 col-md-3">
                        <div class="container">
                            <div class="row">Filed date</div>
                            <div class="row fw-bold">{permit.filed_date}</div>
                        </div>
                    </div>
                    <div class="col-6 col-md-3">
                        <div class="container">
                            <div class="row">Issued date</div>
                            <div class="row fw-bold">{permit.issued_date}</div>
                        </div>
                    </div>
                    <div class="col-6 col-md-3">
                        <div class="container">
                            <div class="row">Completed date</div>
                            <div class="row fw-bold">{permit.completed_date}</div>
                        </div>
                    </div>
                </div>

                <div class="row gy-3 mt-1">
                    <div class="col-6 col-md-3">
                        <div class="container">
                            <div class="row">Permit status</div>
                            <div class="row fw-bold">{permit.status}</div>
                        </div>
                    </div>
                    <div class="col-6 col-md-3">
                        <div class="container">
                            {
                                permit.estimated_cost && permit.estimated_cost > 0 &&
                                <>
                                    <div class="row">Estimated cost</div>
                                    <div class="row fw-bold">{permit.estimated_cost}</div>
                                </>
                            }
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="container">
                            <div class="row">Contractor</div>
                            <div class="row fw-bold">
                            {permit.contractor ?
                                <div class="col gx-0">
                                    <a role="button" class="text-decoration-underline" onClick={() => navigateToVendorDetail(permit.contractor.entity_id, 'CONTRACTOR')}>
                                        {permit.contractor.business_name} 
                                    </a>&nbsp;&nbsp;
                                    
                                </div>
                                :
                                 <span>N/A</span>
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row gy-3 mt-1">
                    {
                        permit.architect && 
                        
                        <div class="col-12 col-md-6">
                            <div class="container">
                                <div class="row">Architect</div>
                                <div class="row fw-bold">
                                    <div class="col gx-0">
                                    <a role="button" class="text-decoration-underline" onClick={() => navigateToVendorDetail(permit.architect.entity_id, 'ARCHITECT')}>
                                        {permit.architect.business_name} </a>&nbsp;&nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        permit.engineer && 
                        
                        <div class="col-12 col-md-6">
                            <div class="container">
                                <div class="row">Engineer</div>
                                <div class="row fw-bold">
                                    <div class="col gx-0">
                                        <a role="button" class="text-decoration-underline" onClick={() => navigateToVendorDetail(permit.engineer.entity_id, 'ENGINEER')}>
                                            {permit.engineer.business_name} </a>&nbsp;&nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    
                </div>

                <div class="row gy-3 mt-1">
                    <div class="col-12">
                        <div class="container">
                            <div class="row">{permit.description}</div>
                        </div>
                    </div>
                </div>
{/* 
                <div class="infoBox permitNumber">
                    <div class="infoBoxKey">Permit number</div>
                    <div class="infoBoxValue">{permit.permit_number}</div>
                </div>
                <div class="infoBox filedDate">
                    <div class="infoBoxKey">Filed date</div>
                    <div class="infoBoxValue">{permit.filed_date}</div>
                </div>
                <div class="infoBox issuedDate">
                    <div class="infoBoxKey">Issued date</div>
                    <div class="infoBoxValue">{permit.issued_date}</div>
                </div>
                <div class="infoBox completedDate">
                    <div class="infoBoxKey">Completed date</div>
                    <div class="infoBoxValue">{permit.completed_date}</div>
                </div>
                <div class="infoBox permitStatus">
                    <div class="infoBoxKey">Permit status</div>
                    <div class="infoBoxValue">{permit.status}</div>
                </div>
                <div class="infoBox estimatedCost">
                    <div class="infoBoxKey">Estimated cost</div>
                    <div class="infoBoxValue">{permit.estimated_cost}</div>
                </div>
                <div class="contractorInfo">
                    <div class="infoBoxKey">Contractor</div>
                    {permit.contractor ?
                        <div class="infoBoxValue underlined"> <a href={ getContractorUrl() } target="_blank">{permit.contractor.business_name} </a>&nbsp;&nbsp;<img src={magnifying} /></div>
                        
                        :
                        <div class="infoBoxValue"> N/A</div>
                    
                    }
                </div>
                <div class="description">
                    {permit.description}
                </div> */}

            </div>
        
    )
}

function TaxInfo (props) {

    const { tax_info } = props;

    const format_value = (value) =>{
        if (!value || value === 0 || value === '0') {
            return 'N/A';
        }

        return value;
    }

    if (!tax_info) { return null; }

    return (
        <div class="container taxInfo">
            <div class="row">
                <div class="col-12 fs-5 fw-bold text-info-emphasis">Tax information</div>
            </div>
            <div class="row">
                <div class="col-12"><hr></hr></div>
            </div>

            <div class="row gy-3">
                <div class="col-6 col-md-3">
                    <div class="container">
                        <div class="row">Year built</div>
                        <div class="row fw-bold">{format_value(tax_info.year_property_built)}</div>
                    </div>
                </div>
                <div class="col-6 col-md-3">
                    <div class="container">
                        <div class="row">Zoning code</div>
                        <div class="row fw-bold">{format_value(tax_info.zoning_code)}</div>
                    </div>
                </div>
                <div class="col-6 col-md-3">
                    <div class="container">
                        <div class="row"># stories</div>
                        <div class="row fw-bold">{format_value(tax_info.number_of_stories)}</div>
                    </div>
                </div>
                <div class="col-6 col-md-3">
                    <div class="container">
                        <div class="row"># units</div>
                        <div class="row fw-bold">{format_value(tax_info.number_of_units)}</div>
                    </div>
                </div>
            </div>

            <div class="row gy-3 mt-3">
                <div class="col-6 col-md-3">
                    <div class="container">
                        <div class="row">Lot area (sqf)</div>
                        <div class="row fw-bold">{format_value(tax_info.lot_area)}</div>
                    </div>
                </div>
                <div class="col-6 col-md-3">
                    <div class="container">
                        <div class="row">Property area (sqf)</div>
                        <div class="row fw-bold">{format_value(tax_info.property_area_in_square_feet)}</div>
                    </div>
                </div>
                <div class="col-6 col-md-3">
                    <div class="container">
                        <div class="row"># of rooms</div>
                        <div class="row fw-bold">{format_value(tax_info.number_of_rooms)}</div>
                    </div>
                </div>
                <div class="col-6 col-md-3">
                    <div class="container">
                        <div class="row"># of bedrooms</div>
                        <div class="row fw-bold">{format_value(tax_info.number_of_bathrooms)}</div>
                    </div>
                </div>
            </div>

            <div class="row gy-3 mt-3">
                 <div class="col-6 col-md-3">
                    <div class="container">
                        <div class="row"># of bathrooms</div>
                        <div class="row fw-bold">{format_value(tax_info.number_of_bathrooms)}</div>
                    </div>
                </div>
                <div class="col-6 col-md-3">
                    <div class="container">
                        <div class="row">Property class </div>
                        <div class="row fw-bold">
                            <div class="col ps-0">
                            {format_value(tax_info.property_class_code)}&nbsp;
                            <OverlayTrigger key="propertyClassCode" placement="auto"
                                overlay={ 
                                    <Popover id="propertyClassCode">
                                        <Popover.Header as="h3">Property class</Popover.Header>
                                        <Popover.Body>
                                            {PropertyClassMap[format_value(tax_info.property_class_code)]}
                                        </Popover.Body>
                                    </Popover>}>
                                    <img src={toolTip_16} class="tooltipImage mb-1"/>
                            </OverlayTrigger>
                            </div>
                        
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-3">
                    <div class="container">
                        <div class="row ">Last sale date</div>
                        <div class="row fw-bold">{format_value(tax_info.current_sales_date)}</div>
                    </div>
                </div>
                <div class="col-6 col-md-3">
                    <div class="container">
                        <div class="row ">Last sale price</div>
                        <div class="row fw-bold">{format_value(tax_info.current_sales_price)}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

class AddressSection extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            searchButtonEnabled: false,
        }

        this.autocompleteInput = React.createRef();
        this.autocomplete = null;
        
        this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
        this.addressChanged = this.addressChanged.bind(this);
        this.resetAddressField = this.resetAddressField.bind(this);
    }
      
    addressChanged(e) {
        this.setState({
            searchButtonEnabled: false,
            address_field: e.target.value,
        });
    }

    resetAddressField() {
        this.autocompleteInput.current.value = "";
        this.setState({
            searchButtonEnabled: false,
        });
    }

    componentDidMount() {

        const center = { lat: 37.75953994968017, lng: -122.44697911726526 };

        // Create a bounding box with sides ~10km away from the center point
        const defaultBounds = {
            north: center.lat + 0.07,
            south: center.lat - 0.07,
            east: center.lng + 0.07,
            west: center.lng - 0.07,
        };
        this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
            {"types": ["geocode"], "fields": ["address_components", "formatted_address", "name", "geometry"], bounds: defaultBounds, strictBounds: true });

        this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
    }

    handlePlaceChanged(){
        const place = this.autocomplete.getPlace();
        let streetNumber = '';
        let streetName = ''
        let city = '';
        let state = '';
        let zipCode = '';

        let i = 0;
        // let lat = place.geometry.location.lat();
        // let long = place.geometry.location.lng();

        for(; i < place.address_components.length; i++) {
            let component = place.address_components[i];
            if (component.types.includes("street_number")) {
                streetNumber = component.long_name;
            }
            else if (component.types.includes("route")) {
                streetName = component.long_name;
            }
            else if (component.types.includes("locality")) {
                city = component.long_name;
            }
            else if (component.types.includes("administrative_area_level_1")) {
                state = component.short_name;
            }
            else if (component.types.includes("postal_code")) {
                zipCode = component.long_name;
            }
        }

        this.props.setAddress({
            streetNumber: streetNumber,
            streetName: streetName,
            city: city,
            state: state,
            zipCode: zipCode,
            formatted_address: place.formatted_address,
        });

        this.setState({
            searchButtonEnabled: true,
        })
    }

    render() {
        const { searchButtonClick, searching } = this.props;
        
        return(
            <div class="container">
                <div class="row gy-3">
                    <div class="col-12 col-md-6">
                        <div class="input-group">
                            <input type="text" class="form-control" placeHolder="Property address" ref={this.autocompleteInput}  id="autocomplete" onChange={ (e) => this.addressChanged(e) }/>
                            <Button className="bg-transparent text-dark clearButton" onClick={this.resetAddressField}>
                                <i class="fa fa-times"/>
                            </Button>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        {searching? 
                            <Button variant="primary" disabled>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                Searching...
                            </Button>
                        :
                            <Button variant="primary" disabled={ !this.state.searchButtonEnabled } onClick={ searchButtonClick }>
                                Search
                            </Button>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default PropertyInspector;