/* global google */
// do not delete above line. Needed for google address to function

import { basicAccountInfo } from "api/basic_account_info";
import { generic_post_api } from "api/generic_api";
import GoogleMapReact from 'google-map-react';
import React, { Component, useEffect, useState } from "react";
import { Button, Card, Form, Modal, Offcanvas } from 'react-bootstrap';
import DatePicker from "react-datepicker"; // https://reactdatepicker.com/
import { OAK_PLANNING_SEARCH } from 'utils/constants';
import { truncateText } from "utils/helpers";
import { EventNames, trackEvent } from 'utils/mixpanel';
import { DistanceMile, OakGpClassifications, OakPlanningFileType, OakPlanningRecordType, OakZones } from 'utils/oak_planning_constants';


const SearchType = {
    NEW_SEARCH: 'new_search',
    FILTER: 'filter',
    NEXT_PAGE: 'next_page',
}


class OakPlanningSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            last_executed_search: null,
            pages: [],
            current_page: 0,
            search_string: "",
            start_date: null,
            end_date: null,
            start_index: 0,
            page_size: 10,
            session_id: null,
            proj_type_filter_map: {},
            file_type_filter_map: {},
            zone_filter_map: {},
            gp_classification_filter_map: {},
            property_address: null,
            distance_filter: "0",
            show_no_more_result_modal: false,
            show_filter_column: false,
            executing_search: false,
            show_custom_date_controls: false,
            custom_date_selection: "All",
            show_filter_bar: false,
            filter_type_display_state: {},
        };

        this.initiateNewSearch = this.initiateNewSearch.bind(this);
        this.setProjTypeFilter = this.setProjTypeFilter.bind(this);
        this.setFileTypeFilter = this.setFileTypeFilter.bind(this);
        this.setZoneFilter = this.setZoneFilter.bind(this);
        this.setGpClassificationFilter = this.setGpClassificationFilter.bind(this);
        this.initiateFilterSearch = this.initiateFilterSearch.bind(this);
        this.finalizeFilterSearch = this.finalizeFilterSearch.bind(this);
        this.setPropertyAddress = this.setPropertyAddress.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
        this.initiateNextPage = this.initiateNextPage.bind(this);
        this.finalizeNextPage = this.finalizeNextPage.bind(this);
        this.executeSearchQuery = this.executeSearchQuery.bind(this);
        this.finalizeNewSearch = this.finalizeNewSearch.bind(this);
        this.customDatePickerChanged = this.customDatePickerChanged.bind(this);
        this.setFilterTypeDisplayState = this.setFilterTypeDisplayState.bind(this);
    }

    setFilterTypeDisplayState(filter_type) {
        let cur_display_state = this.state.filter_type_display_state;
        cur_display_state[filter_type] = !cur_display_state[filter_type];
        this.setState({filter_type_display_state: cur_display_state});
    }

    setPropertyAddress(address) {
        this.setState({
            property_address: address,
            distance_filter: "0",
        });
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'oak_planning_code_search'});

        document.title = "Oakland Planning Insights";

        basicAccountInfo()
        .then(
            data => {
            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            }
        });
    }

    initiateNewSearch() {
        this.setState(
            {
                session_id: null,
                start_index: 0,
                proj_type_filter_map: {},
                file_type_filter_map: {},
                zone_filter_map: {},
                gp_classification_filter_map: {},
                distance_filter: "0",
                last_executed_search: SearchType.NEW_SEARCH,
                executing_search: true,
            }, this.executeSearchQuery);

    }

    finalizeNewSearch(result_entries, start_index, session_id) {

        let cur_pages = [];
        let show_filter_column = false;

        if (result_entries && result_entries.length > 0){
            cur_pages.push(result_entries);
            show_filter_column = true;
        }

        this.setState({
            pages: cur_pages,
            start_index: start_index,
            current_page: cur_pages.length - 1,
            session_id: session_id,
            show_filter_column: show_filter_column,
            next_button_enabled: true,
            property_address: null,
            executing_search: false,
        });
    }

    resetFilters() {
        this.setState(
            {
                proj_type_filter_map: {},
                file_type_filter_map: {},
                zone_filter_map: {},
                gp_classification_filter_map: {},
                distance_filter: "0",
            });
    }

    initiateFilterSearch() {
        this.setState(
            {
                show_filter_bar: false,
                filter_type_display_state: {},
                session_id: null,
                start_index: 0,
                last_executed_search: SearchType.FILTER,
                executing_search: true,
            }, this.executeSearchQuery);
    }

    finalizeFilterSearch(result_entries, start_index, session_id) {
        let cur_pages = [];

        if (result_entries && result_entries.length > 0){
            cur_pages.push(result_entries);
        }

        this.setState({
            pages: cur_pages,
            start_index: start_index,
            current_page: cur_pages.length - 1,
            session_id: session_id,
            next_button_enabled: true,
            executing_search: false,
        });
    }

    initiateNextPage() {
        this.setState({
            last_executed_search: SearchType.NEXT_PAGE,
            executing_search: true,
        }, this.executeSearchQuery);
    }

    finalizeNextPage(result_entries, start_index, session_id) {
        
        let cur_pages = this.state.pages;

        let next_button_enabled = this.state.next_button_enabled;
        let show_no_more_result_modal = this.state.show_no_more_result_modal;

        if (result_entries && result_entries.length > 0){
            cur_pages.push(result_entries);
        }
        else {
            next_button_enabled = false;
            show_no_more_result_modal = true;
        }
        
        this.setState({ 
            pages: cur_pages,
            start_index: start_index,
            current_page: cur_pages.length - 1,
            session_id: session_id,
            next_button_enabled: next_button_enabled,
            show_no_more_result_modal: show_no_more_result_modal,
            executing_search: false,
        });
    }

    executeSearchQuery() {
        
        let proj_filter = [];
        let file_filter = [];
        let zone_filter = [];
        let gp_classification_filter = [];

        for (const [key, value] of Object.entries(this.state.proj_type_filter_map)) {
            if (value === true){
                proj_filter.push(key);
            }
        }

        for (const [key, value] of Object.entries(this.state.file_type_filter_map)) {
            if (value === true){
                file_filter.push(key);
            }
        }

        for (const [key, value] of Object.entries(this.state.zone_filter_map)) {
            if (value === true){
                zone_filter.push(key);
            }
        }

        for (const [key, value] of Object.entries(this.state.gp_classification_filter_map)) {
            if (value === true){
                gp_classification_filter.push(key);
            }
        }
        
        let start_date = null;
        let end_date = null;

        if (this.state.start_date) {
            start_date = {
                year: this.state.start_date.getFullYear(),
                // month is 0 based
                month: this.state.start_date.getMonth() + 1,
                day: this.state.start_date.getDate(),
            }
        }

        if (this.state.end_date) {
            end_date = {
                year: this.state.end_date.getFullYear(),
                // month is 0 based
                month: this.state.end_date.getMonth() + 1,
                day: this.state.end_date.getDate(),
            }
        }

        let request = {
            search_string: this.state.search_string,
            start_date: start_date,
            end_date: end_date,
            start_index: this.state.start_index,
            page_size: this.state.page_size,
            session_id: this.state.session_id,
            proj_filter: proj_filter,
            file_filter: file_filter,
            zone_filter: zone_filter,
            gp_classification_filter: gp_classification_filter,
            distance_filter: this.state.distance_filter,
            property_address: this.state.property_address,
        }

        generic_post_api(request, OAK_PLANNING_SEARCH)
        .then(
            result => {
                return result.data.json()
            }
        ).then(
            data => {
                window.scrollTo(0, 0);

                let result_entries = data['result_entries'];
                let start_index = data['start_index'];
                let session_id = data['session_id'];

                if (this.state.last_executed_search === SearchType.NEW_SEARCH){
                    this.finalizeNewSearch(result_entries, start_index, session_id);
                }
                else if (this.state.last_executed_search === SearchType.FILTER){
                    this.finalizeFilterSearch(result_entries, start_index, session_id);
                }
                else if (this.state.last_executed_search === SearchType.NEXT_PAGE){
                    this.finalizeNextPage(result_entries, start_index, session_id);
                }
            })
        .catch(error => {
        });
    }

    setProjTypeFilter(filter, value) {
        let cur_map = this.state.proj_type_filter_map;
        cur_map[filter] = value;

        this.setState({proj_type_filter_map: cur_map});
    }

    setFileTypeFilter(filter, value) {
        let cur_map = this.state.file_type_filter_map;
        cur_map[filter] = value;

        this.setState({file_type_filter_map: cur_map});
    }

    setZoneFilter(filter, value) {
        let cur_map = this.state.zone_filter_map;
        cur_map[filter] = value;

        this.setState({zone_filter_map: cur_map});
    }

    setGpClassificationFilter(filter, value) {
        let cur_map = this.state.gp_classification_filter_map;
        cur_map[filter] = value;

        this.setState({gp_classification_filter_map: cur_map});
    }

    customDatePickerChanged(value) {

        let end_date = new Date();
        let start_date = new Date();
        let show_custom_date_controls = false;
        
        if  (value === 'All') {
            end_date = null;
            start_date = null;
        }
        else if  (value === '30') {
            start_date.setDate(start_date.getDate() - 30);
        }
        else if  (value === '180') {
            start_date.setDate(start_date.getDate() - 180);
        }
        else if (value === '365') {
            start_date.setFullYear( start_date.getFullYear() - 1 );
        }
        else if  (value === '730') {
            start_date.setFullYear( start_date.getFullYear() - 2 );
            
        }
        // when user sets custom date, start with 1 year as default on the UI
        else if  (value === 'custom') {
            start_date.setFullYear( start_date.getFullYear() - 1 );
            show_custom_date_controls = true;
        }

        this.setState({ 
            custom_date_selection: value,
            end_date: end_date, 
            start_date: start_date,
            show_custom_date_controls: show_custom_date_controls,
         });
    }

    render() {
        const {pages, current_page, file_type_filter_map, zone_filter_map, 
            gp_classification_filter_map, show_filter_column, 
            show_no_more_result_modal, last_executed_search, executing_search} = this.state;

        const pagination_page_style  = (index) => {
            if (index === this.state.current_page) {
                return 'text-decoration-none pe-none fw-bold text-white';
            }
            else {
                return 'text-decoration-underline pe-auto ';
            }
        }

        const pagination_page_style_span  = (index) => {
            if (index === this.state.current_page) {
                return 'me-3 fs-6 ps-1 pe-1 bg-primary';
            }
            else {
                return 'me-3 fs-6 ps-1 pe-1';
            }
        }

        const pagination_page_role  = (index) => {
            if (index === this.state.current_page) {
                return '';
            }
            else {
                return 'button';
            }
        }
        
        const getSmallText = (text) => { return <small>{text}</small>}

        return(
            <div class="container sfPlanningSearch">
                <div class="row">
                    <div class="col-3">
                        <select class="form-select " aria-label="Default select example" onChange={(e) => this.props.history.push(e.target.value)}>
                            <option value="/code/sf_search">San Francisco</option>
                            <option value="/code/oak_search" selected>Oakland</option>
                            <option value="jbk" disabled>Berkeley (coming soon)</option>
                            <option value="lax" disabled>Los Angeles (coming soon)</option>
                            <option value="ngz" disabled>Alameda</option>
                        </select>
                    </div>
                    <div class="col-9">
                        <div class="input-group">
                            <select class="form-select queryMethods" aria-label="Default select example" onChange={(e) => this.setState({query_method: e.target.value})}>
                                <option disabled>Search type</option>
                                <option value="keywords" selected>Keywords</option>
                                {/* <option value="boolean">Boolean</option>
                                <option value="natural">Natural</option>
                                <option value="address">Address</option>
                                <option value="record">Record #</option> */}
                            </select>
                            <input type="search" 
                                placeHolder="Project keywords"
                                class="form-control"
                                name="search" 
                                id="search"
                                value={this.state.search_string}
                                onChange={ (e) => this.setState({search_string: e.target.value}) }
                            />
                             {
                                executing_search ? 
                                    <Button className="bg-transparent text-dark clearButton" disabled>
                                        <span role="status">Searching&nbsp;</span>
                                        <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                    </Button>
                                :
                                <Button variant="warning" onClick={ this.initiateNewSearch } className="searchButton">
                                    Search&nbsp;<i class="fa-solid fa-magnifying-glass fa-rotate-90"></i>
                                </Button>        
                            }
                            
                        </div>
                        
                    </div>
                </div>
                <Offcanvas show={this.state.show_filter_bar} onHide={() => this.setState({show_filter_bar: false})}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Filters</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="filterBar">
                        <div class="container">
                            <div class="row">
                                <div class="col-auto fs-6">Date range</div>
                                <div class="col fs-6 text-end">
                                    <a onClick={()=> this.setFilterTypeDisplayState('date_range')} role="button">
                                        {
                                            this.state.filter_type_display_state['date_range'] ?
                                            <i class="fa-solid fa-minus fa-xl"></i> :
                                            <i class="fa-solid fa-plus fa-xl"></i>
                                        }
                                    </a>
                                </div>
                            </div>
                            {   this.state.filter_type_display_state['date_range'] &&
                                <div class="row mt-3">
                                    <div class="col-12">
                                        <Form.Select className="filterDropdownButton" onChange={(e) => this.customDatePickerChanged(e.target.value)} size='sm'>
                                            <option value="All" selected={this.state.custom_date_selection === 'All'}>Date range (All)</option>
                                            <option value="30" selected={this.state.custom_date_selection === '30'}>Last 30 days</option>
                                            <option value="180" selected={this.state.custom_date_selection === '180'}>Last 180 days</option>
                                            <option value="365" selected={this.state.custom_date_selection === '365'}>Last 12 months</option>
                                            <option value="730" selected={this.state.custom_date_selection === '730'}>Last 2 years</option>
                                            <option value="custom" selected={this.state.custom_date_selection === 'custom'}>Custom</option>
                                        </Form.Select>
                                    </div>
                                    { this.state.show_custom_date_controls &&
                                        <>
                                            <div class="col-6">
                                                <div class="row"><div class="col>">From</div></div>
                                                <div class="row">
                                                    <div class="datePickerInput">
                                                        <DatePicker 
                                                            selected={this.state.start_date} 
                                                            maxDate={new Date()}
                                                            showDisabledMonthNavigation
                                                            onChange={(date) => this.setState({ start_date: date})} 
                                                            className="datePicker"
                                                            placeholderText="From"
                                                            popperPlacement="bottom-end"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="row"><div class="col>">To</div></div>
                                                <div class="row">
                                                    <DatePicker 
                                                        selected={this.state.end_date} 
                                                        // minDate={new Date()}
                                                        showDisabledMonthNavigation
                                                        onChange={(date) => this.setState({ end_date: date })} 
                                                        className="datePicker"
                                                        placeholderText="To"
                                                        popperPlacement="bottom-end"
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            }
                            <div class="row"><div class="col"><hr></hr></div></div>
                        </div>
                        <div class="container recordType">
                            <div class="row">
                                <div class="col-auto fs-6">Record type</div>
                                <div class="col fs-6 text-end">
                                    <a onClick={()=> this.setFilterTypeDisplayState('record_type')} role="button">
                                        {
                                            this.state.filter_type_display_state['record_type'] ?
                                            <i class="fa-solid fa-minus fa-xl"></i> :
                                            <i class="fa-solid fa-plus fa-xl"></i>
                                        }
                                    </a>
                                </div>
                            </div>
                            {   this.state.filter_type_display_state['record_type'] &&
                                <div class="row filterItemsHeightLimit overflow-auto mt-3">
                                    { Object.entries(OakPlanningRecordType).map( (record_type, index) => {
                                        return  <div class="col-12">
                                                    <Form.Check
                                                        className="ms-2"
                                                        name={record_type[1]}
                                                        type="checkbox"
                                                        label={getSmallText(record_type[1])}
                                                        checked={this.state.proj_type_filter_map[record_type[1]]}
                                                        defaultChecked={this.state.proj_type_filter_map[record_type[1]]}
                                                        onChange={(e) => this.setProjTypeFilter(record_type[1], e.currentTarget.checked)} />
                                                </div>  
                                    })
                                    }
                                </div>
                            }
                            <div class="row"><div class="col"><hr></hr></div></div>
                        </div>
                        <div class="container">
                            <div class="row">
                                <div class="col-auto fs-6">File type</div>
                                <div class="col fs-6 text-end">
                                    <a onClick={()=> this.setFilterTypeDisplayState('file_type')} role="button">
                                        {
                                            this.state.filter_type_display_state['file_type'] ?
                                            <i class="fa-solid fa-minus fa-xl"></i> :
                                            <i class="fa-solid fa-plus fa-xl"></i>
                                        }
                                    </a>
                                </div>
                            </div>
                            {   this.state.filter_type_display_state['file_type'] &&
                                <div class="row mt-3">
                                    { Object.entries(OakPlanningFileType).map( (file_type, index) => {
                                        return  <div><Form.Check
                                                    className="ms-2"
                                                    name={file_type[0]}
                                                    type="checkbox"
                                                    label={getSmallText(file_type[0])}
                                                    checked={file_type_filter_map[file_type[0]]}
                                                    defaultChecked={file_type_filter_map[file_type[0]]}
                                                    onChange={(e) => this.setFileTypeFilter(file_type[0], e.currentTarget.checked)} />
                                                </div>
                                    })}
                                </div>
                            }
                            <div class="row"><div class="col"><hr></hr></div></div>
                        </div>
                        <div class="container">
                            <div class="row">
                                <div class="col-auto fs-6">Zoning</div>
                                <div class="col fs-6 text-end">
                                    <a onClick={()=> this.setFilterTypeDisplayState('zone')} role="button">
                                        {
                                            this.state.filter_type_display_state['zone'] ?
                                            <i class="fa-solid fa-minus fa-xl"></i> :
                                            <i class="fa-solid fa-plus fa-xl"></i>
                                        }
                                    </a>
                                </div>
                            </div>
                            {   this.state.filter_type_display_state['zone'] &&
                                <div class="row filterItemsHeightLimit overflow-auto mt-3">
                                    { Object.entries(OakZones).map( (oak_zone, index) => {
                                        return  <div><Form.Check
                                                    className="ms-2"
                                                    name={oak_zone[0]}
                                                    type="checkbox"
                                                    label={getSmallText(oak_zone[0])}
                                                    checked={zone_filter_map[oak_zone[0]]}
                                                    defaultChecked={zone_filter_map[oak_zone[0]]}
                                                    onChange={(e) => this.setZoneFilter(oak_zone[0], e.currentTarget.checked)} />
                                                </div>
                                    })}
                                </div>
                            }
                            <div class="row"><div class="col"><hr></hr></div></div>
                        </div>
                        <div class="container">
                            <div class="row">
                                <div class="col-auto fs-6">General Plan</div>
                                <div class="col fs-6 text-end">
                                    <a onClick={()=> this.setFilterTypeDisplayState('general_plan')} role="button">
                                        {
                                            this.state.filter_type_display_state['general_plan'] ?
                                            <i class="fa-solid fa-minus fa-xl"></i> :
                                            <i class="fa-solid fa-plus fa-xl"></i>
                                        }
                                    </a>
                                </div>
                            </div>
                            {   this.state.filter_type_display_state['general_plan'] &&
                                <div class="row filterItemsHeightLimit overflow-auto mt-3">
                                    {   Object.entries(OakGpClassifications).map( (gp_class, index) => {
                                            return  <div><Form.Check
                                                        className="ms-2"
                                                        name={gp_class[0]}
                                                        type="checkbox"
                                                        label={getSmallText(gp_class[0])}
                                                        checked={gp_classification_filter_map[gp_class[0]]}
                                                        defaultChecked={gp_classification_filter_map[gp_class[0]]}
                                                        onChange={(e) => this.setGpClassificationFilter(gp_class[0], e.currentTarget.checked)} />
                                                    </div>
                                    })}
                                </div>
                            }
                            <div class="row"><div class="col"><hr></hr></div></div>
                        </div>
                        {
                            this.state.property_address && 
                            <div class="container">
                                <div class="row">
                                    <div class="col-auto fs-6">Distance</div>
                                    <div class="col fs-6 text-end">
                                        <a onClick={()=> this.setFilterTypeDisplayState('distance')} role="button">
                                            {
                                                this.state.filter_type_display_state['distance'] ?
                                                <i class="fa-solid fa-minus fa-xl"></i> :
                                                <i class="fa-solid fa-plus fa-xl"></i>
                                            }
                                        </a>
                                    </div>
                                </div>
                                {   this.state.filter_type_display_state['distance'] &&
                                    <div class="row mt-3">
                                        <div class="row mt-3">
                                            <div class="col">
                                                <Form.Select className="filterDropdownButton" onChange={(e)=>this.setState({distance_filter: e.target.value})} size='sm'>
                                                    { DistanceMile.map( (distance, index) => {
                                                        return  <option value={distance.value} selected={this.state.distance_filter === distance.value}>{distance.display}</option> 
                                                    })}
                                                </Form.Select>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div class="row"><div class="col"><hr></hr></div></div>
                            </div>

                        }
                       
                        <div class="row mt-3">
                            <div class="col-6 text-center">
                                <Button variant="outline-secondary" onClick={this.resetFilters}>Reset</Button>
                            </div>
                            <div class="col-6 text-center">
                                <Button variant="outline-primary" onClick={() => {this.initiateFilterSearch()}}>Apply</Button>
                            </div>
                            
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
                <div class="row mt-4">
                    <div class="col-3">
                        {
                            show_filter_column && 
                            <>  
                                <div class="row mt-4">
                                    <MapComponent 
                                        property_address={this.state.property_address}
                                        current_page={pages[current_page]}
                                        radius={this.state.distance_filter}
                                    />
                                </div>
                                <PropertyAddressSection 
                                    property_address={this.state.property_address}
                                    setPropertyAddress={this.setPropertyAddress}
                                />
                                <div class="row mt-3">
                                    <div class="col fw-bold text-center"><Button className="w-100" onClick={()=>this.setState({show_filter_bar: true})} variant="outline-primary">Filter results</Button></div>
                                </div>
                            </>
                        }
                    </div>
                    <div class="col-9">
                        {
                            last_executed_search === SearchType.NEW_SEARCH && pages.length == 0 && !executing_search &&
                            <div class="row"><div class="col fw-semibold">Your search returned no results. Adjust the keywords and date.</div></div>
                        }
                        {
                            last_executed_search === SearchType.FILTER && pages.length == 0 && !executing_search &&
                            <div class="row"><div class="col fw-semibold">No records match these filters. Adjust your filters.</div></div>
                        }
                        {
                            last_executed_search === SearchType.NEXT_PAGE && show_no_more_result_modal &&
                            <Modal
                                show={show_no_more_result_modal}
                                onHide={() => this.setState({show_no_more_result_modal: false })}
                                size="sm"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                            <Modal.Header closeButton>
                                <Modal.Title>No more results</Modal.Title>
                            </Modal.Header>
                                <Modal.Body>
                                    <div class="row">
                                        <div class="col">
                                            There are no more records that match your search.
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        }

                        {pages[current_page] && pages[current_page].map(entry => {
                            return  <ResultRow entry={entry} />
                                
                        })}
                        <div class="row mt-4">
                            <div class="col d-flex justify-content-center">
                                {
                                    pages.map((item, index) => {
                                        return  <span class={pagination_page_style_span(index)}>
                                                    <a className={pagination_page_style(index)} role={pagination_page_role(index)} onClick={()=> this.setState({current_page: index})}>{index + 1}</a>
                                                </span>
                                    })
                                }
                                {
                                    pages.length > 0 &&
                                        <span class="mt-1">
                                            {
                                                this.state.next_button_enabled && 
                                                <a className="text-decoration-none" role="button" onClick={ this.initiateNextPage }>&nbsp;<i class="fa-solid fa-angle-right fa-xl"></i></a>
                                            }
                                        </span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
    }
}

function ResultRow(props){

    const {entry} = props;

    const getDetailUrl = (record_id) => {
        return "/code/oak_detail/" + record_id;
    }

    return (
        <Card className="mt-4 resultRow">
            <Card.Body className="ps-0 pe-0">
                <div class="container">
                    <div class="row">
                        <div class="col-8">
                            <div class="row">
                                <div class="col">
                                    <div class="row">
                                        <div class="col fs-6 fw-bold"><a className="text-decoration-none text-black"href={getDetailUrl(entry['record_id'])} target="_blank">{entry['record_number']}</a></div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col">
                                            {entry['application_date']}&nbsp;&nbsp;
                                            &#x2022;&nbsp;&nbsp;
                                            {entry['record_type']}&nbsp;&nbsp;
                                            &#x2022;&nbsp;&nbsp;
                                            {entry['current_status']}
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-auto pe-0 text-primary"><i class="fa-regular fa-message"></i></div>
                                <div class="col">
                                    {
                                        entry['highlight'] && entry['highlight'].length > 0 ?
                                        <span dangerouslySetInnerHTML={{__html: entry['highlight']}} /> :
                                        <span>{truncateText(entry['description'], 200, true)}</span>
                                    }
                                </div>
                            </div>
                           
                        
                            {
                                entry['related_record_types'] && entry['related_record_types'].length > 0 &&
                                <div class="row mt-3">
                                    <div class="col-auto pe-0 text-primary"><i class="fa-regular fa-folder"></i></div>
                                    <div class="col">
                                        {
                                            entry['related_record_types'].map((related_type, idx) => {
                                                if (idx < entry['related_record_types'].length - 1){
                                                    return <>{related_type}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp; </>
                                                }
                                                else{
                                                    return <>{related_type}</>
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            {
                                entry['file_types'] && entry['file_types'].length > 0 &&
                                <div class="row mt-3">
                                    <div class="col-auto pe-0 text-primary"><i class="fa-regular fa-file"></i></div>
                                    <div class="col">
                                        {
                                            entry['file_types'].map((file_type, idx) => {
                                                if (idx < entry['file_types'].length - 1){
                                                    return <>{file_type}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp; </>
                                                }
                                                else{
                                                    return <>{file_type}</>
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            }
                                
                        </div>

                        {
                            (entry['address']['street_name'] || entry['zone'] || entry['gp_classification']) &&

                            <div class="col-4 propertyInfoColumn">
                                <div class="row">
                                    <div class="col-auto pe-0"><i class="fa-solid fa-location-dot"></i></div>
                                    <div class="col">
                                        <div class="row"><div class="col">{entry['address']['street_number']} {entry['address']['street_name']} {entry['address']['street_suffix']}</div></div>
                                        <div class="row"><div class="col">Oakland {entry['address']['postal']}</div></div>
                                    </div>
                                </div>
                                {
                                    entry['zone'] &&
                                    <div class="row mt-3">
                                        <div class="col-auto pe-0"><i class="fa-solid fa-ruler-vertical"></i></div>
                                        <div class="col">
                                            <div class="row"><div class="col">
                                                {entry['zone']}
                                            </div></div>
                                        </div>
                                    </div>
                                }
                                {
                                    entry['gp_classification'] &&
                                    <div class="row mt-3">
                                        <div class="col-auto pe-0"><i class="fa-regular fa-building"></i></div>
                                        <div class="col">
                                            <div class="row"><div class="col">
                                                {entry['gp_classification']}
                                            </div></div>
                                        </div>
                                    </div>
                                }
                                
                            </div>
                         }
                    </div>
                
                    {/* <InsightComponent entry={entry}/> */}
                   
                </div>
            </Card.Body>
        </Card>
    )
}

function PropertyAddressSection(props){

    const [show_search_bar, setShowSearchBar] = useState(false);
    const [temp_property_address, setTempPropertyAddress] = useState(null);

    const reset = () => {
        setTempPropertyAddress(null);
        setShowSearchBar(false);
    }

    return( 
        <Card className="mt-4 p-3">
            <Card.Title className="fs-6 fw-bold">Property address</Card.Title>
            <Card.Body className="p-0">
                {
                    !props.property_address && !show_search_bar && 
                    <div class="row">
                        <div class="col">
                            <a role="button" className="text-decoration-underline" onClick={()=>setShowSearchBar(true)}><small>Set a property address</small></a>
                        </div>
                    </div>
                }
                {
                    show_search_bar &&
                        <>
                            <AddressSearchComponent 
                                setTempPropertyAddress={setTempPropertyAddress}
                            />
                            <div class="row mt-3">
                                <div class="col text-end">
                                    <Button variant="secondary" onClick={ reset } size="sm">
                                        Cancel
                                    </Button>
                                </div>
                                <div class="col-auto text-end">
                                    <Button variant="primary" disabled={ !temp_property_address } onClick={ () => { props.setPropertyAddress(temp_property_address); reset()} }  size="sm">
                                        Select
                                    </Button>
                                </div>
                            </div>
                        </>
                }
                {
                    props.property_address && !show_search_bar && 
                        <>
                            <div class="row"><div class="col"><small>{props.property_address.formatted_address}</small></div></div>
                            <div class="row">
                                <div class="col-auto">
                                    <a role="button" className="text-decoration-underline" onClick={()=>setShowSearchBar(true)}><small>Edit</small></a>
                                </div>
                                <div class="col-auto">
                                    <a role="button" className="text-decoration-underline" onClick={ () => props.setPropertyAddress(null) }><small>Remove</small></a>
                                </div>
                            </div>
                        </>
                        
                }
            </Card.Body>
        </Card>)
}

class AddressSearchComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            select_button_enabled: false,
        }

        this.autocompleteInput = React.createRef();
        this.autocomplete = null;
        
        this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
        this.addressChanged = this.addressChanged.bind(this);
        this.resetAddressField = this.resetAddressField.bind(this);
    }
      
    addressChanged(e) {
        // this.setState({
        //     address_field: e.target.value,
        // });
        this.props.setTempPropertyAddress(null);
    }

    resetAddressField() {
        this.autocompleteInput.current.value = "";
        this.props.setTempPropertyAddress(null);
    }

    componentDidMount() {

        const center = {lat: 37.81158165329357, lng: -122.24025380424199 };

        // Create a bounding box with sides ~10km away from the center point
        const defaultBounds = {
            north: center.lat + 0.1,
            south: center.lat - 0.1,
            east: center.lng + 0.1,
            west: center.lng - 0.1,
        };
        this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
            {"types": ["geocode"], "fields": ["address_components", "formatted_address", "name", "geometry"], bounds: defaultBounds, strictBounds: true });

        this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
    }

    handlePlaceChanged(){
        const place = this.autocomplete.getPlace();
        let streetNumber = '';
        let streetName = ''
        let city = '';
        let state = '';
        let zipCode = '';

        let i = 0;
        let lat = place.geometry.location.lat();
        let lng = place.geometry.location.lng();

        for(; i < place.address_components.length; i++) {
            let component = place.address_components[i];
            if (component.types.includes("street_number")) {
                streetNumber = component.long_name;
            }
            else if (component.types.includes("route")) {
                streetName = component.long_name;
            }
            else if (component.types.includes("locality")) {
                city = component.long_name;
            }
            else if (component.types.includes("administrative_area_level_1")) {
                state = component.short_name;
            }
            else if (component.types.includes("postal_code")) {
                zipCode = component.long_name;
            }
        }

        this.props.setTempPropertyAddress({
            street_number: streetNumber,
            street_name: streetName,
            city: city,
            state: state,
            zip_code: zipCode,
            formatted_address: place.formatted_address,
            lat: lat,
            lng: lng,
        });
    }

    render() {

        return( 
            <>
                <div class="row mt-1">
                    <div class="col">
                        <div class="input-group">
                            <input type="text" class="form-control" placeHolder="Address or APN" ref={this.autocompleteInput}  id="autocomplete" onChange={ (e) => this.addressChanged(e) }/>
                                <Button className="bg-transparent text-dark clearButton" onClick={this.resetAddressField}>
                                    <i class="fa-solid fa-magnifying-glass"></i>
                                </Button>
                            
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

// need this reference to delete the circle when creating a new one. Could not find a better method.
let my_circle = null;
function MapComponent(props) {

    const [googleApiObj, setIsGoogleApiLoadedObj] = useState(null);
    const [active_marker, setActiveMarker] = useState(null);
    const {property_address, current_page, radius} = props;

    useEffect(() => {
        if (googleApiObj) {
          const {map, maps} = googleApiObj;

            if (my_circle) {
                my_circle.setMap(null);
            }
            my_circle = getCircle(map, maps)
        }
    }, [googleApiObj, property_address, radius]);

    const reSetActiveMarker = (marker) => {
        setActiveMarker(marker);
    }

    const onChildClick = (e, childProps) => {
        setActiveMarker(childProps);
    }

    const getCircle = (map, maps) => { 
        
        let circle_radius = 0;
        let circle_center = {lat: 37.81158165329357, lng: -122.24025380424199 }

        if (property_address && property_address.lat && property_address.lng) {
            circle_center = {lat: property_address.lat, lng: property_address.lng};
        }

        if (parseFloat(radius) > 0) {
            circle_radius = parseFloat(radius) * 1609.34;
        }

        return new maps.Circle({
            strokeColor: '#858080',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#ccc9c9',
            fillOpacity: 0.5,
            map,
            center: circle_center,
            radius: circle_radius,
            })
    }

    return(
        <div class="container">
        <Card className="mt-3">
            <Card.Body>
                <div class="row">
                    <div class="col-12 map p-0">
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: 'AIzaSyAyQMpPy1QPIieWCFecN0j8QGQpKTs82vk' }}
                            defaultCenter={{lat: 37.81158165329357, lng: -122.24025380424199 }}
                            defaultZoom={11}
                            zoom={11}
                            onChildClick={ onChildClick }
                            yesIWantToUseGoogleMapApiInternals={true}
                            onGoogleApiLoaded = {({map, maps}) => setIsGoogleApiLoadedObj({map, maps})}
                        >
                            {
                                current_page && current_page.map(entry =>{  return <ResultsMarker
                                                                                lat={entry.lat}
                                                                                lng={entry.lng}
                                                                                record_number={entry.record_number}
                                                                                record_id={entry.record_id}
                                                                                record_type={entry.record_type}
                                                                                description={entry.description}
                                                                                text="My Marker"/>
                                })
                            }
                            { active_marker &&
                                <MapMarker
                                    record_id={active_marker.record_id}
                                    record_number={active_marker.record_number}
                                    description={active_marker.description}
                                    record_type={active_marker.record_type}
                                    setActiveMarker={reSetActiveMarker}
                                /> 
                            }
                            {
                                property_address && property_address.lat && property_address.lng && 
                                    <PropertyMarker
                                        lat={property_address.lat}
                                        lng={property_address.lng}
                                        text="Marker"/>
                            }
                        </GoogleMapReact>
                    </div>
                </div>
            </Card.Body>
        </Card>
        </div>
    );
}

const ResultsMarker = (props) => {

    return(
        <div class="places" >
            <div class="placesIcon"> <i class="fa-solid fa-location-dot fa-xl" style={{color: "#d81313"}}></i></div>
        </div>

    )
}

const PropertyMarker = (props) => {

    return(
        <div class="places" >
            <div class="placesIcon"> <i class="fa-solid fa-location-dot fa-2xl" style={{color: "#00bde3"}}></i></div>
        </div>

    )
}

const MapMarker = (props) => {

    const getDetailUrl = (record_id) => {
        return "/code/sf_detail/" + record_id;
    }
    const infoWindowStyle = {
        position: 'relative',
        top: '-90px',
        left: '-120px',
        width: 200,
        backgroundColor: 'white',
        boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
        padding: 10,
        fontSize: 14,
        zIndex: 100,
    };

    const infoWindowClose = {
        float: 'right',
        fontSize: 11,
    };

    return (
        <>
        <div style={infoWindowStyle}>
            <div class="fw-light container gx-0">
                <div class="row" onClick={() => props.setActiveMarker(null)}>
                    <div class="col-10 fw-bold"><small>{props.record_number}</small></div>
                    <div class="col-2 text-end" onClick={() => props.setActiveMarker(null)}>X</div>
                </div>
                {/* <div class="row mt-1"><div class="col"><small>{props.record_type}</small></div></div> */}
                <div class="row mt-1"><div class="col"><small>{truncateText(props.description, 80, true)}</small> </div></div>
                <div class="row mt-1"><div class="col"><small><a role="button" target="_blank" href={getDetailUrl(props.record_id)} className="text-decoration-underline">more <i class="fa-solid fa-arrow-up-right-from-square"></i></a></small> </div></div>
            </div>
        </div>
        </>
    );
}

function InsightComponent(props) {

    const {entry} = props;

    const showInsights = () =>
    {
        if (entry['var_decision'] && entry['var_decision'].length > 0){
            return true;
        }
        if (entry['var_sought'] && entry['var_sought'].length > 0){
            return true;
        }
        if (entry['var_hearing_date'] && entry['var_hearing_date'].length > 0){
            return true;
        }
        if (entry['env_document_types'] && entry['env_document_types'].length > 0){
            return true;
        }
        if (entry['app_status_list'] && entry['app_status_list'].length > 0){
            return true;
        }
        if (entry['cua_hearing_date'] && entry['cua_hearing_date'].length > 0){
            return true;
        }
        if (entry['has_zad_decision'] || entry['has_var_decision'] || entry['has_plan_check_letter'] || 
            entry['has_appeal'] || entry['has_opposition_position'] || entry['has_cua_commission_memo']){
            return true;
        }
        return false;
    }

    const joinList = (input_list_1, input_list_2) => {
        
        let combined = [];
        if (input_list_1 && input_list_1.length > 0) {
            combined = combined.concat(input_list_1);
        }

        if (input_list_2 && input_list_2.length > 0) {
            combined = combined.concat(input_list_2);
        }

        return combined.join(",")
    }

    if (!showInsights()) {
        return null;
    }        

    return (
        <div class="derivedSection">
            <div class="row"><div class="col"><hr></hr></div></div>
            <div class="row mb-2">
                {
                    entry['has_var_decision'] &&
                    <div class="col-auto"><i class="fa-solid fa-check" style={{"color": "#164734"}}></i>&nbsp;<small>Variance decision</small></div>
                }
                {
                    entry['has_zad_decision'] &&
                    <div class="col-auto"><i class="fa-solid fa-check" style={{"color": "#164734"}}></i>&nbsp;<small>ZA determination</small></div>
                }
                {
                    entry['has_plan_check_letter'] &&
                    <div class="col-auto"><i class="fa-solid fa-check" style={{"color": "#164734"}}></i>&nbsp;<small>Plan check comments</small></div>
                }
                {
                    entry['has_appeal'] &&
                    <div class="col-auto"><i class="fa-solid fa-check" style={{"color": "#164734"}}></i>&nbsp;<small>Appealed</small></div>
                }
                {
                    entry['has_opposition_position'] &&
                    <div class="col-auto"><i class="fa-solid fa-check" style={{"color": "#164734"}}></i>&nbsp;<small>Received opposition</small></div>
                }
                {
                    entry['has_cua_commission_memo'] &&
                    <div class="col-auto"><i class="fa-solid fa-check" style={{"color": "#164734"}}></i>&nbsp;<small>Conditional Use staff report</small></div>
                }
                {
                    entry['var_sought'] && entry['var_sought'].length > 0 &&
                        <div class="col-auto"><i class="fa-solid fa-check" style={{"color": "#164734"}}></i>&nbsp;<small>{entry['var_sought']}</small></div>
                }
                {
                    entry['var_decision'] && entry['var_decision'].length > 0 &&
                        <div class="col-auto"><i class="fa-solid fa-check" style={{"color": "#164734"}}></i>&nbsp;<small>Variance decision: {entry['var_decision']}</small></div>
                }
                {
                    entry['cua_hearing_date'] && entry['cua_hearing_date'].length > 0 &&
                        <div class="col-auto"><i class="fa-solid fa-calendar-days" style={{"color": "#164734"}}></i>&nbsp;<small>CUA hearing date: {entry['cua_hearing_date']}</small></div>
                }
                {
                    entry['var_hearing_date'] && entry['var_hearing_date'].length > 0 &&
                        <div class="col-auto"><i class="fa-solid fa-calendar-days" style={{"color": "#164734"}}></i>&nbsp;<small>Var hearing date: {entry['var_hearing_date']}</small></div>
                }
            </div>
            {
                ((entry['env_document_types'] && entry['env_document_types'].length > 0) || (entry['app_status_list'] && entry['app_status_list'].length > 0)) &&
                <div class="row">
                    <div class="col"><i class="fa-solid fa-file" style={{"color": "#164734"}}></i>&nbsp;<small>Documents: </small>
                        <small>
                        {
                            joinList(entry['env_document_types'], entry['app_status_list'])
                        }
                        </small>
                    </div>
                </div>
            }
        </div>
    );
}

export default OakPlanningSearch;