/* global google */
// do not delete above line. Needed for google address to function

import { basicAccountInfo } from "api/basic_account_info";
import { generic_get_api } from "api/generic_api";
import left_line from 'assets/reputation/left-line.svg';
import middle_line from 'assets/reputation/middle-line.svg';
import right_line from 'assets/reputation/right-line.svg';
import { Component, useState, useEffect } from "react";
import { Placeholder} from 'react-bootstrap';
import { SF_PLANNING_DETAIL } from 'utils/constants';
import { EventNames, trackEvent } from 'utils/mixpanel';
import Joyride from 'react-joyride';


const steps_basic = [
    {
        target: '.projectDescription',
        content: 'Quick facts about the project/permit including summary, application submission date, and current status.',
        disableBeacon: true,
    },
    {
        target: '.propertyInfoColumn',
        content: 'If available, information about the property, such as address, zoning, height district, etc.',
        disableBeacon: true,
    },
    {
        target: '.timeLine',
        content: 'If available, a quick overview of the timeline of this application with important milestones.',
        disableBeacon: true,
    },
    {
        target: '.cuaInsight',
        content: 'If applicable, a quick summary of Conditional Use documents in this application.',
        disableBeacon: true,
    },
    {
        target: '.varInsight',
        content: 'If applicable, a quick summary of the variance decision in this application.',
        disableBeacon: true,
    },
    {
        target: '.zadInsight',
        content: 'If applicable, a quick summary of the zoning decision in this application.',
        disableBeacon: true,
    },
    {
        target: '.fileSection',
        content: 'A list of files and documents for this application. Click on each category of files to view available documents.',
        disableBeacon: true,
    },
    // {
    //     target: '.subApplications',
    //     content: 'If the permit application includes additional sub-applications, they will be listed here.',
    //     disableBeacon: true,
    // },
];


class SfPlanningDetail extends Component {
    
    constructor(props) {
        
        let record_id = null;
        let path_components = window.location.pathname.split('/')
        record_id = path_components[path_components.length - 1]

        let urlParams = new URLSearchParams(window.location.search);
        let highlight_file_names_in = urlParams.get('fn');
        let highlight_file_names = [];

        if (highlight_file_names_in && highlight_file_names_in.length > 0){
            highlight_file_names = highlight_file_names_in.split('___');
        }

        super(props);

        this.state = {
            record_id: record_id,
            record_details: null,
            highlight_file_names: highlight_file_names,
            basic_detail_tutorial: false,
        };

        this.fetchRecord = this.fetchRecord.bind(this);
        this.startTutorial = this.startTutorial.bind(this);
        this.processBasicTutorial = this.processBasicTutorial.bind(this);
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {data_1: 'planning_detail', data_2: this.state.record_id} );

        basicAccountInfo()
        .then(
            data => {
                this.fetchRecord();
            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            }
        });
    }

    fetchRecord() {

        if (!this.state.record_id){
            return;
        }

        var request = { 
            record_id: this.state.record_id,
        }

        generic_get_api(SF_PLANNING_DETAIL, request)
        .then(
            data => { 
                this.setState({
                    record_details: data,
                }, this.startTutorial);
                window.scrollTo(0, 0);
            }
        )
        .catch(error => {
        });
    }

    startTutorial() {
        let basic_detail_tutorial = localStorage.getItem('basic_detail_tutorial');

        if (basic_detail_tutorial && basic_detail_tutorial.toLocaleLowerCase() === 'true'){
            this.setState({basic_detail_tutorial: true});
        }
    }

    processBasicTutorial(step_object) {

        if (step_object.status === 'finished') {
            
            localStorage.setItem('basic_detail_tutorial', 'false');
            this.setState({
                basic_detail_tutorial: false,
            });
        }
    }

    processInsightsTutorial(step_object) {

        if (step_object.status === 'finished') {
            
            localStorage.setItem('insights_tutorial', 'false');
            this.setState({
                insights_tutorial: false,
            });
        }
    }

    render() {

        const {record_details, highlight_file_names, basic_detail_tutorial, insights_tutorial} = this.state;

        return(
            <div class="container sfPlanningDetail">
                <Joyride 
                    run={record_details && basic_detail_tutorial}
                    steps={steps_basic} 
                    showSkipButton={false}
                    showStepsProgress={true}
                    continuous={true}
                    disableScrolling={true}
                    hideBackButton={true}
                    hideCloseButton={true}
                    callback={(e) => this.processBasicTutorial(e)}
                    styles={{
                        options: {
                          arrowColor: '#efecec',
                          primaryColor: '#0D6EFD',
                          textColor: '#004a14',
                          backgroundColor: '#efecec',
                          arrowColor: '#efecec',
                        },
                      }}
                 />
                {
                    !record_details ? 
                        <LoadingPlaceholder />
                    :
                    <div class="container detailSection">
                        <div class="row">
                            <div class="col-8 projectDescription">
                                <div class="row">
                                    <div class="col">
                                        <div class="row">
                                            <div class="col fs-6 fw-bold">{record_details['record_number']}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="container mt-3">
                                    <div class="row fw-lighter">Application summary</div>
                                    <div class="row">{record_details['description']}
                                    </div>
                                </div>
                                <div class="container mt-3 ps-0">
                                    <div class="row fw-lighter">
                                        <div class="col">Parent application type</div>
                                        <div class="col">Parent application status</div>
                                        <div class="col">Application date</div>
                                    </div>
                                    <div class="row">
                                        <div class="col">{record_details['record_type']}</div>
                                        <div class="col">{record_details['current_status']}</div>
                                        <div class="col">{record_details['application_date']}</div>
                                    </div>
                                </div>
                                {
                                    (record_details.insight.received_opposition || record_details.insight.has_appeal) && 
                                    <div class="row mt-4">
                                    {
                                        record_details.insight.has_appeal  &&
                                        <div class="col-auto"><i class="fa-solid fa-check" style={{"color": "#164734"}}></i>&nbsp;<small>Appealed</small></div>
                                    }
                                    {
                                        record_details.insight.received_opposition &&
                                        <div class="col-auto"><i class="fa-solid fa-check" style={{"color": "#164734"}}></i>&nbsp;<small>Received opposition</small></div>
                                    }
                                    </div>
                                }
                            </div>

                            <div class="col-4 propertyInfoColumn">
                                { (record_details['address']['street_name'] && record_details['address']['street_name'].toLocaleLowerCase() !== 'public') &&
                                    <div class="container">
                                        <div class="row">{record_details['address']['street_number']} {record_details['address']['street_name']} {record_details['address']['street_suffix']}</div>
                                        <div class="row">San Francisco {record_details['address']['postal']}</div>
                                    </div>
                                }
                                {
                                    (record_details['use_district'] || record_details['height_district']) && 
                                    <div class="container mt-3">
                                        <div class="row fw-lighter">
                                            <div class="col ps-0">Use (Zone)</div>
                                            <div class="col">Height district</div>
                                        </div>
                                        <div class="row">
                                            <div class="col ps-0"> {record_details['use_district']}</div>
                                            <div class="col">{record_details['height_district']}</div>

                                            {/* {record_details['use_district']}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;{record_details['height_district']} */}
                                        </div>
                                        
                                    </div>
                                }
                                {
                                    record_details['special_use_districts'] && record_details['special_use_districts'].length > 0 && 
                                    <div class="container mt-3">
                                        <div class="row fw-lighter">Special use districts</div>
                                        <div class="row">
                                            {
                                                record_details['special_use_districts'].map((district, idx) => {
                                                    if (idx < record_details['special_use_districts'].length - 1) {
                                                        return <>{district}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp; </>
                                                    }
                                                    else{
                                                        return <>{district}</>
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        
                        <InsightComponent insight={record_details['insight']}/>
                        <FileSection 
                            file_groups={record_details['file_groups']} 
                            highlight_file_names={highlight_file_names}
                        />

                    {   
                        record_details['related_records'] && record_details['related_records'].length > 0 &&
                        <>
                            <div class="row mt-5">
                                <div class="col fw-semibold text-success fs-6 subApplications"><i class="fa-regular fa-folder"></i>&nbsp;Sub applications</div>
                            </div>
                            <div class="row"><div class="col"><hr className="mt-1"></hr></div></div>
                            <div class="row mt-1">
                            {
                                record_details['related_records'].map(related_record => {
                                    return  <> 
                                                <RelatedRecordSection related_record={related_record} />
                                                <div class="row"><div class="col pe-5 ps-5"><hr></hr></div></div>
                                            </>
                                })
                            }
                            </div>
                        </>
                    }
                    </div>
                }
            </div>
        )
    }
}

function LoadingPlaceholder() {

    return(
            <div class="container detailSection">
                <div class="row">
                    <div class="col-8">
                        <div class="row">
                            <div class="col">
                                <div class="row ms-1">
                                    <Placeholder as="p" animation="glow">
                                        <Placeholder xs={4} bg="secondary"/>
                                    </Placeholder>
                                </div>
                                <div class="container mt-3 applicationSummary">
                                    <div class="row">
                                        <Placeholder as="p" animation="glow">
                                            <Placeholder xs={12} bg="secondary" size="lg"/>
                                            <Placeholder xs={12} bg="secondary" size="lg"/>
                                        </Placeholder>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div class="container mt-3 subApplications">
                        </div>
                        
                        <div class="container mt-3 availableFiles">
                            <div class="row">
                                <Placeholder as="p" animation="glow">
                                    <Placeholder xs={12} bg="secondary" size="lg"/>
                                    <Placeholder xs={12} bg="secondary" size="lg"/>
                                </Placeholder>
                            </div>
                        </div>
                            
                    </div>
                    <div class="col-4 propertyInfoColumn">
                        <div class="container">
                            <div class="row">
                                <Placeholder as="p" animation="glow">
                                    <Placeholder xs={12} bg="secondary"/>
                                    <Placeholder xs={12} bg="secondary"/>
                                </Placeholder>
                            </div>                           
                        </div>
                
                        <div class="container mt-3">
                            <div class="row">
                               
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="container mt-4">
                    <div class="row">
                        <div class="col">
                            <Placeholder as="p" animation="glow">
                                <Placeholder xs={12} bg="secondary" size="lg"/>
                                <Placeholder xs={12} bg="secondary" size="lg"/>
                            </Placeholder>
                        </div>
                    </div>
                </div>
            </div>
    )
}

function FileSection(props) {
    const {file_groups, highlight_file_names} = props;
    const [expand_map, setExpandMap] = useState({});

    const toggleState = (class_name) => {
        setExpandMap(prevState => ({
            ...prevState,
            [class_name]: !expand_map[class_name],
        }));
    }

    if (!file_groups || file_groups.length == 0){
        return null;
    }

    return(
        <div class="">
            <div class="row mt-5">
                <div class="col fw-semibold text-success fs-6 fileSection"><i class="fa-regular fa-file-lines"></i>&nbsp;Available files &amp; documents</div>
            </div>
            <div class="row"><div class="col"><hr className="mt-1"></hr></div></div>
            {
                highlight_file_names && highlight_file_names.length > 0 &&
                <div class="row mt-1">
                    <div class="col">
                        Your keywords also matched these files: <span className="fw-bold text-primary">{highlight_file_names.join(', ')}</span></div>
                </div>

            }
            <div class="row mt-1">
                {file_groups.map(file_group => {
                    return  <div class="row mt-2  border p-2 ms-2">
                                <div class="row fw-bold">
                                    <div class="col">
                                        <a onClick={() => {toggleState(file_group.class_name)}} role="button">
                                            {
                                                expand_map[file_group.class_name] ?
                                                    <i class="fa-regular fa-square-minus fa-lg"></i>
                                                :
                                                <i class="fa-regular fa-square-plus fa-lg"></i>
                                            }
                                        
                                        </a>
                                        &nbsp;<a onClick={() => {toggleState(file_group.class_name)}} role="button">{file_group.class_name}</a>&nbsp;({file_group.files.length}) 
                                    </div>
                                </div>
                                {
                                    expand_map[file_group.class_name] &&
                                    <>
                                        {
                                            file_group.files.map(file => {
                                                return <div class="row mt-1 ms-4 fileEntry"><span><i class="fa-regular fa-file"></i>&nbsp;<a target="_blank" className="text-secondary" role="button" href={file.signed_url}>{file.name}</a></span> </div>
                                            })
                                        }
                                    </>
                                }
                                
                            </div>
                })}
            </div>
        </div>
    )
}

function RelatedRecordSection(props) {

    const {related_record} = props;

    return(
        <div class="container">
            <div class="container">
                <div class="row fw-lighter">Application summary</div>
                <div class="row">{related_record['description']}</div>
            </div>
            <div class="container mt-3 ps-0">
                <div class="row fw-lighter">
                    <div class="col">Application number</div>
                    <div class="col">Application type</div>
                    <div class="col">Application status</div>
                    <div class="col">Application date</div>
                </div>
                <div class="row">
                    <div class="col">{related_record['record_number']}</div>
                    <div class="col">{related_record['record_type']}</div>
                    <div class="col">{related_record['current_status']}</div>
                    <div class="col">{related_record['application_date']}</div>
                </div>
            </div>
            {/* <div class="row">
                <div class="col">
                    {related_record['record_number']}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;
                    {related_record['record_type']}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;
                    {related_record['application_date']}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;
                    {related_record['current_status']}
                    
                </div>
            </div> */}
            {/* <div class="row mt-2">
                <div class="col-auto pe-0 text-primary"><i class="fa-regular fa-message"></i></div>
                <div class="col">{related_record['description']}</div>
            </div> */}
        </div>
    )
}

function InsightComponent(props) {

    const { insight } = props;

    const showInsights = () => {
        if (insight.show_time_line || insight.has_appeal || insight.received_opposition || 
            insight.cua_insight || insight.var_insight || insight.zad_insight) {
                return true;
            }
        return false;
    }

    if (!showInsights()){
        return null;
    }

    return (
        <div class="derivedSection mt-5">
           
            <div class="row">
                <div class="col fw-bold text-success fs-6"><i class="fa-solid fa-wand-magic-sparkles"></i>&nbsp;Insights</div>
            </div>
            <div class="row"><div class="col"><hr className="mt-1"></hr></div></div>
            
            {
                insight.show_time_line &&
                <div class="container">  
                    <div class="row mt-2">
                        <div class="col text-center fw-lighter">Application timeline</div>
                    </div>
                    <div class="row mb-5 mt-4">
                        <TimeLine
                            time_line={insight['time_line']}
                        />
                    </div>
                </div>
            }
            
            <VarInsightComponent var_insight={insight['var_insight']} />
            <ZadInsightComponent zad_insight={insight['zad_insight']} />
            <CuaInsightComponent cua_insight={insight['cua_insight']} />
        </div>
    )
}

function CuaInsightComponent(props) {
    const {cua_insight} = props;

    if (!cua_insight){
        return null;
    }

    return (
        <div class="row cuaInsight border rounded mt-4 ms-1 p-2">
            <div class="row fw-bold fs-6"><div class="col">Conditional use insights</div></div>

            <div class="container mt-3">
                <div class="row fw-lighter">
                    <div class="col">Decision</div>
                    <div class="col">Hearing date</div>
                </div>
                <div class="row">
                    <div class="col">{cua_insight['committee_recommendation']}</div>
                    <div class="col">{cua_insight['hearing_date']}</div>
                </div>
            </div>
            <div class="container mt-3">
                <div class="row fw-lighter">
                    <div class="col">Application summary</div>
                </div>
                <div class="row">
                    <div class="row"><div class="col">{cua_insight['project_description']}</div></div>
                    <div class="row mt-2"><div class="col">{cua_insight['public_comments']}</div></div>
                </div>
            </div>
            <div class="container mt-3">
                <div class="row fw-lighter">
                    <div class="col">Environmental determination</div>
                </div>
                <div class="row "><div class="col">{cua_insight['environmental_review']}</div></div>
            </div>


            <div class="row mt-4"><div class="col"><i class="fa-solid fa-scale-balanced"></i>&nbsp;Findings &amp; evaluations</div></div>

            {
                cua_insight['finding_list'] && cua_insight['finding_list'].length > 0 &&
                <>
                    {
                        cua_insight['finding_list'].map(issue=>{
                            return  <div class="row mt-3">
                                        <div class="row"><div class="col fw-lighter"><i>{issue[0]}</i></div></div>
                                        <div class="row"><div class="col">{issue[1]}</div></div>
                                    </div>
                        })
                    }
                </>
            }
        </div>
    )
}

function VarInsightComponent(props){
    const {var_insight} = props;

    if (! var_insight){
        return null;
    }

    return(         
            <div class="row varInsight border rounded mt-4 ms-1 p-2">
                <div class="row  fw-bold fs-6"><div class="col">Variance quick facts</div></div>
                <div class="container mt-3">
                    <div class="row fw-lighter"><div class="col">Variance sought</div></div>
                    <div class="row"><div class="col">{var_insight['variance_sought']}</div></div>
                </div>
                <div class="container mt-3">
                    <div class="row fw-lighter">
                        <div class="col">Variance decision</div>
                        <div class="col">Hearing date</div>
                        <div class="col">Planning code sections</div>
                    </div>
                    <div class="row">
                        <div class="col">{var_insight['decision']}</div>
                        <div class="col">{var_insight['hearing_date']}</div>
                        <div class="col">{
                            var_insight['planning_code_sections'] && var_insight['planning_code_sections'].length > 0 ?
                            <>
                            { 
                                var_insight['planning_code_sections'].map((section_entry, idx) => {
                                    if (idx < var_insight['planning_code_sections'].length - 1) {
                                        return <span><a href={section_entry.section_url} target="_blank">{section_entry.section_number}</a>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</span>
                                    }
                                    else {
                                        return <span><a href={section_entry.section_url} target="_blank">{section_entry.section_number}</a></span>
                                    }
                                    
                                })
                            }
                            </>
                            :
                            <span><i class="fa-regular fa-circle-xmark fa-sm"></i> N/A</span>
                        }</div>
                    </div>
                </div>
                <div class="container mt-3">
                    <div class="row fw-lighter"><div class="col">Variance description</div></div>
                    <div class="row"><div class="col">{var_insight['variance_description']}</div></div>
                </div>
            {
                var_insight['findings'] && var_insight['findings'].length > 0 &&
                <div class="">
                    <div class="row mb-2 mt-3">
                        <div class="col fw-lighter">Variance evaluation &amp; findings</div>
                    </div>
                    {
                        var_insight['findings'].map(finding_entry => {
                            return  <div class="mb-3 row">
                                        <div class="col-auto"><i class="fa-solid fa-caret-right fa-sm"></i></div>
                                        <div class="col">
                                            <div class="row"><div class="col fst-italic">{finding_entry[0]}</div></div>
                                            <div class="row"><div class="col mt-2">{finding_entry[1]}</div></div>
                                            <div class="row"><div class="col mt-2">Evaluation: <span className="fw-semibold">{finding_entry[2]}</span></div></div>
                                        </div>
                                    </div>
                        })
                    }
                </div>
            }
        </div>
    )
}

function ZadInsightComponent(props) {
    const {zad_insight} = props;

    if (!zad_insight){
        return null;
    }

    return (
        <div class="row zadInsight border rounded mt-4 ms-1 p-2">
            <div class="row  fw-bold fs-6"><div class="col">ZA determination insights</div></div>

            <div class="container mt-3">
                <div class="row fw-lighter">
                    <div class="col">Determination date</div>
                    <div class="col">Planning code sections</div>
                </div>
                <div class="row">
                    <div class="col">
                    {
                        zad_insight.determination ? 
                        <>{zad_insight['document_date']}</> :<>N/A</> 
                    }
                    </div>
                    <div class="col">
                    {
                         zad_insight['planning_code_sections'] && zad_insight['planning_code_sections'].length ? 
                         <>
                         { 
                             zad_insight['planning_code_sections'].map((section_entry, idx) => {
                                 if (idx < zad_insight['planning_code_sections'].length - 1) {
                                     return <span><a href={section_entry.section_url} target="_blank">{section_entry.section_number}</a>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</span>
                                 }
                                 else {
                                     return <span><a href={section_entry.section_url} target="_blank">{section_entry.section_number}</a></span>
                                 }
                                 
                             })
                         }
                         </> 
                         : <>N/A</> 
                    }
                    </div>
                </div>
            </div>
            <div class="container mt-3">
                <div class="row fw-lighter"><div class="col">Request</div></div>
                <div class="row"><div class="col">{zad_insight['request']}</div></div>
            </div>
            <div class="container mt-3">
                <div class="row fw-lighter"><div class="col">Determination</div></div>
                <div class="row"><div class="col">{zad_insight['determination']}</div></div>
            </div>
        </div>
    )
}

function TimeLine(props) {
    const {time_line} = props;
    const [split_time_line, setSplitTimeLine] = useState([]);

    useEffect(() => {
        let middle = Math.floor(time_line.length / 2);

        let list_1 = [];
        let list_2 = [];

        let i = 0;
        for(i; i < time_line.length; i++){
            if (i < middle) {
                list_1.push(time_line[i]);
            }
            else{
                list_2.push(time_line[i]);
            }
        }

        setSplitTimeLine([list_1, list_2]);
    }, [time_line]);

    if (time_line.length <= 0){
        return null;
    }


    return (                                            
        <>
            {
                time_line.length <= 7 &&
                <div class="container d-flex justify-content-center timeLine">
                    <div class="row">
                        <div class="col">
                            <div class="row text-center"><div class="col p-0 text-wrap width85"><small>{time_line[0][0]}</small></div></div>
                            <div class="row mt-1 mb-1"><div class="col p-0"><img src={left_line}/></div></div>
                            <div class="row text-center"><div class="col p-0"><small>{time_line[0][1]}</small></div></div>
                        </div>
                        {
                            time_line.map((entry, index) => {
                                if (index > 0 && index < time_line.length - 1){
                                    return  <div class="col">
                                                <div class="row text-center"><div class="col p-0 text-wrap width85"><small>{entry[0]}</small></div></div>
                                                <div class="row  mt-1 mb-1"><div class="col p-0"><img src={middle_line}/></div></div>
                                                <div class="row text-center"><div class="col p-0"><small>{entry[1]}</small></div></div>
                                            </div>
                                }
                                    
                            })
                        }
                        <div class="col">
                            <div class="row text-center text-wrap"><div class="col p-0 width85"><small>{time_line[time_line.length - 1][0]}</small></div></div>
                            <div class="row mt-1 mb-1"><div class="col p-0"><img src={right_line}/></div></div>
                            <div class="row text-center"><div class="col p-0"><small>{time_line[time_line.length - 1][1]}</small></div></div>
                        </div>
                    </div>
                </div>
            }
            {
                time_line.length > 7 && time_line.length <= 14 && split_time_line.length === 2 &&
                <>
                    <div class="container d-flex justify-content-center timeLine">
                        <div class="row">
                            <div class="col">
                                <div class="row text-center"><div class="col p-0 text-wrap width85"><small>{split_time_line[0][0][0]}</small></div></div>
                                <div class="row mt-1 mb-1"><div class="col p-0"><img src={left_line}/></div></div>
                                <div class="row text-center"><div class="col p-0"><small>{split_time_line[0][0][1]}</small></div></div>
                            </div>
                            {
                                split_time_line[0].map((entry, index) => {
                                    if (index > 0 && index < split_time_line[0].length - 1){
                                        return  <div class="col">
                                                    <div class="row text-center"><div class="col p-0 text-wrap width85"><small>{entry[0]}</small></div></div>
                                                    <div class="row  mt-1 mb-1"><div class="col p-0"><img src={middle_line}/></div></div>
                                                    <div class="row text-center"><div class="col p-0"><small>{entry[1]}</small></div></div>
                                                </div>
                                    }
                                        
                                })
                            }
                            <div class="col">
                                <div class="row text-center text-wrap"><div class="col p-0 width85"><small>{split_time_line[0][split_time_line[0].length - 1][0]}</small></div></div>
                                <div class="row mt-1 mb-1"><div class="col p-0"><img src={middle_line}/></div></div>
                                <div class="row text-center"><div class="col p-0"><small>{split_time_line[0][split_time_line[0].length - 1][1]}</small></div></div>
                            </div>
                        </div>
                    </div>
                    {/* second row */}
                    <div class="container d-flex justify-content-center timeLine">
                    <div class="row mt-5">
                        <div class="col">
                            <div class="row text-center"><div class="col p-0 text-wrap width85"><small>{split_time_line[1][0][0]}</small></div></div>
                            <div class="row mt-1 mb-1"><div class="col p-0"><img src={middle_line}/></div></div>
                            <div class="row text-center"><div class="col p-0"><small>{split_time_line[1][0][1]}</small></div></div>
                        </div>
                        {
                            split_time_line[1].map((entry, index) => {
                                if (index > 0 && index < split_time_line[1].length - 1){
                                    return  <div class="col">
                                                <div class="row text-center"><div class="col p-0 text-wrap width85"><small>{entry[0]}</small></div></div>
                                                <div class="row  mt-1 mb-1"><div class="col p-0"><img src={middle_line}/></div></div>
                                                <div class="row text-center"><div class="col p-0"><small>{entry[1]}</small></div></div>
                                            </div>
                                }
                                    
                            })
                        }
                        <div class="col">
                            <div class="row text-center text-wrap"><div class="col p-0 width85"><small>{split_time_line[1][split_time_line[1].length - 1][0]}</small></div></div>
                            <div class="row mt-1 mb-1"><div class="col p-0"><img src={right_line}/></div></div>
                            <div class="row text-center"><div class="col p-0"><small>{split_time_line[1][split_time_line[1].length - 1][1]}</small></div></div>
                        </div>
                    </div>
                    </div>
                </>
                
                
            }
          </> );
}

export default SfPlanningDetail;