import React, { Component } from "react";


import { EntityRole, LOGIN_URL, PASSWORD_RESET } from 'utils/constants';
import {trackEvent, EventNames} from 'utils/mixpanel';
import {AuthContext} from 'auth_context';
import {Button, Spinner} from 'react-bootstrap';
import { isValidEmail, isConstructionVendor } from "utils/helpers";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notAuthorized: null,
            showResetPasswordSection: false,
            resetEmailAddress: null,
            showResetConfirmation: false,
            showResetError: false,
            submitting: false,
        };
        this.formChanged = this.formChanged.bind(this);
        this.canSubmit = this.canSubmit.bind(this);
        this.handleSubmitLogin = this.handleSubmitLogin.bind(this);
        this.handlePasswordReset = this.handlePasswordReset.bind(this);
        this.inputOnKeyDown = this.inputOnKeyDown.bind(this);
    }

    inputOnKeyDown(e) {
        // User pressed the enter key
        if (e.keyCode === 13) {
            this.handleSubmitLogin();
        }
    }

    handlePasswordReset() {
        
        var request = { 
            email: this.state.resetEmailAddress,
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        };

        var url = new URL(PASSWORD_RESET);
        
        this.setState({ 
                submitting: true,
                notAuthorized: null,
                showResetError: false,
            }
        )

        trackEvent(PASSWORD_RESET, {'resetEmailAddress': request.email })

        fetch(url, requestOptions)
        .then(
            (result) => {
                if (result.status === 200) {
                    this.setState({
                        showResetConfirmation: true,
                    })
                }
                else if (result.status === 401) {
                    this.setState({
                        showResetError: true,
                        submitting: false,
                    })
                }
            }
        );
    }

    handleSubmitLogin()
    {
        var request = { 
            email: this.state.email,
            password: this.state.password
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        };

        var url = new URL(LOGIN_URL);
        this.setState(
            { 
                submitting: true,
                notAuthorized: null
            }
        )

        trackEvent(EventNames.LOGIN, {'email': request.email })

        fetch(url, requestOptions)
        .then(
            (result) => {
                if (result.status === 200) {
                    return result.json();  
                }
                else if (result.status === 401) {
                    this.setState({ 
                        notAuthorized: true,
                        submitting: false,
                     })
                }
            }
        ).then(
            loginInfo => {
                this.setState({ 
                    submitting: false,
                });

                if (loginInfo && loginInfo.token){
                    trackEvent(EventNames.LOGIN, {'email': request.email, 'success': true });
                    localStorage.setItem('token', loginInfo.token);

                    if (loginInfo.role && loginInfo.role === EntityRole.AGENT)
                    {   
                        localStorage.removeItem('show_first_run_card');
                        this.context.setLoggedInEntityRole(EntityRole.AGENT);
                        this.props.history.push('/agent/start');
                    }
                    else if (loginInfo.role && loginInfo.role === EntityRole.AGENT_CONTACT)
                    {
                        this.context.setLoggedInEntityRole(EntityRole.AGENT_CONTACT);
                        this.props.history.push('/contact/start');
                    }
                    else if (loginInfo.role && isConstructionVendor(loginInfo.role))
                    {
                        this.context.setLoggedInEntityRole(loginInfo.role);
                        this.props.history.push('/vendor/start');
                    }
                    else if (loginInfo.role && loginInfo.role === EntityRole.CODE_USER) {
                        this.context.setLoggedInEntityRole(EntityRole.CODE_USER);
                        localStorage.setItem('show_disclaimer_modal', 'true');

                        if (!localStorage.getItem('first_run_experience')) {
                            localStorage.setItem('first_run_experience', 'true');
                        }

                        this.props.history.push('/code/sf_search');
                    }
                    else {
                        this.context.setLoggedInEntityRole(EntityRole.UNKNOWN);
                        this.props.history.push('/');
                    }
                    
                }
            }
        );
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'login'})
        document.title = "Login to your Renolition account page";

        localStorage.setItem('token', '');
        this.context.setLoggedInEntityRole(null);
    }

    canSubmit() {
        return isValidEmail(this.state.email) && this.state.password;
    }

    formChanged(e, target) {
        this.setState({ [target]: e.target.value })
    }

    render() {
        return(
            <div class="loginPageEnvelope">
                <div class="loginPage">
                    
                    {! this.state.showResetPasswordSection &&
                        <div class="container loginSection">

                            <div class="row">
                                <div class="col display-6">Member login</div>
                            </div>
                            <div class="row mt-4 gy-2">
                                <div class="col-12 col-md-6"> 
                                    <input type="email" 
                                        placeHolder="Email address" 
                                        onChange={ (e) => this.formChanged(e, 'email') }
                                        value= { this.state.email }
                                        class="form-control"
                                    />
                                </div>
                                <div class="col-12 col-md-6">
                                    <input type="password" 
                                        placeHolder="Password" 
                                        onChange={ (e) => this.formChanged(e, 'password') }
                                        value= { this.state.password }
                                        class="form-control"
                                        onKeyDown={ this.inputOnKeyDown }
                                    />
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col text-center">
                                    { this.state.submitting ? 
                                            <Button
                                                variant="primary"
                                                disabled>  
                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                Log in</Button>
                                        : 
                                            <Button
                                                variant="primary"
                                                onClick={() => this.handleSubmitLogin()} 
                                                disabled={ !this.canSubmit() }
                                            >Log in</Button>
                                    }
                                </div>
                            </div>
                            
                            <div class="row mt-3">
                                <div class="col text-center">
                                    { this.state.notAuthorized &&
                                        <span class="text-danger">
                                            Incorrect email/password. Please try again.
                                        </span>
                                    }
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col text-center">
                                    <a role="button" onClick={() => this.setState({ showResetPasswordSection: true })}><strong>Forgot password?</strong></a>
                                </div>
                            </div>

                        </div>
                    }
                    {
                        this.state.showResetPasswordSection && !this.state.showResetConfirmation &&
                            <div class="container passwordReset text-center">
                                <div class="row">
                                    <div class="col text-center fs-5 fw-bold">
                                        Reset password
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col">
                                        Enter your email and we'll send you a password reset link
                                    </div>
                                </div>
                                <div class="row mt-3 justify-content-center">
                                    <div class="col"></div>
                                    <div class="col-10 col-md-6">
                                        <input type="email" class="form-control" placeHolder="Email address" value={this.state.resetEmailAddress} onChange={ (e) => this.formChanged(e, 'resetEmailAddress') }/>
                                    </div>
                                    <div class="col"></div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col text-center">
                                        { this.state.submitting ? 
                                        <Button variant="primary" disabled>
                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                    Sending...</Button>
                                            : 
                                            <Button variant="primary" 
                                                onClick={this.handlePasswordReset} 
                                                disabled={!isValidEmail(this.state.resetEmailAddress)}
                                            >Email reset link</Button>
                                        }
                                    </div>
                                </div>

                                {
                                    this.state.showResetError &&
                                        <div class="row mt-4">
                                            <div class="col text-danger text-center">Email address is not correct</div>
                                        </div>
                                }
                            </div>
                    }

                    {
                        this.state.showResetPasswordSection && this.state.showResetConfirmation &&
                        <div class="container">
                             <div class="row"><div class="col text-center">
                                You should receive an email shortly with a link to reset your password.
                            </div>
                            </div>
                        </div>
                    }           
                </div>
                
            </div>
        );
    }
}

Login.contextType = AuthContext;
export default Login;