import React, { Component  } from "react";
import {trackEvent, EventNames} from 'utils/mixpanel';
import { basicAccountInfo } from "api/basic_account_info";
import { isVendor } from "utils/helpers";
import {
    EntityRole,
} from 'utils/constants';

class IndexNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'index'})
    
        basicAccountInfo()
        .then(data=>{
            if (data.role === EntityRole.AGENT) {
                this.props.history.push('/agent/start');
            }
            else if (data.role === EntityRole.AGENT_CONTACT) {
                this.props.history.push('/contact/start');
            }
            else if (isVendor(data.role)) {
                this.props.history.push('/vendor/start');
            }
            else if (data.role === EntityRole.CODE_USER) {
                this.props.history.push('/code/sf_search');
            }
            else {
                // if user is not logged in
                this.props.history.push('/v1/code/intro');
            }
        })
        .catch(error => {
            this.props.history.push('/v1/code/intro');
        }); 
    }

    render() {
        return(<div></div>)
    }
}

export default IndexNew;
