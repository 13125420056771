import React from 'react';
// import add_vendors from 'assets/add_vendors.mp4';
// import share_vendors from 'assets/share_vendors.mp4';
import {trackEvent, EventNames} from 'utils/mixpanel';

class Tutorials extends React.Component {
    
    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'tutorials'})
    }

    render() {
       
        return (
            <div class="container text-center">
                <div class="row"><div class="col display-6">Renolition Tutorials</div></div>

                <div class="row mt-5">
                    <div class="col fw-bold">
                        Share vendors and highlight your brand
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col">
                        <video width="80%" height="80%" controls>
                            {/* <source src={share_vendors} type="video/mp4" /> */}
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>

                <div class="row mt-5">
                    <div class="col fw-bold">
                        Populate your vendors list
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col">
                        <video width="80%" height="80%" controls>
                            {/* <source src={add_vendors} type="video/mp4" /> */}
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>

            </div>
           );
      
    }
}

export default Tutorials;