import React, { Component } from "react";
import {trackEvent, EventNames} from 'utils/mixpanel';


class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'privacy'})
    }

    render() {

        return(
            <div class="PPEnvelope">
                <div class="privacy">
                    
                    <div class="ppGrid" >
                        <div class="ppText">
                            <h2>Privacy Policy</h2>
                            <p>Renolition Inc. d/b/a Renolition (“Renolition,” “Company,” “We,” “Our”) respects your privacy and is 
                                committed to protecting it through our compliance with this policy. This document details the types 
                                of information we may collect or that you may provide when you access, use or register with Renolition's 
                                website or software platform (“Services”) and our practices for using, maintaining, protecting and disclosing that information.</p>

                            <p>Please read this carefully to understand our policies and practices regarding your information and how we will treat it. By using 
                                or registering with our Services, you agree to this Privacy Policy. Your continued use of our Services after we make changes is 
                                deemed to be acceptance of those changes, so please check the policy periodically for updates as it may change from time to time.</p>

                            <h3>Information We Collect and How We Collect It</h3>
                            <p>We collect information from and about users of our Services:</p>
                            <ul>
                                <li>Directly from you when you provide it to us.</li>
                                <li>Automatically when you use the Services. </li>
                            </ul>
                            <h4>Information You Provide to Us </h4>
                            <p>When you use or register with the Services, we may ask you to provide information by which you may be personally identified, 
                                such as your name, email address, postal address, phone number, location, financial information, and any other identifier 
                                by which you may be contacted or identified online or offline ("Personal Information").  </p>
                            <p>This information includes:</p>
                            <ul>
                                <li>Information that you provide by filling in forms in the Services. This includes 
                                    information provided at the time of registering to use the Services.</li>
                                <li>Details of transactions you carry out through the Services and of the fulfillment of 
                                    your orders and requests, including tour details, schedules and documents.</li>
                                <li>Records and copies of your correspondence (including e-mail addresses and phone numbers), if you contact us. We may 
                                    also ask you for information when you report a problem with the Services.</li>
                                <li>Your responses to surveys that we might ask you to complete for research and development purposes.</li>
                            </ul>

                            <h4>Automatic Information Collection</h4>
                            <p>When you download, access or use the Services, it may use technology to automatically collect:</p>
                            <ul>
                                <li>Usage Details. When you access and use the Services, we may automatically collect certain details of your access to 
                                    and use of the Services, including traffic data, location data, logs and other communication data and the resources 
                                    that you access and use on or through the Services. </li>
                                <li>Device Information. We may collect information about your computer, mobile device and internet connection, including 
                                    the computer or mobile device's unique device identifier, IP address, operating system, browser type, mobile network 
                                    information and the device's telephone number.

                                </li>
                            </ul>

                            <h4>Local Storage, Cookies, Web Beacons, and Tracking</h4>
                            <p>Our Services use local storage and cookies, which are small text files that are intended to make the Services better for you to use. 
                                In general, cookies are used to retain preferences, store information for things like shopping carts, and provide tracking data to 
                                third-party applications like Google Analytics, Mixpanel, and others.</p>

                            <p>We may also employ software technology known as “web beacons” or “clear GIFs,” which helps us keep track of what content on our service 
                                is effective. Web beacons are small graphics with a unique identifier that are used to track the online movements of Internet users. 
                                Web beacons are embedded in the web pages you review, so they are not stored on your hard drive. The web beacons we may use will not 
                                track or collect any personally identifiable information about you and they are in no way linked to your personally identifiable information.</p>

                            <p>The Services also use third-party analysis and tracking services to track the performance of our services, understand how you use our services, 
                                and offer you an improved and safer experience.  Such usage information will be deleted as soon as this information is no longer required for
                                 the purpose collected, and will not be shared with third parties until after being anonymized.</p>

                            <h3>Use of Personal Information We Collect</h3>
                            <p>We may use the information we collect from You in the following ways:</p>
                            <ul>
                                <li>To set up your account with us. </li>
                                <li>To perform transactions on your behalf through the Services and third-party service providers.</li>
                                <li>To personalize your user experience and to allow us to deliver the type of content and offerings in which you are most interested. </li>
                                <li>To send marketing and promotional materials.</li>
                                <li>To deliver service messages and other services and content you request and send information related to accounts and services, including confirmations, 
                                    invoices, technical notices, updates, security alerts, and support and administrative messages, and to enable user-to user communication.</li>
                                <li>To conduct an aggregated analysis of the performance of our Services, including referral data if you arrive at our website from an external source. </li>
                            </ul>
                            <p>All the data we collect at Renolition is necessary for us to deliver the services you use. The amount we collect has been minimized wherever possible to respect your privacy.</p>
                            <p>Renolition's use and transfer to any other app of information received from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank">Google API Services User Data Policy</a>, including the Limited Use requirements.</p>

                            <h3>How We May Share Information We Collect</h3>
                            <p>We may share personal information as follows: </p>
                            <ul>
                                <li>To provide to third party service providers that perform services on our behalf, such as third-party accounting or payment platforms.</li>
                                <li>We may share personal information when we do a business deal, or negotiate a business deal, involving the sale or transfer of all or a part 
                                    of our business or assets. These deals can include any merger, financing, acquisition, or bankruptcy transaction or proceeding. However, 
                                    this information will be in the aggregate and anonymized to protect your personal information.</li>
                                <li>We may share information to respond to lawful requests and legal processes.</li>
                                <li>We may share information to protect the rights and property of Renolition, our agents, customers, and others. This includes enforcing our agreements, policies, and terms of use. </li>
                                <li>We may share information in an emergency. This includes protecting the safety of our employees and agents, our customers, or any person. </li>
                                <li>We may share information with those who need it to do work for us.</li>
                            </ul>
                            <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information except as outlined by this Privacy Policy.</p>

                            <h3> Data Retention</h3>
                            <p>We retain personal information we collect from you where we have an ongoing legitimate business need to do so (for example, to provide you with a service 
                                you have requested or to comply with applicable legal, tax, or accounting requirements).  </p>
                            <p>When we have no ongoing legitimate business need to process personal information, we will either delete or anonymize it or, if this is not possible 
                                (for example, because personal information has been stored in backup archives), then we will securely store personal information and isolate it 
                                from any further processing until deletion is possible.</p>
                            
                            <h3>Security of Your Personal Information </h3>
                            <p>The security of your Personal Information is important to us. When you enter sensitive information (such as credit card number) on our Services, 
                                we encrypt that information using secure socket layer technology (SSL).</p>
                            <p>Renolition takes reasonable security measures to protect your Personal Information to prevent loss, misuse, unauthorized access, disclosure, alteration, and destruction. Please be aware, 
                                however, that despite our efforts, no security measures are impenetrable.</p>
                            <p>If you use a password on the Services, you are responsible for keeping it confidential. Do not share it with any other person. If you believe your password has been misused, 
                                please notify us immediately.</p>
                                
                            <h3>Your Data Protection Rights under the California Consumer Privacy Act (CCPA)</h3>
                            <p>The California Consumer Privacy Act (“CCPA”) provides consumers with specific rights regarding their Personal Information. If you are a California resident, 
                                you have the right to:</p>
                            <ul>
                                <li>Request we disclose to you free of charge the following information covering the 12 months preceding your request: (a) the categories of Personal 
                                    Information about you that we collected; (b) the categories of sources from which the Personal Information was collected; (c) the purpose for 
                                    collecting Personal Information about you; (d) the categories of third parties to whom we disclosed Personal Information about you and the 
                                    categories of Personal Information that was disclosed (if applicable) and the purpose for disclosing the Personal Information about you; and
                                     (e) the specific pieces of Personal Information we collected about you;</li>
                                <li>Request we delete Personal Information we collected from you, unless the CCPA recognizes an exception; </li>
                                <li>If the business sells Personal Information, you have a right to opt-out of that sale.</li>
                            </ul>
                            <p>Consistent with California law, if you choose to exercise your applicable CCPA rights, we will not charge you different prices or provide you a different 
                                quality of services. If we ever offer a financial incentive or product enhancement that is contingent upon you providing your Personal Information, we 
                                will not do so unless the benefits to you are reasonably related to the value of the Personal Information that you provide to us. If you are a California 
                                resident and wish to exercise your rights under the CCPA, please contact us at support@renolition.com.</p>
                            <h3>Updating and Accessing Your Personal Information</h3>
                            <p>If your Personal Information changes, we invite you to correct or update your information. We will retain your information for as long as your account 
                                is active or as needed to provide you services. If you wish to cancel your account, request that we no longer use your information to provide 
                                you services, or delete your Personal Information, you may do so through your account settings, or contact us at support@renolition.com. We will 
                                respond to your request to have your Personal Information updated as soon as possible. We will retain and use your information as necessary to 
                                comply with our legal obligations, resolve disputes, and enforce our agreements.</p>

                            <h3>Choices About Use of Your Information</h3> 
                            <p>You can request that your account be deleted, however, certain financial record keeping information may be maintained in our systems because we 
                                have a legitimate legal and financial interest in maintaining such records.</p>
                            <p>You may unsubscribe from receiving certain promotional emails from us. If you wish to do so, simply follow the instructions found at the end of 
                                the email. Even if you unsubscribe, we may still contact you for informational, transactional, account-related, or similar purposes, including 
                                messages about your current Renolition account and services.</p>
                            <p>Many browsers have an option for disabling cookies, which may prevent your browser from accepting new cookies or enable selective use of cookies. 
                                Please note that, if you choose not to accept cookies, some features and the personalization of our Services may no longer work for you. You will 
                                continue to receive advertising material but it will not be tailored to your interests.</p>
                            
                            <h3>Third-Party Disclosure and Links</h3>  
                            <p>The Services may include links to other websites whose privacy practices may differ from those of Renolition. If you submit Personal Information to 
                                any of those sites, your information is governed by their privacy statements. We encourage you to carefully read the privacy statement of any 
                                third-party websites you visit.</p>
                                
                            <h3>Notice Concerning The Information Of Children</h3>
                            <p>Renolition is not directed to children under the age of 13 and does not intentionally collect any information from children under the age of 13. 
                                Please contact us if your child has provided Personal Information to us and we will delete the information.</p>
                            
                            <h3>Changes to This Policy</h3>
                            <p>Renolition may change this Policy from time to time. If we make any changes to this Policy, we will change the "Last Updated" date below and may 
                                notify you by email (sent to the e-mail address specified in your account). We encourage you to review this Policy whenever you visit the Services 
                                to understand how your Personal Information is used.</p>

                            <h3>Questions About This Policy</h3>
                            <p>If you have any questions about this Policy, our practices related to the Services, or if you would like to have us remove your information 
                                from our database, please feel free to contact us at support@renolition.com. </p>

                            <p>Last Update: March 19, 2025</p>
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
}

export default PrivacyPolicy;
                            