import React, { Component } from "react";

import { INFO_REQUEST_URL } from 'utils/constants'
import ContactUs from 'pages/contact_us';
import {trackEvent, EventNames} from 'utils/mixpanel';
import rocket from 'assets/reputation/rocket.svg';
import thinking from 'assets/reputation/thinking.png';
import customer from 'assets/reputation/customer.png';
import public_relation from 'assets/reputation/public_relation.png';
import vendor_sharing from 'assets/reputation/vendor_sharing.png';
import property_inspector_feature from 'assets/reputation/property_inspector_feature.png';
import discover_feature from 'assets/reputation/discover_feature.png';
import { Button, Spinner, Card } from "react-bootstrap";
import { isValidEmail } from "utils/helpers";
// import intro_video from 'assets/intro_video.mp4';

const ActiveFeature = {
    SHARE: 0,
    DISCOVER: 1,
    EXPLORE: 2,
}
const feature_carousel_delay = 10000;

class AgentLanding extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            show_contact_us_modal: false,
            active_feature_page: ActiveFeature.SHARE,
            name: "",
            company: "",
            email: "",
            phone: "",
            submitted: false,
            showSpinner: false,
        };

        this.formChanged = this.formChanged.bind(this);
        this.handleSubmitRequest = this.handleSubmitRequest.bind(this);
        this.setShowContactUsModal = this.setShowContactUsModal.bind(this);
    }
    
    setShowContactUsModal(value) {
        this.setState({show_contact_us_modal: value});
    }

    handleSubmitRequest() {

        var request = { 
            name: this.state.name,
            company: this.state.company,
            email: this.state.email,
            phone: this.state.phone,
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        };

        var url = new URL(INFO_REQUEST_URL);
        this.setState({ showSpinner: true })

        trackEvent(EventNames.API_REQUEST, {'data_1': request, "data_2": INFO_REQUEST_URL})

        fetch(url, requestOptions)
        .then(
            (result) => {
                if (result.status == 201) {
                    this.setState({
                        submitted: true,
                        showSpinner: false
                    })
                }
                else {
                    this.setState({
                        showSpinner: false
                    })
                }
            }
        );
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'agent_landing'})

        const interval = setInterval(() => {
            let curr_active = this.state.active_feature_page;
            let next_page = (curr_active + 1) % 3;
            this.setState({active_feature_page: next_page});
        }, feature_carousel_delay);

        this.setState({
            interval_counter: interval,
        })

        const img = new Image();
        img.src = discover_feature;
    }

    componentWillUnmount(){
        if (this.state.interval_counter) {
            clearInterval(this.state.interval_counter);
        }
      }

    formChanged(e, element) {
        this.setState({ [element]: e.target.value })
    }

    render() {
        const canSubmit = () => {
            return this.state.name && this.state.email && this.state.phone && isValidEmail(this.state.email) && this.state.company;
        }

        const getButtonVariant = (feature_id) => {
            if (this.state.active_feature_page === feature_id) {
                return "primary";
            }
            return "outline-primary";
        }

        return(
            <div class="agentLandingEnvelope">
                <div class="agentLandingMain bg-success p-5">
                    <div class="container">
                        <div class="row gy-5">
                            <div class="col-lg-2 rocket"><img src={rocket}/></div>
                            <div class="col-12 col-md-6 display-6  col-lg-6 text-white position-relative">
                                {/* <span class="display-6 position-absolute top-50 start-0 translate-middle-y"> */}
                                    Expand the Power of Your Vendor Network & Elevate Your Brand
                                {/* </span> */}
                            </div>
                            <div class="col-12 col-md-6 col-lg-4">
                                <div class="container bg-secondary p-3">
                                    { !this.state.submitted ?
                                        <div class="row">
                                            <div class="col-12 text-warning fs-5 fw-bold"> 
                                                GET ACCESS
                                            </div>
                                            <div class="col-12 mt-3"><input type="text" class="form-control" placeholder="Name" value={ this.name } onChange={ (e) => this.formChanged(e, 'name') }/></div>
                                            <div class="col-12 mt-2"><input type="text" class="form-control"  placeholder="Email"  value={ this.email } onChange={ (e) => this.formChanged(e, 'email') }/></div>
                                            <div class="col-12 mt-2"><input type="text" class="form-control"  placeholder="Phone"  value={ this.phone } onChange={ (e) => this.formChanged(e, 'phone') }/></div>
                                            <div class="col-12 mt-2">
                                                <select class="form-select" name="questionCategory" onChange={(e)=>this.formChanged(e, 'company' )}>
                                                    <option disabled selected value>Select Category</option>
                                                    <option value="Agent" >Real estate professional</option>
                                                    <option value="Vendor" >Vendor</option>
                                                </select></div>
                                            <div class="col-12 mt-4 text-center">
                                                { this.state.showSpinner ? 
                                                    <Button variant="warning" disabled>
                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                        SUBMITTING</Button>
                                                :
                                                    <Button variant="warning" onClick={ this.handleSubmitRequest } disabled={!canSubmit()}>SUBMIT NOW</Button>
                                                }
                                                
                                            </div>
                                        </div>
                                    :
                                        <div class="row">
                                            <div class="col text-white">Thank you for your submission. We will be in touch very soon.</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container bg-white features pt-5 pb-5">
                    <div class="container benefits text-center pb-5">
                        <div class="row">
                            <div class="col display-6">Find, share, organize &amp; book vendors</div>
                        </div>
                        <div class="row mt-5 gy-4">
                            <div class="col-12 col-lg-2">
                                <div class="container">
                                    <div class="row gy-3 d-flex justify-content-center">
                                        <div class="col-auto col-lg-12 text-start">
                                            <Button className="featureButton" variant={getButtonVariant(0)} 
                                            onClick={() => this.setState({active_feature_page: ActiveFeature.SHARE})}>Engage</Button>
                                        </div>
                                        <div class="col-auto col-lg-12 text-start">
                                            <Button className="featureButton" variant={getButtonVariant(1)} 
                                            onClick={() => this.setState({active_feature_page: ActiveFeature.DISCOVER})}>Discover</Button>
                                        </div>
                                        <div class="col-auto col-lg-12 text-start">
                                            <Button className="featureButton" variant={getButtonVariant(2)}
                                            onClick={() => this.setState({active_feature_page: ActiveFeature.EXPLORE})}>Explore</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.active_feature_page === ActiveFeature.SHARE && 
                                <>
                                    <div class="col-12 col-lg-6 text-center">
                                        <img src={vendor_sharing} class="benefitPhoto"/>
                                    </div>
                                    <div class="col-12 col-lg-4 text-left p-3">
                                        <div class="h2">Engage existing clients</div>
                                        <div>Organize your vendors &amp; share vendors with your clients in a brand-focused way.</div>
                                    </div>
                                </>
                            }
                            {
                                this.state.active_feature_page === ActiveFeature.DISCOVER && 
                                <>
                                    <div class="col-12 col-lg-6 text-center benefitPhotoWrapper">
                                        <img src={discover_feature} class="benefitPhoto"/>
                                    </div>
                                    <div class="col-12 col-lg-4 text-left p-3">
                                        <div class="h2">Discover new vendors</div>
                                        <div>Expand your vendor network with our powerful search and filtering tools.</div>
                                        <div class="mt-2">Choose with confidence with detailed permit history, licensing info, and <strong>verified reviews</strong>. </div>
                                        <div class="mt-2">Connect with other agents in the city &amp; your office to gain access to even more resources. </div>
                                    </div>
                                </>
                            }
                            {
                                this.state.active_feature_page === ActiveFeature.EXPLORE && 
                                <>
                                    <div class="col-12 col-lg-6 text-center">
                                        <img src={property_inspector_feature} class="benefitPhoto"/>
                                    </div>
                                    <div class="col-12 col-lg-4 text-left p-3">
                                        <div class="h2">Explore properties</div>
                                        <div>Discover the detailed permit history for San Francisco properties. </div>
                                        <div class="mt-2">Find the contractor, architect &amp; engineer for each project. </div>
                                    </div>
                                </>
                            }
                           
                        </div>
                    </div>
                </div>

                <div class="p-5 video">
                    <div class="container">
                        <div class="row mt-3 gy-3">
                            <div class="col text-center">
                                <video width="90%" controls>
                                    {/* <source src={intro_video} type="video/mp4" /> */}
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div class="p-5 offer">
                    <div class="container">
                        <div class="row"> <div class="col text-center display-6">Renolition is a unique platform for real estate professionals</div></div>
                        <div class="row mt-5 gy-3">
                            <div class="col-12 col-md-4">
                                <Card className="offerCard">
                                    <Card.Body>
                                        <div class="container text-center">
                                            <div class="row"><div class="col"><img src={thinking} class="cardIcon mt-3"/></div></div>
                                            <div class="row mt-3"><div class="col h4 text-secondary">Stay top of mind</div></div>
                                            <div class="row mt-2"><div class="col">Be the <strong>#1 resource</strong> for your clients for all things home-related.</div></div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div class="col-12 col-md-4">
                                <Card className="offerCard">
                                    <Card.Body>
                                        <div class="container text-center">
                                            <div class="row"><div class="col"><img src={public_relation} class="cardIcon mt-3"/></div></div>
                                            <div class="row mt-3"><div class="col h4 text-secondary">Build your brand</div></div>
                                            <div class="row mt-2"><div class="col">Use your vendor network to re-enforce your brand and grow your business.</div></div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div class="col-12 col-md-4">

                                <Card className="offerCard">
                                    <Card.Body>
                                        <div class="container text-center">
                                            <div class="row"><div class="col"><img src={customer} class="cardIcon mt-3"/></div></div>
                                            <div class="row mt-3"><div class="col h4 text-secondary">Fresh vendor list</div></div>
                                            <div class="row mt-2"><div class="col">Keep your vendor network updated with our extensive review sources and metrics.</div></div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="bg-light">
                    <div class="container problemDescription p-5">
                        <div class="row">
                            <div class="col text-center">
                                <div>
                                    <Card>
                                        <Card.Body>
                                            <Card.Text>
                                                <div class="display-6 mb-5">Your vendor network is crucial for your business</div>
                                                <div>Whether closing deals or sharing with your clients, <strong>Renolition</strong> helps you save time and grow your business.</div>
                                                <div class="mt-3">Free to join.</div>
                                                <div class="text-center mt-5">
                                                    <Button variant="primary" size="lg" onClick={() => this.setShowContactUsModal(true)}>Request access</Button>
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <ContactUs 
                    displayStatus={this.state.show_contact_us_modal} 
                    changeDisplayStatus={this.setShowContactUsModal}
                />
            </div>
        )
    }
}

export default AgentLanding;