import { Component } from "react";
import { INVITE_INFO_URL, SIGN_UP_URL, EntityRole } from 'utils/constants';
import { trackEvent, EventNames } from 'utils/mixpanel';
import { isValidEmail, isValidPassword, isValidPhoneNumber } from "utils/helpers";
import { Button, Spinner, Card } from 'react-bootstrap';
import { AuthContext } from 'auth_context';
import { generic_get_api, generic_post_api } from "api/generic_api";


var fieldsMatch = (field1, field2) => {
    if (!field1 || !field2) { return true; }

    return field1 === field2;
} 

var emailAddressMatching = (email_1, email_2) => {
    // have not started typing yet
    if (!email_1 || !email_2) { return true; }

    return email_1.toLowerCase() === email_2.toLowerCase();
}

class SignUp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
            phone: '', // Phone number without formatting. Must be set to empty string or user can type non-digits on first try. 
            phone_display: '', // formatted phone number that we show
            error_message: null,
            valid_invite: false,
            show_password: false,
        };

        this.formChanged = this.formChanged.bind(this);
        this.canSubmit = this.canSubmit.bind(this);
        this.handleSubmitRequest = this.handleSubmitRequest.bind(this);
        this.fetchInvitationInfo = this.fetchInvitationInfo.bind(this);
    }

    handleSubmitRequest()
    {
        this.setState({ 
            submitting: true,
            error_message: null,
         });

        var request = { 
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            password: this.state.password,
            phone: this.state.phone,
            invitation_id: this.state.invitation_id,
        }

        generic_post_api(request, SIGN_UP_URL)
        .then(
            result => {

                if (result.status === 409){
                    return result.data.json().then(text => { throw new Error(text)} )
                }
                else if (result.status === 201){
                    return result.data.json();
                }
            }
        ).then(
            data => {
                trackEvent(EventNames.SIGN_UP_URL, { 'success': true })
                window.scrollTo(0, -100);

                if (data.token) {
                    this.context.setLoggedInEntityRole(EntityRole.CODE_USER);
                    localStorage.setItem('token', data.token);
                    
                    // We can set all FRE and tutorial markers since signup is run once per user
                    localStorage.setItem('show_disclaimer_modal', 'true');
                    localStorage.setItem('result_row_tutorial', 'true');
                    localStorage.setItem('show_search_tutorial', 'true');
                    localStorage.setItem('basic_detail_tutorial', 'true');
                    
                    this.props.history.push('/code/sf_search');
                }

                this.setState({ submitting: false });
            })
        .catch(error => {
            this.setState({ 
                submitting: false,
                error_message: error.message,
                })
        });
    }

    canSubmit() {
        const {first_name, last_name, email, email_confirm, phone, password, password_confirm} = this.state;

        // let basic_info_valid = first_name && last_name;
        let email_valid = isValidEmail(email) && email_confirm && (email.toLowerCase() === email_confirm.toLowerCase());
        // let password_valid = isValidPassword(password) && (password ==password_confirm);
        // let phone_valid = isValidPhoneNumber(phone);

        return first_name && last_name && email_valid && isValidPassword(password) && this.state.valid_invite;
    }

    formChanged(e, target) {
        this.setState({ [target]: e.target.value })
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'new_account' });

        document.title = "Sign up for a new account";

        let pathSplit = window.location.pathname.split('/');
        
        if (pathSplit.length === 4) {
            this.setState({
                invitation_id: pathSplit[3],
            }, this.fetchInvitationInfo);
        }
    }

    fetchInvitationInfo() {

        generic_get_api(INVITE_INFO_URL, {invitation_id: this.state.invitation_id})
        .then(
            data => {
                if (data.processed) {
                    this.setState({
                         error_message: 'This invitation was already processed',
                    });
                 }
                 else {
                     this.setState({
                         first_name: data.first_name,
                         last_name: data.last_name,
                         email: data.email,
                         email_confirm: data.email,
                         valid_invite: true,
                     });
                 }
            }
        )
        .catch(error => {
            this.setState({
                error_message: 'Invalid invitation',
           });
        });
    }

    render() {
        const {first_name, last_name, email, email_confirm, show_password, password, password_confirm, submitting, valid_invite} = this.state;

        const setPhoneNumber = (new_phone_value) => {
            if (new_phone_value != null && new_phone_value.length >= 0) {
                new_phone_value = new_phone_value.replace(/\D/g, '');

                let formatted_number = "";
    
                if (isValidPhoneNumber(new_phone_value)) {
                    formatted_number = "(" + new_phone_value.substring(0, 3) + ") " + new_phone_value.substring(3, 6) + "-" + new_phone_value.substring(6, 10);
                } else if (new_phone_value.length < 10) {
                    formatted_number = new_phone_value;
                }
                else if (new_phone_value.length > 10) {
                    new_phone_value = new_phone_value.substring(0, 10);
                    formatted_number = "(" + new_phone_value.substring(0, 3) + ") " + new_phone_value.substring(3, 6) + "-" + new_phone_value.substring(6, 10);
                }
    
                this.setState({ 
                    phone: new_phone_value,
                    phone_display: formatted_number,
                });
            }
        }

        const passwordHas8Char = () => {
            return password && password.length >= 8;
        }

        const passwordHasOneDigit = () => {
            let reg = /^.*\d+.*$/
            return password && reg.test(password)
        }

        const passwordHasLower = () => {
            let reg = /^.*[a-z]+.*$/
            return password && reg.test(password)
        }

        const passwordHasUpper = () => {
            let reg = /^.*[A-Z]+.*$/
            return password && reg.test(password)
        }

        const passwordHasOneSpecial = () => {
        
            let reg = /^.*[!@#$%^&*_]+.*$/
            return password && reg.test(password)
        }

        const getPasswordInputType = () => {

            if (show_password){
                return "text";
            }
            else{
                return "password";
            }
        }

        return(
            <div class="signUpEnvelope">
                <div class="signUpPage">
                    <div class="signUpPageSection container">
                        {/* <div class="row"><div class="col display-6">Sign up</div></div> */}
                        <div class="row"><div class="col mt-3"> Already have an account? <a role="button" onClick={ () => this.props.history.push('/login')}><strong>Log in here</strong></a></div></div>
                            <Card className="mt-3">
                                <Card.Body>
                                    <div class="container mt-2">
                                        { this.state.error_message && 
                                            <div class="row mb-3">
                                                <div class="col text-danger text-center">
                                                    { this.state.error_message }
                                                </div>
                                            </div>
                                        }
                                        {
                                            !valid_invite && !this.state.error_message &&
                                            <>
                                                <div class="row">
                                                    <div class="col text-center">
                                                        <Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" />
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col text-center mt-2">Fetching your invitation info</div>
                                                </div>
                                            </>

                                        }
                                        <div class="row">
                                            <div class="col fs-4 text-center">Sign up for Renolition</div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col text-center">The most comprehensive tool for searching past entitlement permits</div>
                                        </div>

                                        <div class="row gy-2 mt-4">
                                            <div class="col-12 col-md-6">
                                                <label for="firstNameField" class="form-label ms-1 mb-1 fw-semibold"><small>First name</small></label>
                                                <input type="text" 
                                                    // placeHolder="First name" 
                                                    onChange={ (e) => this.formChanged(e, 'first_name') }
                                                    value= { first_name }
                                                    class="form-control"
                                                    id='firstNameField'
                                                />
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <label for="lastNameField" class="form-label ms-1 mb-1 fw-semibold"><small>Last name</small></label>
                                                <input type="text" 
                                                    // placeHolder="Last name" 
                                                    onChange={ (e) => this.formChanged(e, 'last_name') }
                                                    value= { last_name }
                                                    class="form-control"
                                                    id='lastNameField'
                                                />
                                            </div>
                                        </div>
                                        <div class="row gy-2 mt-0">
                                            <div class="col-12 col-md-6">
                                                <label for="emailField" class="form-label ms-1 mb-1 fw-semibold"><small>Email</small></label>
                                                <input type="email" 
                                                    // placeHolder="Email" 
                                                    onChange={ (e) => this.formChanged(e, 'email') }
                                                    value= { email }
                                                    class="form-control"
                                                    id="emailField"
                                                />
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div>
                                                    <label for="email-confirm" class="form-label ms-1 mb-1 fw-semibold"><small>Confirm email</small></label>
                                                    <input type="email" 
                                                        // placeHolder="Confirm email" 
                                                        onChange={ (e) => this.formChanged(e, 'email_confirm') }
                                                        value= { email_confirm }
                                                        disabled = { !isValidEmail(email) }
                                                        class="form-control"
                                                        id="email-confirm"
                                                    />
                                                </div>
                                                {!emailAddressMatching(email, email_confirm) &&
                                                    <div class="form-text text-danger">Emails do not match</div>
                                                }
                                            </div>
                                        </div>
                                        
                                        {/* <div class="row gy-2 mt-0">
                                            <div class="col-12 col-md-6">
                                                <input type="tel" 
                                                    placeHolder="Phone number (optional)" 
                                                    onChange={ (e) => setPhoneNumber(e.target.value) }
                                                    value= { phone_display }
                                                    class="form-control"
                                                />
                                            </div>
                                        </div> */}
                                        
                                        <div class="row mt-2">
                                            <div class="col-12 col-md-6">
                                                <div class="row">
                                                    <div class="col text-start ms-1  fw-semibold"><small>Password</small></div>
                                                    
                                                    <div class="col text-end  fw-semibold me-1">
                                                        {
                                                            !show_password ? 
                                                            <>
                                                                <a role="button" className="text-decoration-none" onClick={()=> this.setState({show_password: true})}><i class="fa-solid fa-eye"></i>&nbsp;<small>Show</small></a>
                                                            </>
                                                            :
                                                            <>
                                                            <a role="button" className="text-decoration-none"  onClick={()=> this.setState({show_password: false})}><i class="fa-solid fa-eye-slash"></i>&nbsp;<small>Hide</small></a>
                                                            </>
                                                        }            
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row gy-2 mt-0">
                                            <div class="col-12 col-md-6 mt-1">
                                                <input type={getPasswordInputType()} 
                                                    // placeHolder="Password" 
                                                    onChange={ (e) => this.formChanged(e, 'password') }
                                                    value= { password }
                                                    class="form-control"
                                                    id="passwordField"
                                                />
                                                {/* { password && password.length > 0 && !isValidPassword(password) &&
                                                    <div class="form-text text-danger" id="confirmPasswordField">Weak password</div>
                                                } */}
                                                {/* <div class="form-text mt-1" id="passwordField">Must contain at least eight characters, including at least 1 upper case letter, 1 lower case letter, 1 number and a special character from !@#$%^&*_</div> */}

                                                <div class="form-text mt-1 ms-1" id="passwordField">
                                                    {
                                                        passwordHasLower() ?
                                                        <span className="text-success"><i class="fa-solid fa-check"></i>&nbsp;One lowercase character</span>
                                                        :
                                                        <span className="text-danger"><i class="fa-solid fa-xmark"></i>&nbsp;One lowercase character</span>
                                                    }                 
                                                </div>
                                                <div class="form-text mt-1 ms-1" id="passwordField">
                                                    {
                                                        passwordHasUpper() ?
                                                        <span className="text-success"><i class="fa-solid fa-check"></i>&nbsp;One uppercase character</span>
                                                        :
                                                        <span className="text-danger"><i class="fa-solid fa-xmark"></i>&nbsp;One uppercase character</span>
                                                    }                 
                                                </div>
                                                <div class="form-text mt-1 ms-1" id="passwordField">
                                                    {
                                                        passwordHasOneDigit() ?
                                                        <span className="text-success"><i class="fa-solid fa-check"></i>&nbsp;One number</span>
                                                        :
                                                        <span className="text-danger"><i class="fa-solid fa-xmark"></i>&nbsp;One number</span>
                                                    }                 
                                                </div>
                                                <div class="form-text mt-1 ms-1" id="passwordField">
                                                    {
                                                        passwordHasOneSpecial() ?
                                                        <span className="text-success"><i class="fa-solid fa-check"></i>&nbsp;One special character from !@#$%^&*_</span>
                                                        :
                                                        <span className="text-danger"><i class="fa-solid fa-xmark"></i>&nbsp;One special character from !@#$%^&*_</span>
                                                    }                 
                                                </div>
                                                <div class="form-text mt-1 ms-1" id="passwordField">
                                                    {
                                                        passwordHas8Char() ?
                                                        <span className="text-success"><i class="fa-solid fa-check"></i>&nbsp;8 characters minimum</span>
                                                        :
                                                        <span className="text-danger"><i class="fa-solid fa-xmark"></i>&nbsp;8 characters minimum</span>
                                                    }                 
                                                </div>

                                            </div>
                                            {/* <div class="col-12 col-md-6">
                                                <input type="password" 
                                                    placeHolder="Confirm password" 
                                                    value= { password_confirm }
                                                    onChange={ (e) => this.formChanged(e, 'password_confirm') }
                                                    disabled={ !isValidPassword(password)}
                                                    class="form-control"
                                                    id="confirmPasswordField"
                                                />
                                                { !fieldsMatch(password, password_confirm) && isValidPassword(password) &&
                                                    <div class="form-text text-danger" id="confirmPasswordField">Passwords do not match</div>
                                                }
                                            </div> */}
                                        </div>
                                        
                                        <div class="row mt-5">
                                            <div class="col text-center">
                                                {submitting ?
                                                    <Button variant="primary">
                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;
                                                        Submitting...
                                                    </Button>
                                                    :
                                                    <Button variant="primary" onClick={() => this.handleSubmitRequest()} 
                                                    disabled={ !this.canSubmit() }>Sign up</Button>
                                                }
                                            </div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col text-center">
                                                <small> By signing up you agree to our&nbsp;
                                                    <a class="text-decoration-underline" href="/privacy_policy" role="button" target="_blank">Privacy Policy</a>
                                                    &nbsp;and&nbsp; 
                                                    <a class="text-decoration-underline" href="/tos" role="button" target="_blank">Terms of Service</a>
                                                </small>
                                            </div>
                                            
                                        </div>
                                            
                                    </div>
                                </Card.Body>
                            </Card>
                           
                    </div>  
                </div>
                
            </div>
        );
    }
}

SignUp.contextType = AuthContext;
export default SignUp;