import React from 'react';
// import intro_video from 'assets/intro_video.mp4';
import {trackEvent, EventNames} from 'utils/mixpanel';

class SignUpVideo extends React.Component {
    
    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'sign_up_video'})
    }

    render() {
       
        return (
            <div class="container text-center">
                <div class="row"><div class="col display-6">Renolition</div></div>

                <div class="row mt-5">
                    <div class="col fw-bold">
                        Organize, share, and find vendors
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col">
                        <video width="80%" controls>
                            {/* <source src={intro_video} type="video/mp4" /> */}
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>

            </div>
           );
      
    }
}

export default SignUpVideo;