/* global google */
// do not delete above line. Needed for google address to function

import React, { Component, useState, useEffect } from "react";
import { SF_PLANNING_SEARCH, SF_PROPERTY_INFO } from 'utils/constants';
import { SfPlanningRecordType, SfPlanningFileType, SfEnvironmentFileType, SfHeightDistrict, SfSpecialUseDistrict, SfUseDistrict, DistanceMile, SfRecordStatus} from 'utils/sf_planning_constants';
import DatePicker from "react-datepicker"; // https://reactdatepicker.com/
import { trackEvent, EventNames } from 'utils/mixpanel';
import { Card, Button, Form, Offcanvas, Placeholder, Modal, OverlayTrigger, Popover} from 'react-bootstrap';
import { generic_get_api, generic_post_api } from "api/generic_api";
import GoogleMapReact from 'google-map-react';
import { truncateText } from "utils/helpers";
import { basicAccountInfo } from "api/basic_account_info";
import tool_tip_icon from 'assets/reputation/tooltip_green_outline.svg';
import Joyride from 'react-joyride';

const SearchType = {
    NEW_SEARCH: 'new_search',
    FILTER: 'filter',
    NEXT_PAGE: 'next_page',
}

const QueryMethods = {
    KEYWORDS: 'Keywords',
    SEMANTIC: 'Semantic',
    BOOLEAN: 'Boolean'
}

const search_steps = [
    {
        target: '.searchBar',
        content: 'Start with a few strong keywords that describe the type of projects and permits you are looking for.',
        disableBeacon: true,
    },
    {
        target: '.queryMethods',
        content: 'You can choose between a few different search types: Keywords, Semantic or Boolean.',
        disableBeacon: true,
    },
    {
        target: '.queryMethodDescriptions',
        content: 'The help links explain the different search types in detail. We recommend you start with Keywords.',
        disableBeacon: true,
    },
    {
        target: '.searchButton',
        content: 'Click on the search button to start the search.',
        disableBeacon: true,
    },
    {
        target: '.searchGuideTitle',
        content: 'Sample searches are available on the home page and when starting a new search to help you get going. And we are always a click away. Contact us for help!',
        disableBeacon: true,
    },
]

const result_row_steps = [
    {
        target: '.propertiesMap',
        content: 'You get an easy overview of the locations of the properties in the search results.',
        disableBeacon: true,
        data: 'propertiesMap',
    },
    {
        target: '.resultRow',
        content: 'A quick overview of each permit application in the search results.',
        disableBeacon: true,
    },    
    {
        target: '.idLink',
        content: 'Click on the application number to view the details of this permit application.',
        disableBeacon: true,
    },
    {
        target: '.applicationSummary',
        content: 'A high level summary of the application.',
        disableBeacon: true,
    },
    // {
    //     target: '.subApplications',
    //     content: 'A list of additional sub-applications that were filed with this application.',
    //     disableBeacon: true,
    // },
    {
        target: '.availableFiles',
        content: 'A list of files and documents that are available for this application. You can open/download each document in the details view of the application.',
        disableBeacon: true,
    },
    {
        target: '.allFiltersButton',
        content: 'Refine the results with multiple, powerful filters.',
        disableBeacon: true,
        data: 'allFiltersButton',
    },
    {
        target: '.propertyAddressSection',
        content: 'If you specify the address of your project property, you can also set a distance filter.',
        disableBeacon: true,
        data: 'propertyAddressSection',
    },
]

function PopupElement(props) {
    const {key, title, body, element} = props;

    return <OverlayTrigger key={key} placement="auto"
        overlay={ 
            <Popover id={key}>
                { title && 
                    <Popover.Header>{title}</Popover.Header>
                }
                <Popover.Body>
                    {body}
                </Popover.Body>
            </Popover>
        }>
            {element}
    </OverlayTrigger>
}


class SfPlanningSearch extends Component {

    constructor(props) {
        super(props);

        // when adding a new variable to the state, add it also in 'changeQueryMethod'
        this.state = {
            last_executed_search: null,
            pages: [],
            current_page: 0,
            total: 0,
            actual_total: 0,
            query_method: QueryMethods.KEYWORDS,
            search_string: "",
            start_index: 0,
            page_size: 10,
            session_id: null,
            executing_search: false,
            show_disclaimer_modal: false,
            show_search_tutorial: false,
            run_search_tutorial: false,
            result_row_tutorial: false,
            show_boolean_guide_modal: false,

            // Filter related
            start_date: null,
            end_date: null,
            proj_type_filter_map: {},
            file_type_filter_map: {},
            use_district_filter_map: {},
            height_district_filter_map: {},
            special_use_district_filter_map: {},
            has_opposition_filter_set: false,
            has_appeal_filter_set: false,
            record_status_filter_map: {},
            distance_filter: "0",
            current_date_dropdown_selection: "All",
            show_custom_date_controls: false,

            property_address: null,
            show_filter_canvas: false,
            filter_toggle_state: {},
            effective_filter_string_list: [],
            query_error_string: null,

        };

        this.initiateNewSearch = this.initiateNewSearch.bind(this);
        this.setProjTypeFilter = this.setProjTypeFilter.bind(this);
        this.setFileTypeFilter = this.setFileTypeFilter.bind(this);

        this.setUseDistrictFilter = this.setUseDistrictFilter.bind(this);
        this.setHeightDistrictFilter = this.setHeightDistrictFilter.bind(this);
        this.setSpecialUseDistrictFilter = this.setSpecialUseDistrictFilter.bind(this);

        this.initiateFilterSearch = this.initiateFilterSearch.bind(this);
        this.finalizeFilterSearch = this.finalizeFilterSearch.bind(this);

        this.setPropertyAddress = this.setPropertyAddress.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
        this.initiateNextPage = this.initiateNextPage.bind(this);
        this.finalizeNextPage = this.finalizeNextPage.bind(this);
        this.executeSearchQuery = this.executeSearchQuery.bind(this);
        this.finalizeNewSearch = this.finalizeNewSearch.bind(this);
        this.customDatePickerChanged = this.customDatePickerChanged.bind(this);
        this.setRecordStatusFilter = this.setRecordStatusFilter.bind(this);
        this.setFilterToggleState = this.setFilterToggleState.bind(this);
        this.is_any_filter_set = this.is_any_filter_set.bind(this);
        this.setEffectiveFilterStringList = this.setEffectiveFilterStringList.bind(this);
        this.getPropertyInfo = this.getPropertyInfo.bind(this);
        this.dismissDisclaimerModal = this.dismissDisclaimerModal.bind(this);
        this.dismissBooleanGuideModal = this.dismissBooleanGuideModal.bind(this);
        this.validateSearchQuery = this.validateSearchQuery.bind(this);
        this.calculateActualTotal = this.calculateActualTotal.bind(this);
        this.changeQueryMethod = this.changeQueryMethod.bind(this);
        this.processFirstRunExperienceStep = this.processFirstRunExperienceStep.bind(this);
        this.setResultRowTutorial = this.setResultRowTutorial.bind(this);
        this.initiateSampleKeywordSearch = this.initiateSampleKeywordSearch.bind(this);
        this.initiateSampleSemanticSearch = this.initiateSampleSemanticSearch.bind(this);
        this.initiateSampleBooleanSearch = this.initiateSampleBooleanSearch.bind(this);
        this.onSearchBoxKeyDown = this.onSearchBoxKeyDown.bind(this);
    }

    changeQueryMethod(method) {
        this.setState({
            last_executed_search: null,
            pages: [],
            current_page: 0,
            total: 0,
            actual_total: 0,
            query_method: method,
            search_string: "",
            start_index: 0,
            page_size: 10,
            session_id: null,
            executing_search: false,
            show_disclaimer_modal: false,
            show_search_tutorial: false,
            run_search_tutorial: false,
            show_boolean_guide_modal: false,

            // Filter related
            start_date: null,
            end_date: null,
            proj_type_filter_map: {},
            file_type_filter_map: {},
            use_district_filter_map: {},
            height_district_filter_map: {},
            special_use_district_filter_map: {},
            has_opposition_filter_set: false,
            has_appeal_filter_set: false,
            record_status_filter_map: {},
            distance_filter: "0",
            current_date_dropdown_selection: "All",
            show_custom_date_controls: false,

            // property_address: null,
            show_filter_canvas: false,
            filter_toggle_state: {},
            effective_filter_string_list: [],
            query_error_string: null,

        });
    }

    calculateActualTotal() {
        let total = 0;

        if (this.state.current_page > 0) {
            total += this.state.current_page * this.state.page_size;
        }

        if (this.state.pages[this.state.current_page]) {
            total += this.state.pages[this.state.current_page].length;
        }

        total += (this.state.total - this.state.start_index);

        this.setState({actual_total: total});
    }

    setFilterToggleState(filter_type) {

        let cur_display_state = this.state.filter_toggle_state;
        let prev_filter_state = cur_display_state[filter_type];

        // first close all other filters
        for (const [key, value] of Object.entries(cur_display_state)) {
            cur_display_state[key] = false;
        }

        cur_display_state[filter_type] = !prev_filter_state;
        this.setState({filter_toggle_state: cur_display_state});
    }

    onSearchBoxKeyDown(e) {
        // User pressed the enter key
        if (e.keyCode === 13) {
            this.initiateNewSearch();
        }
    }

    setPropertyAddress(address) {
        trackEvent(EventNames.EVENT, {data_1: 'setPropertyAddress', data_2: address});

        this.setState({
            property_address: address,
            distance_filter: "0",
        }, this.getPropertyInfo);
    }

    getPropertyInfo() {

        if (this.state.property_address){

            let query_params = {
                lat: this.state.property_address.lat,
                lng: this.state.property_address.lng,
            }

            generic_get_api(SF_PROPERTY_INFO, query_params)
            .then(
                data => {
                    let cur_address = this.state.property_address;
                    cur_address.use_district = data.use_district;
                    cur_address.height_district = data.height_district;
                    cur_address.special_use_districts = data.special_use_districts;

                    this.setState({property_address: cur_address});
                }
            )
            .catch(error => {
            });
        }
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'planning_code_search'});
        document.title = "Search Planning Permits";

        basicAccountInfo()
        .then(
            data => {
                let show_disclaimer_modal = localStorage.getItem('show_disclaimer_modal');

                if (show_disclaimer_modal && show_disclaimer_modal.toLocaleLowerCase() === 'true'){
                    this.setState({show_disclaimer_modal: true});
                }

                let show_search_tutorial = localStorage.getItem('show_search_tutorial');

                if (show_search_tutorial && show_search_tutorial.toLocaleLowerCase() === 'true'){
                    this.setState({show_search_tutorial: true});
                }

            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            }
        });
    }

    initiateNewSearch() {

        if (!this.validateSearchQuery()) {
            return;
        }

        this.setState(
            {
                session_id: null,
                start_index: 0,
                last_executed_search: SearchType.NEW_SEARCH,
                executing_search: true,
            }, this.executeSearchQuery);

    }

    initiateSampleKeywordSearch(sample) {
        let cur_map = this.state.proj_type_filter_map;
        let file_map = this.state.file_type_filter_map;
        let key_words = '';

        if (sample === 'rear_yard'){
            cur_map['Variance (VAR)'] = true;
            file_map['Variance Decision Letters'] = true;
            key_words = 'Rear yard variance for a deck';
        }
        else if (sample === 'formula_retail'){
            cur_map['Conditional Use Authorization (CUA)'] = true;
            file_map['Motions-Executive-Summary'] = true;
            key_words = 'formula retail fitness studio';
        }
        else if (sample === 'rear_yard_zad'){
            cur_map['Zoning Administrator Determination Letter (ZAD)'] = true;
            file_map['Letters of Determination'] = true;
            key_words = 'rear yard setback';
        }
        else if (sample === 'demolish'){
            cur_map['Conditional Use Authorization (CUA)'] = true;
            file_map['Motions-Executive-Summary'] = true;
            key_words = 'demolish a single family building';
        }
        else if (sample === 'cannabis'){
            cur_map['Conditional Use Authorization (CUA)'] = true;
            file_map['Motions-Executive-Summary'] = true;
            key_words = 'cannabis dispensary parcel delivery service';
        }
        
        this.setState(
            {
                session_id: null,
                start_index: 0,
                last_executed_search: SearchType.NEW_SEARCH,
                executing_search: true,
                search_string: key_words,
                proj_type_filter_map: cur_map,
                file_type_filter_map: file_map,
            }, this.executeSearchQuery);
    }


    initiateSampleSemanticSearch(sample) {
        let cur_map = this.state.proj_type_filter_map;
        let file_map = this.state.file_type_filter_map;
        let key_words = '';

        if (sample === 'roof'){
            cur_map['Conditional Use Authorization (CUA)'] = true;
            file_map['Motions-Executive-Summary'] = true;
            key_words = 'install communication equipment on rooftops';
        }
        else if (sample === 'demolish'){
            cur_map['Conditional Use Authorization (CUA)'] = true;
            file_map['Motions-Executive-Summary'] = true;
            key_words = 'demolish a residential building and build a multi unit';
        }
        
        this.setState(
            {
                session_id: null,
                start_index: 0,
                last_executed_search: SearchType.NEW_SEARCH,
                executing_search: true,
                search_string: key_words,
                proj_type_filter_map: cur_map,
                file_type_filter_map: file_map,
            }, this.executeSearchQuery);
    }

    initiateSampleBooleanSearch(sample) {
        let cur_map = this.state.proj_type_filter_map;
        let file_map = this.state.file_type_filter_map;
        let key_words = '';

        if (sample === 'gps'){
            cur_map['Conditional Use Authorization (CUA)'] = true;
            file_map['Motions-Executive-Summary'] = true;
            key_words = '"GPS antenna" AND "wireless facility"';
        }
        else if (sample === 'cannabis'){
            cur_map['Conditional Use Authorization (CUA)'] = true;
            file_map['Motions-Executive-Summary'] = true;
            key_words = 'cannabis AND ("parcel delivery service" OR "light manufacturing")';
        }
        
        this.setState(
            {
                session_id: null,
                start_index: 0,
                last_executed_search: SearchType.NEW_SEARCH,
                executing_search: true,
                search_string: key_words,
                proj_type_filter_map: cur_map,
                file_type_filter_map: file_map,
            }, this.executeSearchQuery);
    }

    finalizeNewSearch(result_entries, start_index, session_id, total) {

        let cur_pages = [];

        if (result_entries && result_entries.length > 0){
            cur_pages.push(result_entries);
        }

        this.setState({
            pages: cur_pages,
            start_index: start_index,
            current_page: cur_pages.length - 1,
            session_id: session_id,
            executing_search: false,
            total: total,
        });
        this.setEffectiveFilterStringList();
        this.calculateActualTotal();
        this.setResultRowTutorial();
    }

    setResultRowTutorial() {

        // this way, only one tutorial is enabled at a time
        let result_row_tutorial = localStorage.getItem('result_row_tutorial');

        if (result_row_tutorial && result_row_tutorial.toLocaleLowerCase() === 'true'){
            this.setState({result_row_tutorial: true});
        }

        localStorage.setItem('show_search_tutorial', 'false');

        this.setState({
            show_search_tutorial: false,
        });
    }

    resetFilters() {
        
        this.setState({
            start_date: null,
            end_date: null,
            current_date_dropdown_selection: "All",
            proj_type_filter_map: {},
            file_type_filter_map: {},
            use_district_filter_map: {},
            height_district_filter_map: {},
            special_use_district_filter_map: {},
            distance_filter: "0",
            has_opposition_filter_set: false,
            has_appeal_filter_set: false,
            record_status_filter_map: {},

            filter_toggle_state: {},
            show_filter_canvas: false,
            session_id: null,
            start_index: 0,
            last_executed_search: SearchType.NEW_SEARCH,
            executing_search: true,
        }, this.initiateNewSearch);
    }

    is_any_filter_set() {

        let date_filter_set = this.state.start_date && this.state.end_date;
        
        let map_filters = false;

        for (const [key, value] of Object.entries(this.state.proj_type_filter_map)) {
            if (value === true){
                map_filters = true;
            }
        }

        for (const [key, value] of Object.entries(this.state.file_type_filter_map)) {
            if (value === true){
                map_filters = true;
            }
        }

        for (const [key, value] of Object.entries(this.state.use_district_filter_map)) {
            if (value === true){
                map_filters = true;
            }
        }

        for (const [key, value] of Object.entries(this.state.height_district_filter_map)) {
            if (value === true){
                map_filters = true;
            }
        }

        for (const [key, value] of Object.entries(this.state.special_use_district_filter_map)) {
            if (value === true){
                map_filters = true;
            }
        }

        for (const [key, value] of Object.entries(this.state.record_status_filter_map)) {
            if (value === true){
                map_filters = true;
            }
        }

        return this.state.has_opposition_filter_set || this.state.distance_filter != "0" 
        || this.state.has_appeal_filter_set || this.state.has_opposition_filter_set || date_filter_set || map_filters;
    }

    setEffectiveFilterStringList() {

        let result = []

        if (this.state.start_date && this.state.end_date) {
            let year_start = this.state.start_date.getFullYear();
            let month_start = this.state.start_date.getMonth() + 1;
            let day_start = this.state.start_date.getDate();

            let year_end = this.state.end_date.getFullYear();
            let month_end = this.state.end_date.getMonth() + 1;
            let day_end = this.state.end_date.getDate();

            let date_string = `${month_start}/${day_start}/${year_start} to ${month_end}/${day_end}/${year_end}`;
            result.push(date_string);
        }

        for (const [key, value] of Object.entries(this.state.proj_type_filter_map)) {
            if (value === true) {
                // push the display name
                result.push(SfPlanningRecordType[key]);
            }
        }

        for (const [key, value] of Object.entries(this.state.file_type_filter_map)) {
            const combined_file_types = { ...SfPlanningFileType, ...SfEnvironmentFileType };
            if (value === true){
                result.push(combined_file_types[key]);
            }
        }

        for (const [key, value] of Object.entries(this.state.use_district_filter_map)) {
            if (value === true){
                result.push(key);
            }
        }

        for (const [key, value] of Object.entries(this.state.height_district_filter_map)) {
            if (value === true){
                result.push(key);
            }
        }

        for (const [key, value] of Object.entries(this.state.special_use_district_filter_map)) {
            if (value === true){
                result.push(key);
            }
        }

        for (const [key, value] of Object.entries(this.state.record_status_filter_map)) {
            if (value === true){
                result.push(key);
            }
        }

        if (this.state.has_opposition_filter_set) {
            result.push("Received opposition");
        }

        if (this.state.distance_filter != "0") {
            result.push("Within " + this.state.distance_filter + " miles");
        }

        if (this.state.has_appeal_filter_set ) {
            result.push("Was appealed");
        }

        this.setState({effective_filter_string_list: result });
    }

    initiateFilterSearch() {
        
        if (!this.validateSearchQuery()) {
            return;
        }

        this.setState(
            {
                show_filter_canvas: false,
                filter_toggle_state: {},
                session_id: null,
                start_index: 0,
                last_executed_search: SearchType.FILTER,
                executing_search: true,
            }, this.executeSearchQuery);
    }

    finalizeFilterSearch(result_entries, start_index, session_id, total) {
        let cur_pages = [];

        if (result_entries && result_entries.length > 0){
            cur_pages.push(result_entries);
        }

        this.setState({
            pages: cur_pages,
            start_index: start_index,
            current_page: cur_pages.length - 1,
            session_id: session_id,
            executing_search: false,
            total: total,
        });
        this.setEffectiveFilterStringList();
        this.calculateActualTotal();
    }

    initiateNextPage() {
        this.setState({
            last_executed_search: SearchType.NEXT_PAGE,
            executing_search: true,
        }, this.executeSearchQuery);
    }

    finalizeNextPage(result_entries, start_index, session_id, total) {
        
        let cur_pages = this.state.pages;

        if (result_entries && result_entries.length > 0){
            cur_pages.push(result_entries);
        }
        
        this.setState({ 
            pages: cur_pages,
            start_index: start_index,
            current_page: cur_pages.length - 1,
            session_id: session_id,
            executing_search: false,
            total: total,
        });
        this.calculateActualTotal();
    }

    executeSearchQuery() {

        let proj_filter = [];
        let file_filter = [];
        let use_district_filter = [];
        let height_district_filter = [];
        let special_use_district_filter = [];
        let record_status_filter = [];

        for (const [key, value] of Object.entries(this.state.record_status_filter_map)) {
            if (value === true){
                record_status_filter.push(key);
            }
        }

        for (const [key, value] of Object.entries(this.state.proj_type_filter_map)) {
            if (value === true){
                proj_filter.push(key);
            }
        }

        for (const [key, value] of Object.entries(this.state.file_type_filter_map)) {
            if (value === true){
                file_filter.push(key);
            }
        }

        for (const [key, value] of Object.entries(this.state.use_district_filter_map)) {
            if (value === true){
                use_district_filter.push(key);
            }
        }

        for (const [key, value] of Object.entries(this.state.special_use_district_filter_map)) {
            if (value === true){
                special_use_district_filter.push(key);
            }
        }

        for (const [key, value] of Object.entries(this.state.height_district_filter_map)) {
            if (value === true){
                height_district_filter.push(key);
            }
        }
        
        let start_date = null;
        let end_date = null;

        if (this.state.start_date) {
            start_date = {
                year: this.state.start_date.getFullYear(),
                // month is 0 based
                month: this.state.start_date.getMonth() + 1,
                day: this.state.start_date.getDate(),
            }
        }

        if (this.state.end_date) {
            end_date = {
                year: this.state.end_date.getFullYear(),
                // month is 0 based
                month: this.state.end_date.getMonth() + 1,
                day: this.state.end_date.getDate(),
            }
        }

        let request = {
            search_string: this.state.search_string,
            query_method: this.state.query_method,
            start_date: start_date,
            end_date: end_date,
            start_index: this.state.start_index,
            page_size: this.state.page_size,
            session_id: this.state.session_id,
            proj_filter: proj_filter,
            file_filter: file_filter,
            use_district_filter: use_district_filter,
            height_district_filter: height_district_filter,
            special_use_district_filter: special_use_district_filter,
            distance_filter: this.state.distance_filter,
            property_address: this.state.property_address,
            has_opposition_position: this.state.has_opposition_filter_set,
            has_appeal_filter: this.state.has_appeal_filter_set,
            record_status_filter: record_status_filter,
        }

        trackEvent(EventNames.SEARCH_START, {'data_1': request });

        window.scrollTo(0, 0);

        generic_post_api(request, SF_PLANNING_SEARCH)
        .then(
            result => {
                return result.data.json()
            }
        ).then(
            data => {

                let result_entries = data['result_entries'];
                let start_index = data['start_index'];
                let session_id = data['session_id'];
                let total = data['total'];

                if (this.state.last_executed_search === SearchType.NEW_SEARCH){
                    this.finalizeNewSearch(result_entries, start_index, session_id, total);
                }
                else if (this.state.last_executed_search === SearchType.FILTER){
                    this.finalizeFilterSearch(result_entries, start_index, session_id, total);
                }
                else if (this.state.last_executed_search === SearchType.NEXT_PAGE){
                    this.finalizeNextPage(result_entries, start_index, session_id, total);
                }
            })
        .catch(error => {

            if (error.name === 'BAD_REQUEST') {
                this.setState({
                    query_error_string: 'Enter a valid Boolean search expression',
                    executing_search: false,
                });    
            }
        });
    }

    setProjTypeFilter(filter, value) {
        let cur_map = this.state.proj_type_filter_map;
        cur_map[filter] = value;

        this.setState({proj_type_filter_map: cur_map});
    }

    setRecordStatusFilter(filter, value) {
        let cur_map = this.state.record_status_filter_map;
        cur_map[filter] = value;

        this.setState({record_status_filter_map: cur_map});
    }

    setFileTypeFilter(filter, value) {
        let cur_map = this.state.file_type_filter_map;
        cur_map[filter] = value;

        this.setState({file_type_filter_map: cur_map});
    }

    setUseDistrictFilter(filter, value) {
        let cur_map = this.state.use_district_filter_map;
        cur_map[filter] = value;

        this.setState({use_district_filter_map: cur_map});
    }

    setSpecialUseDistrictFilter(filter, value) {
        let cur_map = this.state.special_use_district_filter_map;
        cur_map[filter] = value;

        this.setState({special_use_district_filter_map: cur_map});
    }

    setHeightDistrictFilter(filter, value) {
        let cur_map = this.state.height_district_filter_map;
        cur_map[filter] = value;

        this.setState({height_district_filter_map: cur_map});
    }

    customDatePickerChanged(value) {

        let end_date = new Date();
        let start_date = new Date();
        let show_custom_date_controls = false;
        
        if  (value === 'All') {
            end_date = null;
            start_date = null;
        }
        else if  (value === '30') {
            start_date.setDate(start_date.getDate() - 30);
        }
        else if  (value === '180') {
            start_date.setDate(start_date.getDate() - 180);
        }
        else if (value === '365') {
            start_date.setFullYear( start_date.getFullYear() - 1 );
        }
        else if  (value === '730') {
            start_date.setFullYear( start_date.getFullYear() - 2 );
            
        }
        // when user sets custom date, start with 1 year as default on the UI
        else if  (value === 'custom') {
            start_date.setFullYear( start_date.getFullYear() - 1 );
            show_custom_date_controls = true;
        }

        this.setState({ 
            current_date_dropdown_selection: value,
            end_date: end_date, 
            start_date: start_date,
            show_custom_date_controls: show_custom_date_controls,
         });
    }

    dismissDisclaimerModal() {
        localStorage.removeItem('show_disclaimer_modal');
        this.setState({
            show_disclaimer_modal: false,
        });
    }

    processFirstRunExperienceStep(step_object, type) {

        if (step_object.status === 'finished' && type === 'search') {
            
            localStorage.setItem('show_search_tutorial', 'false');

            this.setState({
                show_search_tutorial: false,
                run_search_tutorial: false,
            });
        }

        else if (step_object.status === 'finished' && type === 'result_row') {
            
            localStorage.setItem('result_row_tutorial', 'false');

            this.setState({
                result_row_tutorial: false,
            });
        }
    }

    dismissBooleanGuideModal(){
        this.setState({show_boolean_guide_modal: false});
    }

    validateSearchQuery() {

        this.setState({query_error_string: null});

        let search_string = this.state.search_string;

        if (!search_string || !search_string.trim()){
            this.setState({query_error_string: "Enter valid search keywords"});
            return false;
        }

        let start_date = this.state.start_date;
        let end_date = this.state.end_date;

        if ((start_date && ! end_date) ||(!start_date && end_date)) {
            this.setState({query_error_string: "Enter a valid date range"});
            return false;
        }

        if (start_date && end_date && start_date > end_date) {
            this.setState({query_error_string: "Start date must be before end date"});
            return false;
        }

        return true;
    }

    offCanvasHasOneExpandedFilter = ()  => {
        let cur_display_state = this.state.filter_toggle_state;

        for (const [key, value] of Object.entries(cur_display_state)) {
            let targeted_filter_types = ['record_type', 'file_type', 'use_district', 'height_district', 
                'special_use', 'application_status']
           
            if (value && targeted_filter_types.includes(key)) {
                return true;
            }
        }

        return false;
    }

    render() {
        const {pages, current_page, file_type_filter_map, height_district_filter_map, 
            use_district_filter_map, special_use_district_filter_map, 
            last_executed_search, executing_search, start_index,
            record_status_filter_map, total, actual_total, page_size, show_disclaimer_modal, show_search_tutorial, query_method,
            show_boolean_guide_modal, query_error_string, result_row_tutorial, run_search_tutorial,
        } = this.state;

        const pagination_page_style  = (index) => {
            if (index === this.state.current_page) {
                return 'text-decoration-none pe-none fw-bold text-white';
            }
            else {
                return 'text-decoration-underline pe-auto ';
            }
        }

        const pagination_page_style_span  = (index) => {
            if (index === this.state.current_page) {
                return 'me-3 fs-6 ps-1 pe-1 bg-primary';
            }
            else {
                return 'me-3 fs-6 ps-1 pe-1';
            }
        }

        const pagination_page_role  = (index) => {
            if (index === this.state.current_page) {
                return '';
            }
            else {
                return 'button';
            }
        }
        
        const getSearchBoxPlaceHolder = () => {

            if (this.state.query_method === QueryMethods.KEYWORDS) {
                return 'formula retail fitness studio, demolish a single family building';
            }
            else if (this.state.query_method === QueryMethods.BOOLEAN) {
                return '"density bonus" AND (affordable OR housing)'
            }
            else if (this.state.query_method === QueryMethods.SEMANTIC) {
                return 'installing communication equipment on rooftops'
            }
        }

        const getSmallText = (text) => { return <small>{text}</small>}

        const getStartIndex = () => {
            return current_page * page_size + 1;
        }

        return(
            <div class="container sfPlanningSearch">
                 <Joyride 
                    run={run_search_tutorial}
                    steps={search_steps} 
                    showSkipButton={false}
                    showStepsProgress={true}
                    continuous={true}
                    disableScrolling={true}
                    hideBackButton={true}
                    hideCloseButton={true}
                    callback={(e) => this.processFirstRunExperienceStep(e, 'search')}
                    styles={{
                        options: {
                            arrowColor: '#efecec',
                            primaryColor: '#0D6EFD',
                            textColor: '#004a14',
                            backgroundColor: '#efecec',
                            arrowColor: '#efecec',
                        },
                      }}
                 />
                <Joyride 
                    run={result_row_tutorial}
                    steps={result_row_steps} 
                    showSkipButton={false}
                    showStepsProgress={true}
                    continuous={true}
                    disableScrolling={true}
                    hideBackButton={true}
                    hideCloseButton={true}
                    callback={(e) => this.processFirstRunExperienceStep(e, 'result_row')}
                    styles={{
                        options: {
                            arrowColor: '#efecec',
                            primaryColor: '#0D6EFD',
                            textColor: '#004a14',
                            backgroundColor: '#efecec',
                            arrowColor: '#efecec',
                        },
                      }}
                 />
                <DisclaimerModal
                    show_disclaimer_modal={show_disclaimer_modal}
                    dismissDisclaimerModal={this.dismissDisclaimerModal}
                />
                <div class="row">
                    <div class="col-3">
                        <select class="form-select" aria-label="Default select example" onChange={(e) => this.props.history.push(e.target.value)}>
                            <option disabled>Jurisdiction</option>
                            <option value="/code/sf_search" selected>San Francisco</option>
                            <option value="/code/oak_search"  disabled>Oakland (coming soon)</option>
                            <option value="jbk" disabled>Berkeley (coming soon)</option>
                            <option value="lax" disabled>Los Angeles (coming soon)</option>
                            <option value="ngz" disabled>Alameda (coming soon)</option>
                        </select>
                    </div>
                    <div class="col-9">
                        <div class="input-group">
                            <select class="form-select queryMethods" aria-label="Default select example" onChange={(e) => this.changeQueryMethod(e.target.value)}>
                                <option disabled>Search type</option>
                                <option value={QueryMethods.KEYWORDS} selected>Keywords</option>
                                <option value={QueryMethods.SEMANTIC}>Semantic</option>
                                <option value={QueryMethods.BOOLEAN}>Boolean</option>
                                {/* <option value="address">Address</option>
                                <option value="record">Record #</option> */}
                            </select>

                            <input type="search" 
                                placeHolder={getSearchBoxPlaceHolder()}
                                class="form-control searchBar"
                                name="search" 
                                id="search"
                                disabled={executing_search}
                                value={this.state.search_string}
                                onChange={ (e) => this.setState({search_string: e.target.value}) }
                                onKeyDown={ (e) => this.onSearchBoxKeyDown(e) }
                            />

                           
                             {
                                executing_search ? 
                                    <Button className="bg-transparent text-dark clearButton" disabled>
                                        <span role="status">Searching&nbsp;</span>
                                        <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                    </Button>
                                :
                                <Button variant="warning" onClick={ this.initiateNewSearch } className="searchButton">
                                    Search&nbsp;<i class="fa-solid fa-magnifying-glass fa-rotate-90"></i>
                                </Button>        
                            }
                            
                        </div>
                        
                    </div>
                </div>
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-9">
                        <div class="row">
                            <div class="col-auto">
                                { (query_method === QueryMethods.KEYWORDS || query_method === QueryMethods.SEMANTIC) && 
                                    <PopupElement
                                        key="keywordSearchTooltip"
                                        body={
                                            getSearchTypeToolTipText(query_method)
                                        }
                                        element={<span className="tooltipAnchor ms-2 queryMethodDescriptions">About {query_method}</span>}
                                    />
                                }
                                {
                                    query_method === QueryMethods.BOOLEAN && 
                                        <span className="tooltipAnchor ms-2" role="button"
                                            onClick={() => {this.setState({ show_boolean_guide_modal: true})}}>
                                                About {query_method}
                                        </span>
                                }
                                <BooleanGuideModal 
                                    show_boolean_guide_modal={show_boolean_guide_modal}
                                    dismissBooleanGuideModal={this.dismissBooleanGuideModal}
                                />
                            </div>
                            <div class="col">
                                { query_error_string &&
                                    <span className="text-danger">{query_error_string}</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <Offcanvas show={this.state.show_filter_canvas} onHide={() => this.setState({show_filter_canvas: false})}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Filters</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="filterBar">
                        <div class="container mb-4">
                            <div class="row">
                                <div class="col"><small>Add filters to narrow down results and click on <i>Apply</i></small></div>
                            </div>
                        </div>
                        {
                            this.offCanvasHasOneExpandedFilter() && 
                                <div class="row mt-3 mb-5">
                                    <div class="col-6 text-center">
                                        <Button variant="outline-secondary" onClick={this.resetFilters}>Reset</Button>
                                    </div>
                                    <div class="col-6 text-center filterApplyButton">
                                        <Button variant="primary" onClick={() => {this.initiateFilterSearch()}}>Apply</Button>
                                    </div>
                                    
                                </div>
                        }

                        <div class="container filterContainer">
                            <div class="row">
                                <div class="col-auto fs-6">
                                    Date range&nbsp;
                                    <PopupElement
                                        key="dateRangeTooltip"
                                        body="Filter permit applications by their submission date to the city."
                                        element={<img src={tool_tip_icon} className="tooltip_16"/>}
                                    />
                
                                </div>
                                <div class="col fs-6 text-end">
                                    <a onClick={()=> this.setFilterToggleState('date_range')} role="button">
                                        {
                                            this.state.filter_toggle_state['date_range'] ?
                                            <i class="fa-solid fa-minus fa-xl"></i> :
                                            <i class="fa-solid fa-plus fa-xl"></i>
                                        }
                                    </a>
                                </div>
                            </div>
                            {   this.state.filter_toggle_state['date_range'] &&
                                <div class="row mt-3">
                                    <div class="col-12">
                                        <Form.Select className="filterDropdownButton" onChange={(e) => this.customDatePickerChanged(e.target.value)} size='sm'>
                                            <option value="All" selected={this.state.current_date_dropdown_selection === 'All'}>Date range (All)</option>
                                            <option value="30" selected={this.state.current_date_dropdown_selection === '30'}>Last 30 days</option>
                                            <option value="180" selected={this.state.current_date_dropdown_selection === '180'}>Last 180 days</option>
                                            <option value="365" selected={this.state.current_date_dropdown_selection === '365'}>Last 12 months</option>
                                            <option value="730" selected={this.state.current_date_dropdown_selection === '730'}>Last 2 years</option>
                                            <option value="custom" selected={this.state.current_date_dropdown_selection === 'custom'}>Custom</option>
                                        </Form.Select>
                                    </div>
                                    { this.state.show_custom_date_controls &&
                                        <>
                                            <div class="col-6">
                                                <div class="row"><div class="col>"><small>From</small></div></div>
                                                <div class="row">
                                                    <div class="datePickerInput">
                                                        <DatePicker 
                                                            selected={this.state.start_date} 
                                                            maxDate={new Date()}
                                                            showDisabledMonthNavigation
                                                            onChange={(date) => this.setState({ start_date: date})} 
                                                            className="datePicker"
                                                            placeholderText="From"
                                                            popperPlacement="bottom-end"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="row"><div class="col>"><small>To</small></div></div>
                                                <div class="row">
                                                    <DatePicker 
                                                        selected={this.state.end_date} 
                                                        maxDate={new Date()}
                                                        showDisabledMonthNavigation
                                                        onChange={(date) => this.setState({ end_date: date })} 
                                                        className="datePicker"
                                                        placeholderText="To"
                                                        popperPlacement="bottom-end"
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            }
                            <div class="row"><div class="col"><hr></hr></div></div>
                        </div>
                        <div class="container recordType">
                            <div class="row">
                                <div class="col-auto fs-6">
                                    Application type&nbsp;
                                    <PopupElement
                                        key="applicationTypeTooltip"
                                        body="Filter permit applications by type. Large projects may include multiple sub-application 
                                        types—for example, an addition project might require a variance and an environmental 
                                        application. This filter ensures projects with any of the selected application types are included in 
                                        the search."
                                        element={<img src={tool_tip_icon} className="tooltip_16"/>}
                                    />
                                </div>
                                <div class="col fs-6 text-end">
                                    <a onClick={()=> this.setFilterToggleState('record_type')} role="button">
                                        {
                                            this.state.filter_toggle_state['record_type'] ?
                                            <i class="fa-solid fa-minus fa-xl"></i> :
                                            <i class="fa-solid fa-plus fa-xl"></i>
                                        }
                                    </a>
                                </div>
                            </div>
                            {   this.state.filter_toggle_state['record_type'] &&
                                <div class="row filterItemsHeightLimit overflow-auto mt-3">
                                    { Object.entries(SfPlanningRecordType).map( (record_type, index) => {
                                        return  <div class="col-12">
                                                    <Form.Check
                                                        className="ms-2"
                                                        name={record_type[1]}
                                                        type="checkbox"
                                                        label={getSmallText(record_type[1])}
                                                        checked={this.state.proj_type_filter_map[record_type[0]]}
                                                        defaultChecked={this.state.proj_type_filter_map[record_type[0]]}
                                                        onChange={(e) => this.setProjTypeFilter(record_type[0], e.currentTarget.checked)} />
                                                </div>  
                                    })
                                    }
                                </div>
                            }
                            <div class="row"><div class="col"><hr></hr></div></div>
                        </div>
                        <div class="container">
                            <div class="row ">
                                <div class="col-auto fs-6">Available documents&nbsp;
                                    <PopupElement
                                        key="documentTypeTooltip"
                                        body="Filter permit applications based on the availability of specific document types."
                                        element={<img src={tool_tip_icon} className="tooltip_16"/>}
                                    />
                                </div>
                                <div class="col fs-6 text-end">
                                    <a onClick={()=> this.setFilterToggleState('file_type')} role="button">
                                        {
                                            this.state.filter_toggle_state['file_type'] ?
                                            <i class="fa-solid fa-minus fa-xl"></i> :
                                            <i class="fa-solid fa-plus fa-xl"></i>
                                        }
                                    </a>
                                </div>
                            </div>
                            {   this.state.filter_toggle_state['file_type'] &&
                            <>
                                <div class="row filterItemsHeightLimit overflow-auto mt-3">
                                    { Object.entries(SfPlanningFileType).map( (file_type, index) => {
                                        return  <div><Form.Check
                                                    className="ms-2"
                                                    name={file_type[1]}
                                                    type="checkbox"
                                                    label={getSmallText(file_type[1])}
                                                    checked={file_type_filter_map[file_type[0]]}
                                                    defaultChecked={file_type_filter_map[file_type[0]]}
                                                    onChange={(e) => this.setFileTypeFilter(file_type[0], e.currentTarget.checked)} />
                                                </div>
                                    })}
                                
                                <div class="row mt-3 mb-1">{getSmallText("CEQA/Environmental")}</div>
                                
                                    { Object.entries(SfEnvironmentFileType).map( (file_type, index) => {
                                        return  <div><Form.Check
                                                    className="ms-2"
                                                    name={file_type[1]}
                                                    type="checkbox"
                                                    label={getSmallText(file_type[1])}
                                                    checked={file_type_filter_map[file_type[0]]}
                                                    defaultChecked={file_type_filter_map[file_type[0]]}
                                                    onChange={(e) => this.setFileTypeFilter(file_type[0], e.currentTarget.checked)} />
                                                </div>
                                    })}
                                </div>
                            </>
                                
                            }
                            <div class="row"><div class="col"><hr></hr></div></div>
                        </div>
                        <div class="container">
                            <div class="row">
                                <div class="col-auto fs-6">Use district (Zone)&nbsp;
                                    <PopupElement
                                        key="districtTooltip"
                                        body="Filter permit applications by the property's use district (zone)."
                                        element={<img src={tool_tip_icon} className="tooltip_16"/>}
                                    />
                                </div>
                                <div class="col fs-6 text-end">
                                    <a onClick={()=> this.setFilterToggleState('use_district')} role="button">
                                        {
                                            this.state.filter_toggle_state['use_district'] ?
                                            <i class="fa-solid fa-minus fa-xl"></i> :
                                            <i class="fa-solid fa-plus fa-xl"></i>
                                        }
                                    </a>
                                </div>
                            </div>
                            {   this.state.filter_toggle_state['use_district'] &&
                                <div class="row filterItemsHeightLimit overflow-auto mt-3">
                                    { Object.entries(SfUseDistrict).map( (use_district, index) => {
                                        return  <div><Form.Check
                                                    className="ms-2"
                                                    name={use_district[0]}
                                                    type="checkbox"
                                                    label={getSmallText(use_district[0])}
                                                    checked={use_district_filter_map[use_district[0]]}
                                                    defaultChecked={use_district_filter_map[use_district[0]]}
                                                    onChange={(e) => this.setUseDistrictFilter(use_district[0], e.currentTarget.checked)} />
                                                </div>
                                    })}
                                </div>
                            }
                            <div class="row"><div class="col"><hr></hr></div></div>
                        </div>
                        <div class="container">
                            <div class="row">
                                <div class="col-auto fs-6">Height district&nbsp;
                                    <PopupElement
                                        key="heightDistrictTooltip"
                                        body="Filter permit applications by the property's height district."
                                        element={<img src={tool_tip_icon} className="tooltip_16"/>}
                                    />
                                </div>
                                <div class="col fs-6 text-end">
                                    <a onClick={()=> this.setFilterToggleState('height_district')} role="button">
                                        {
                                            this.state.filter_toggle_state['height_district'] ?
                                            <i class="fa-solid fa-minus fa-xl"></i> :
                                            <i class="fa-solid fa-plus fa-xl"></i>
                                        }
                                    </a>
                                </div>
                            </div>
                            {   this.state.filter_toggle_state['height_district'] &&
                                <div class="row filterItemsHeightLimit overflow-auto mt-3">
                                    {   Object.entries(SfHeightDistrict).map( (height_district, index) => {
                                            return  <div><Form.Check
                                                        className="ms-2"
                                                        name={height_district[0]}
                                                        type="checkbox"
                                                        label={getSmallText(height_district[0])}
                                                        checked={height_district_filter_map[height_district[0]]}
                                                        defaultChecked={height_district_filter_map[height_district[0]]}
                                                        onChange={(e) => this.setHeightDistrictFilter(height_district[0], e.currentTarget.checked)} />
                                                    </div>
                                    })}
                                </div>
                            }
                            <div class="row"><div class="col"><hr></hr></div></div>
                        </div>
                        <div class="container">
                            <div class="row">
                                <div class="col-auto fs-6">Special use district&nbsp;
                                    <PopupElement
                                        key="specialUseTooltip"
                                        body="Filter permit applications by the property's Special Use districts. Projects with at 
                                        least one selected Special Use district will be included in the search."
                                        element={<img src={tool_tip_icon} className="tooltip_16"/>}
                                    />
                                </div>
                                <div class="col fs-6 text-end">
                                    <a onClick={()=> this.setFilterToggleState('special_use')} role="button">
                                        {
                                            this.state.filter_toggle_state['special_use'] ?
                                            <i class="fa-solid fa-minus fa-xl"></i> :
                                            <i class="fa-solid fa-plus fa-xl"></i>
                                        }
                                    </a>
                                </div>
                            </div>
                            {   this.state.filter_toggle_state['special_use'] &&
                                <div class="row filterItemsHeightLimit overflow-auto mt-3">
                                    { Object.entries(SfSpecialUseDistrict).map( (special_use_district, index) => {
                                        return  <div><Form.Check
                                                    className="ms-2"
                                                    name={special_use_district[0]}
                                                    type="checkbox"
                                                    label={getSmallText(special_use_district[0])}
                                                    checked={special_use_district_filter_map[special_use_district[0]]}
                                                    defaultChecked={special_use_district_filter_map[special_use_district[0]]}
                                                    onChange={(e) => this.setSpecialUseDistrictFilter(special_use_district[0], e.currentTarget.checked)} />
                                                </div>
                                    })}
                                </div>
                            }
                            <div class="row"><div class="col"><hr></hr></div></div>
                        </div>
                        <div class="container">
                            <div class="row">
                                <div class="col-auto fs-6">Current app. status&nbsp;
                                    <PopupElement
                                        key="appStatusTooltip"
                                        body="Filter permit applications by their status. Note that some project statuses may 
                                        be outdated if not updated by the city."
                                        element={<img src={tool_tip_icon} className="tooltip_16"/>}
                                    />

                                </div>
                                <div class="col fs-6 text-end">
                                    <a onClick={()=> this.setFilterToggleState('application_status')} role="button">
                                        {
                                            this.state.filter_toggle_state['application_status'] ?
                                            <i class="fa-solid fa-minus fa-xl"></i> :
                                            <i class="fa-solid fa-plus fa-xl"></i>
                                        }
                                    </a>
                                </div>
                            </div>
                            {   this.state.filter_toggle_state['application_status'] &&
                                <div class="row mt-3">
                                    { Object.entries(SfRecordStatus).map( (record_status, index) => {
                                        return  <div><Form.Check
                                                    className="ms-2"
                                                    name={record_status[0]}
                                                    type="checkbox"
                                                    label={getSmallText(record_status[0])}
                                                    checked={record_status_filter_map[record_status[1]]}
                                                    defaultChecked={record_status_filter_map[record_status[1]]}
                                                    onChange={(e) => this.setRecordStatusFilter(record_status[1], e.currentTarget.checked)} />
                                                </div>
                                    })}
                                </div>
                            }
                            <div class="row"><div class="col"><hr></hr></div></div>
                        </div>
                        <div class="container">
                            <div class="row">
                                <div class="col-auto fs-6">Appeals &amp; opposition&nbsp;
                                    <PopupElement
                                        key="appealsOppositionTooltip"
                                        body="Search for permit applications that were appealed or faced opposition."
                                        element={<img src={tool_tip_icon} className="tooltip_16"/>}
                                    />
                                </div>
                                <div class="col fs-6 text-end">
                                    <a onClick={()=> this.setFilterToggleState('insights')} role="button">
                                        {
                                            this.state.filter_toggle_state['insights'] ?
                                            <i class="fa-solid fa-minus fa-xl"></i> :
                                            <i class="fa-solid fa-plus fa-xl"></i>
                                        }
                                    </a>
                                </div>
                            </div>
                            {   this.state.filter_toggle_state['insights'] &&
                                <div class="row mt-3">
                                    <div class="col-12 mt-1">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" checked={this.state.has_appeal_filter_set} id="hasAppeal" onChange={(e) => this.setState({has_appeal_filter_set: e.currentTarget.checked})}/>
                                            <label class="form-check-label" for="hasAppeal">
                                                Was appealed
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" checked={this.state.has_opposition_filter_set} id="oppositionCheck" onChange={(e) => this.setState({has_opposition_filter_set: e.currentTarget.checked})}/>
                                            <label class="form-check-label" for="oppositionCheck">
                                                Received opposition
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div class="row"><div class="col"><hr></hr></div></div>
                        </div>
                        {
                            this.state.property_address && 
                            <div class="container">
                                <div class="row">
                                    <div class="col-auto fs-6">Distance&nbsp;
                                        <PopupElement
                                            key="distanceTooltip"
                                            body="Search for permit applications within a specified distance from your project's address."
                                            element={<img src={tool_tip_icon} className="tooltip_16"/>}
                                        />
                                    </div>
                                    <div class="col fs-6 text-end">
                                        <a onClick={()=> this.setFilterToggleState('distance')} role="button">
                                            {
                                                this.state.filter_toggle_state['distance'] ?
                                                <i class="fa-solid fa-minus fa-xl"></i> :
                                                <i class="fa-solid fa-plus fa-xl"></i>
                                            }
                                        </a>
                                    </div>
                                </div>
                                {   this.state.filter_toggle_state['distance'] &&
                                    <div class="row mt-3">
                                        <div class="row mt-3">
                                            <div class="col">
                                                <Form.Select className="filterDropdownButton" onChange={(e)=>this.setState({distance_filter: e.target.value})} size='sm'>
                                                    { DistanceMile.map( (distance, index) => {
                                                        return  <option value={distance.value} selected={this.state.distance_filter === distance.value}>{distance.display}</option> 
                                                    })}
                                                </Form.Select>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div class="row"><div class="col"><hr></hr></div></div>
                            </div>

                        }
                       
                        <div class="row mt-3">
                            <div class="col-6 text-center">
                                <Button variant="outline-secondary" onClick={this.resetFilters}>Reset</Button>
                            </div>
                            <div class="col-6 text-center filterApplyButton">
                                <Button variant="primary" onClick={() => {this.initiateFilterSearch()}}>Apply</Button>
                            </div>
                            
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
                <div class="row mt-4">
                    <div class="col-3">
                        {
                            ((pages && pages.length > 0) || (this.is_any_filter_set())) && 
                            <>  
                                 <div class="row mt-4 allFiltersButton">
                                    <div class="col fw-bold text-center">
                                        <Button className="w-100" onClick={()=>this.setState({show_filter_canvas: true})} variant="outline-primary"><i class="fa-solid fa-filter"></i>&nbsp;All Filters</Button>
                                    </div>
                                </div>
                                <PropertyAddressSection 
                                    property_address={this.state.property_address}
                                    setPropertyAddress={this.setPropertyAddress}
                                />
                                <div class="row mt-3">
                                    <MapComponent 
                                        property_address={this.state.property_address}
                                        current_page={pages[current_page]}
                                        radius={this.state.distance_filter}
                                        pages={pages}
                                    />
                                </div>
                                
                            </>
                        }
                    </div>
                    <div class="col-9">

                        { show_search_tutorial && 
                            <div class="container mt-4 border rounded p-3">
                                <div class="col text-start fs-5 fw-semibold">Get a quick tour of Renolition</div>
                                <div class="col text-center mt-3">
                                    <Button className="me-3" onClick={() => this.setState({run_search_tutorial: true})}>Start tutorial</Button>
                                </div>
                            </div>
                        }

                        {
                            !last_executed_search && 
                            <GuideComponent 
                                query_method={query_method}
                                initiateSampleKeywordSearch={this.initiateSampleKeywordSearch}
                                initiateSampleSemanticSearch={this.initiateSampleSemanticSearch}
                                initiateSampleBooleanSearch={this.initiateSampleBooleanSearch}
                            />
                        }
                        {
                            (
                                last_executed_search === SearchType.NEW_SEARCH || last_executed_search === SearchType.FILTER) && 
                                pages.length == 0 && !executing_search && !query_error_string &&
                            <div class="row"><div class="col fw-semibold">Your search returned no results. Adjust the keywords or filters.</div></div>
                        }
                        {
                            this.state.effective_filter_string_list && this.state.effective_filter_string_list.length > 0 &&
                            <div class="row mt-4">
                                <div class="col">
                                    <i class="fa-solid fa-filter"></i>&nbsp;Filters&nbsp;
                                    {
                                       this.state.effective_filter_string_list.map(filter_string => {
                                            return <span className="me-1 border p-1 rounded">{filter_string}</span>
                                       })
                                    }
                                    <span className="me-1 border p-1 rounded fw-semibold"><a role="button" onClick={this.resetFilters} className="text-decoration-none">Reset all&nbsp;<i class="fa-solid fa-xmark"></i></a></span>
                                </div>
                            </div>
                        }
                        {
                            pages[current_page] && actual_total > 0 &&
                            <div class="row mt-4">
                                <div class="col text-end">
                                    Showing {getStartIndex()} - {getStartIndex() + pages[current_page].length - 1} of {actual_total}
                                    &nbsp;
                                    <PopupElement
                                        key="totalCountTooltip"
                                        body="Initially, the total count is a rough estimate. As you paginate through the results, it becomes more accurate and may decrease from the initial estimate."
                                        element={<img src={tool_tip_icon} className="tooltip_16"/>}
                                    />
                                </div>
                            </div>
                        }
                        {pages[current_page] && pages[current_page].map(entry => {
                            if (executing_search) {
                                return  <ResultRowPlaceholder/>
                            }
                            else {
                                return  <ResultRow entry={entry}/>
                            }
                        })}

                        <div class="row mt-4">
                            <div class="col d-flex justify-content-center">
                                {
                                    pages.map((item, index) => {
                                        return  <span class={pagination_page_style_span(index)}>
                                                    <a className={pagination_page_style(index)} role={pagination_page_role(index)} onClick={()=> this.setState({current_page: index})}>{index + 1}</a>
                                                </span>
                                    })
                                }
                                {
                                    pages.length > 0 &&
                                        <span class="mt-1">
                                            {
                                                (total > start_index) && 
                                                <a className="text-decoration-none" role="button" onClick={ this.initiateNextPage }>&nbsp;<i class="fa-solid fa-angle-right fa-xl"></i></a>
                                            }
                                        </span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
    }
}

function ResultRow(props){

    const {entry} = props;

    const getDetailUrl = (record_id, file_names_list) => {
        let file_names = file_names_list.join("___");
        return "/code/sf_detail/" + record_id + "?fn=" + file_names;
    }

    return (
        <Card className="mt-4 resultRow">
            <Card.Body className="ps-0 pe-0" >
                <a href={getDetailUrl(entry['record_id'], entry['highlight_file_names'])} target="_blank" className="resultRowLink">
                <div class="container" role="button">
                    <div class="row">
                        <div class="col-8">
                            <div class="row">
                                <div class="col">
                                    <div class="row">
                                        <div class="col fs-6 fw-bold idLink">
                                            <a 
                                                className="text-black" 
                                                href={getDetailUrl(entry['record_id'], entry['highlight_file_names'])} 
                                                target="_blank">{entry['record_number']}
                                            </a>
                                        </div>
                                    </div>
                                    <div class="container mt-3 applicationSummary">
                                        <div class="row fw-lighter ">Application summary</div>
                                        <div class="row">
                                            {
                                                entry['highlight'] && entry['highlight'].length > 0 ?
                                                <span dangerouslySetInnerHTML={{__html: truncateText(entry['highlight'], 400, true)}} className="p-0"/> :
                                                <span className="p-0">{truncateText(entry['description'], 400, true)}</span>
                                            }
                                        </div>
                                    </div>
                                    <div class="container mt-3 ps-0">
                                        <div class="row fw-lighter">
                                            <div class="col">Parent application type</div>
                                            <div class="col">Parent application status</div>
                                            <div class="col">Application date</div>
                                        </div>
                                        <div class="row">
                                            <div class="col">{entry['record_type']}</div>
                                            <div class="col">{entry['current_status']}</div>
                                            <div class="col">{entry['application_date']}</div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                           
                           
                        
                            {
                                entry['related_record_types'] && entry['related_record_types'].length > 0 &&
                                <div class="container mt-3 subApplications">
                                     <div class="row fw-lighter ">Additional sub applications</div>
                                    <div class="row">
                                        {
                                            entry['related_record_types'].map((related_type, idx) => {
                                                if (idx < entry['related_record_types'].length - 1){
                                                    return <>{related_type}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp; </>
                                                }
                                                else{
                                                    return <>{related_type}</>
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            {
                                entry['file_types'] && entry['file_types'].length > 0 &&
                                <div class="container mt-3 availableFiles">
                                    <div class="row fw-lighter ">Available files &amp; documents</div>
                                    <div class="row">
                                        {
                                            entry['file_types'].map((file_type, idx) => {
                                                if (idx < entry['file_types'].length - 1){
                                                    return <>{file_type}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp; </>
                                                }
                                                else{
                                                    return <>{file_type}</>
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            }
                                
                        </div>
                        <div class="col-4 propertyInfoColumn">
                            { (entry['address']['street_name'] && entry['address']['street_name'].toLocaleLowerCase() !== 'public') &&
                                <div class="container">
                                    <div class="row">{entry['address']['street_number']} {entry['address']['street_name']} {entry['address']['street_suffix']}</div>
                                    <div class="row">San Francisco {entry['address']['postal']}</div>                                </div>
                            }
                            {
                                (entry['use_district'] || entry['height_district']) && 
                                <div class="container mt-3">
                                    <div class="row fw-lighter">
                                        <div class="col ps-0">Use (Zone)</div>
                                        <div class="col">Height district</div>
                                    </div>
                                    <div class="row">
                                        <div class="col ps-0"> {entry['use_district']}</div>
                                        <div class="col">{entry['height_district']}</div>
                                    </div>
                                    
                                </div>
                            }
                            {
                                entry['special_use_districts'] && entry['special_use_districts'].length > 0 && 
                                <div class="container mt-3">
                                    <div class="row fw-lighter">Special use districts</div>
                                    <div class="row">
                                        {
                                            entry['special_use_districts'].map((district, idx) => {
                                                if (idx < entry['special_use_districts'].length - 1) {
                                                    return <>{district}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp; </>
                                                }
                                                else{
                                                    return <>{district}</>
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            }
                         </div>
                    </div>
                    
                    <InsightComponent entry={entry}/>
                   
                </div>
                </a>
            </Card.Body>
        </Card>
    )
}

function ResultRowPlaceholder(){

    return (
        <Card className="mt-4 resultRow">
            <Card.Body className="ps-0 pe-0">
                <div class="container">
                    <div class="row">
                        <div class="col-8">
                            <div class="row">
                                <div class="col">
                                    <div class="row ms-1">
                                        {/* <div class="col fs-6 fw-bold idLink"> */}
                                            <Placeholder as="p" animation="glow">
                                                <Placeholder xs={4} bg="secondary"/>
                                            </Placeholder>
                                        {/* </div> */}
                                    </div>
                                    <div class="container mt-3 applicationSummary">
                                        {/* <div class="row fw-lighter ">Application summary</div> */}
                                        <div class="row">
                                            <Placeholder as="p" animation="glow">
                                                <Placeholder xs={12} bg="secondary" size="lg"/>
                                                <Placeholder xs={12} bg="secondary" size="lg"/>
                                                {/* <Placeholder xs={12} bg="secondary"/> */}
                                            </Placeholder>
                                        </div>
                                    </div>
                                    {/* <div class="container mt-3 ps-0">
                                        <div class="row fw-lighter">
                                            <div class="col">Parent application type</div>
                                            <div class="col">Parent application status</div>
                                            <div class="col">Application date</div>
                                        </div>
                                        <div class="row">
                                            <div class="col"><Placeholder sm={4} /></div>
                                            <div class="col"><Placeholder sm={4} /></div>
                                            <div class="col"><Placeholder sm={4} /></div>
                                        </div>
                                    </div> */}
                                    
                                </div>
                            </div>
                        
                            <div class="container mt-3 subApplications">
                                    {/* <div class="row fw-lighter ">Additional sub applications</div> */}
                                {/* <div class="row">
                                    <Placeholder xs={12} animation="glow"/>
                                    <Placeholder xs={11} animation="glow"/>
                                </div> */}
                            </div>
                           
                            <div class="container mt-3 availableFiles">
                                {/* <div class="row fw-lighter ">Available files &amp; documents</div> */}
                                <div class="row">
                                    <Placeholder as="p" animation="glow">
                                        <Placeholder xs={12} bg="secondary" size="lg"/>
                                        <Placeholder xs={12} bg="secondary" size="lg"/>
                                    </Placeholder>
                                </div>
                            </div>
                                
                        </div>
                        <div class="col-4 propertyInfoColumn">
                        
                            <div class="container">
                                <div class="row">
                                <Placeholder as="p" animation="glow">
                                        <Placeholder xs={12} bg="secondary"/>
                                        <Placeholder xs={12} bg="secondary"/>
                                    </Placeholder>
                                </div>                           
                            </div>
                    
                            <div class="container mt-3">
                                {/* <div class="row fw-lighter">Use (Zone) &nbsp;&nbsp;&#x2022;&nbsp;&nbsp;Height district</div> */}
                                <div class="row">
                                    {/* <Placeholder xs={12} animation="glow"/> */}
                                </div>
                                
                            </div> 
                       
                            {/* <div class="container mt-3">
                                <div class="row fw-lighter">Special use districts</div>
                                <div class="row">
                                    <Placeholder xs={12} />
                                    <Placeholder xs={12} />
                                </div>
                            </div> */}
                         </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

function PropertyAddressSection(props){
    const {property_address} = props;

    const [show_search_bar, setShowSearchBar] = useState(false);
    const [temp_property_address, setTempPropertyAddress] = useState(null);

    const reset = () => {
        setTempPropertyAddress(null);
        setShowSearchBar(false);
    }

    return( 
        <Card className="mt-4 p-3 propertyAddressSection">
            {/* <Card.Title className="fs-6 fw-bold">Property address</Card.Title> */}
            <Card.Body className="p-0">
                {
                    !property_address && !show_search_bar && 
                    <div class="row">
                        <div class="col">
                            <a role="button" className="text-decoration-underline" onClick={()=>setShowSearchBar(true)}>
                                <small>Set a project address</small>
                            </a>
                            &nbsp;
                            <PopupElement
                                key="setPropertyAddressInfo"
                                body={<span>By setting a project address, you can use the <b>Distance</b> filter
                                 to limit your search to a preferred radius.</span>}
                                element={<img src={tool_tip_icon} className="tooltip_16"/>}
                            />
                        </div>
                    </div>
                }
                {
                    show_search_bar &&
                        <>
                            <AddressSearchComponent 
                                setTempPropertyAddress={setTempPropertyAddress}
                            />
                            <div class="row mt-3">
                                <div class="col text-end">
                                    <Button variant="secondary" onClick={ reset } size="sm">
                                        Cancel
                                    </Button>
                                </div>
                                <div class="col-auto text-end">
                                    <Button variant="primary" disabled={ !temp_property_address } onClick={ () => { props.setPropertyAddress(temp_property_address); reset()} }  size="sm">
                                        Select
                                    </Button>
                                </div>
                            </div>
                        </>
                }
                {
                    property_address && !show_search_bar && 
                        <>
                            <div class="container">
                                <div class="row">
                                    <div class="col fw-semibold">{property_address.formatted_address}</div>
                                </div>
                            </div>
                            {
                                (property_address.use_district || property_address.height_district) && 
                                <div class="container mt-2">
                                    <div class="row fw-lighter">
                                        <div class="col">Use (Zone)</div>
                                        <div class="col">Height district</div>
                                    </div>
                                    <div class="row">
                                        <div class="col"> {property_address.use_district}</div>
                                        <div class="col">{property_address.height_district}</div>
                                        {/* {property_address.use_district}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;{property_address.height_district} */}
                                    </div>
                                </div>
                            }
                            {
                                property_address.special_use_districts && property_address.special_use_districts.length > 0 && 
                                <div class="container mt-2">
                                   <div class="row">
                                        <div class="col  fw-lighter">
                                            Special use districts
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            {
                                            property_address.special_use_districts.map((district, idx) => {
                                                    if (idx < property_address.special_use_districts.length - 1) {
                                                        return <>{district}&nbsp;&nbsp;&#x2022;&nbsp;&nbsp; </>
                                                    }
                                                    else{
                                                        return <>{district}</>
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                }
                            <div class="row mt-2">
                                <div class="col-auto ms-4">
                                    <a role="button" className="text-decoration-underline" onClick={()=>setShowSearchBar(true)}><small>Edit</small></a>
                                </div>
                                <div class="col-auto">
                                    <a role="button" className="text-decoration-underline" onClick={ () => props.setPropertyAddress(null) }><small>Remove</small></a>
                                </div>
                            </div>
                        </>
                        
                }
            </Card.Body>
        </Card>)
}

function DisclaimerModal(props){

    const {show_disclaimer_modal, dismissDisclaimerModal} = props;

    return (
        <Modal
            show={show_disclaimer_modal}
            onHide={dismissDisclaimerModal}
            size="lg"
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered>

            <Modal.Header>
                <Modal.Title>Welcome</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <p>
                                Thank you for signing up for Renolition, the most comprehensive tool for searching past entitlement permits. 
                            </p>
                            <p>
                                Renolition indexes over <b>60K entitlement permit applications</b> and over <b>200K related documents</b> and 
                                files, spanning over 10 years of data in San Francisco. New data is updated daily, and 
                                we continue adding more historical data.
                            </p>
                            <p>
                                We strive to maintain an up-to-date database of permits, related documents, and files. However, 
                                despite our best efforts, some records may be missing, mislabeled, or contain discrepancies 
                                compared to official city records.
                            </p>
                            <p>
                                This tool is intended to assist with entitlement research but should not be relied upon for final 
                                project decisions. Always consult official sources for the most accurate and authoritative codes, 
                                regulations, and guidelines.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col text-center">
                            <Button variant="primary" onClick={dismissDisclaimerModal}>Let's go</Button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

const getSearchTypeToolTipText = (search_type) => {

    if (search_type === QueryMethods.KEYWORDS){
        return <><i>Keywords:</i> This search type is best if you know a few common keywords for the project you are searching for. Renolition 
        will search for projects that match as <b>many keywords as possible</b>. The search will also include common variations of the 
        keywords, words that are close to your keywords, and misspellings.</>
    }
    else if (search_type === QueryMethods.SEMANTIC){
        return <><i>Semantic:</i>  If you're unsure which keywords to use, Semantic Search is the best option. You can enter full sentences, 
            and the system will find permits that match the meaning of your query. For example, if you're looking for permits 
            related to <i>communication equipment installed on rooftops</i> but don't know the common terminology, you could search 
            for <b>communication equipment on rooftops</b>. 
            This would also return results for permits related to antennas, wireless telecommunication equipment, and more.</>
    }
}

function BooleanGuideModal(props) {
    const {show_boolean_guide_modal, dismissBooleanGuideModal} = props;

    return (
        <Modal
            show={show_boolean_guide_modal}
            onHide={dismissBooleanGuideModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>

            <Modal.Header closeButton>
                <Modal.Title>Boolean Search Guide</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="container">
                <>
                    <div>In this advanced search method, you can use Boolean expressions for precise matches. Note that operators are case-sensitive.</div>
                    <div class="mt-2 mb-2"><span className="fw-bold">Syntax</span></div>
                    <div>
                        <p><b>AND</b>: The AND operator matches documents where both terms exist. To search for documents that contain <i>affordable</i> and <i>housing</i> use the query:&nbsp;
                            <b>affordable AND housing</b>
                        </p>
                        <p><b>OR</b>: The OR operator matches documents where any of the terms exist. To search for documents that contain <i>affordable</i> or <i>housing</i> use the query:&nbsp;
                            <b>affordable OR housing</b>
                        </p>
                        <p><b>NOT</b>: The NOT operator excludes documents that contain the term after NOT. To search for documents that contain <i>affordable</i> but not <i>housing</i> use the query:&nbsp;
                            <b>affordable NOT housing</b>
                        </p>
                        <p><b>Phrases</b>: A Phrase is a group of words surrounded by double quotes such as "affordable housing". To search for documents that contain <i>affordable housing</i> use the query:&nbsp;
                            <b>"affordable housing"</b>
                        </p>
                        <p><b>Wildcards</b>: We support multiple character wildcard searches within single terms. To perform a multiple character wildcard search use the "*" symbol. Multiple character wildcard searches looks for 0 or more characters. 
                            For example, to search for <i>house</i>, <i>houses</i> or <i>housing</i>, you can use the query:&nbsp;
                            <b>hous*</b>
                        </p>
                        <p><b>Grouping</b>: We support using parentheses to group sub queries. For example, to search for the terms <i>affordable housing</i> and either one of <i>density</i> or <i>bonus</i>, you can use the query:&nbsp;
                            <b>"density bonus" AND (affordable OR housing)</b>
                        </p>
                    </div>
                </>
                </div>
            </Modal.Body>
        </Modal>
    )
}

function GuideComponent(props){
    const {query_method, initiateSampleKeywordSearch, initiateSampleSemanticSearch, initiateSampleBooleanSearch} = props;

    const [expanded, setExpanded] = useState(false);

    return (
        <div class="container mt-4 border round p-3">
            <div class="row"><div class="col fw-semibold fs-5 searchGuideTitle">Sample {query_method} Searches</div></div>
            {
                query_method === QueryMethods.KEYWORDS && 
                <div class="row mt-3">
                    <div class="col">
                        <p>
                            Start with a few strong <b>keywords</b> that describe the projects you are looking for. 
                            You can include the permit type (e.g. <i>Variance, Conditional Use</i>) in the keywords if 
                            you know it. After the initial search results appear, you can apply filters to narrow them 
                            down further.
                        </p>
                        <p>Try some of these sample searches that also set useful filters:</p>
                        <p>
                            <ul>
                                <li><b>Search 1</b>: Find variance determination documents related to decks in the rear yard
                                    <ul>
                                        <li>Search keywords: rear yard variance for a deck</li>
                                        <li>Application type filter: Variance</li>
                                        <li>Available documents filter: Variance determination</li>
                                        <li><a role="button" className="text-decoration-underline" onClick={() => initiateSampleKeywordSearch('rear_yard')}>Try it out</a></li>
                                    </ul>

                                </li>
                                <li className="mt-2"><b>Search 2</b>: Find applications related to demolishing a single family building, that include staff letters/commission reports
                                    <ul>
                                        <li>Search keywords: demolish a single family building</li>
                                        <li>Application type filter: Conditional use</li>
                                        <li>Available documents filter: Comm. Motions & Executive Summaries</li>
                                        <li><a role="button" className="text-decoration-underline" onClick={() => initiateSampleKeywordSearch('demolish')}>Try it out</a></li>
                                    </ul>
                                </li>
                                <li className="mt-2"><b>Search 3</b>: Find zoning determinations related to rear yard setbacks
                                    <ul>
                                        <li>Search keywords: rear yard setback</li>
                                        <li>Application type filter: Zoning Determination Letter</li>
                                        <li>Available documents filter: Zoning Determination</li>
                                        <li><a role="button" className="text-decoration-underline" onClick={() => initiateSampleKeywordSearch('rear_yard_zad')}>Try it out</a></li>
                                    </ul>
                                </li>
                                <li className="mt-2"><b>Search 4</b>: Discover applications related to operating a parcel delivery service out of a cannabis dispensary.
                                    <ul>
                                        <li>Search keywords: cannabis dispensary parcel delivery service'</li>
                                        <li>Application type filter: Conditional use</li>
                                        <li>Available documents filter: Comm. Motions & Executive Summaries</li>
                                        <li><a role="button" className="text-decoration-underline" onClick={() => initiateSampleKeywordSearch('cannabis')}>Try it out</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </p>
                    </div>
                </div>
            }
            {
                query_method === QueryMethods.SEMANTIC && 
                <div class="row mt-3">
                    <div class="col">
                        <p>
                            You can start with a full sentence or fragment of sentence for the project you are searching for. 
                            This search type works best if you are unsure which keywords to use. 
                            You can include the permit type (e.g. <i>Variance, Conditional Use</i>) in the keywords if you know it. 
                            After the initial search results appear, you can apply filters to narrow them down further.
                        </p>
                        <p>Try some of these sample searches that also set useful filters:</p>
                        <p>
                            <ul>
                                <li><b>Search 1</b>: Find conditional use applications related to installing wireless equipment on the rooftop
                                    <ul>
                                        <li>Search sentence: install communication equipment on rooftops</li>
                                        <li>Application type filter: Conditional use</li>
                                        <li>Available documents filter: Comm. Motions & Executive Summaries</li>
                                        <li><a role="button" className="text-decoration-underline" onClick={() => initiateSampleSemanticSearch('roof')}>Try it out</a></li>
                                    </ul>

                                </li>
                                <li className="mt-2"><b>Search 2</b>: Find applications related to demolishing a residential building and building a multi-unit.
                                    <ul>
                                        <li>Search keywords: demolish a residential building and build a multi unit</li>
                                        <li>Application type filter: Conditional use</li>
                                        <li>Available documents filter: Comm. Motions & Executive Summaries</li>
                                        <li><a role="button" className="text-decoration-underline" onClick={() => initiateSampleSemanticSearch('demolish')}>Try it out</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </p>
                    
                    </div>
                </div>
            }
            {
                query_method === QueryMethods.BOOLEAN && 
                <div class="row mt-3">
                    <div class="col">
                        <p>
                            A Boolean search works best when you need fine-grained control over the search keywords with Boolean expressions. Note that the Boolean operators are case-sensitive.
                        </p>
                        <p>Try some of these sample searches that also set useful filters:</p>
                        <p>
                            <ul>
                                <li><b>Search 1</b>: Find conditional use applications that contain both phrases "GPS antenna" and "wireless facility"
                                    <ul>
                                        <li>Search expression: "GPS antenna" AND "wireless facility"</li>
                                        <li>Application type filter: Conditional use</li>
                                        <li>Available documents filter: Comm. Motions & Executive Summaries</li>
                                        <li><a role="button" className="text-decoration-underline" onClick={() => initiateSampleBooleanSearch('gps')}>Try it out</a></li>
                                    </ul>

                                </li>
                                <li className="mt-2"><b>Search 2</b>: Find conditional use applications that contain the word <i>cannabis</i> and either of the phrases "parcel delivery service" or "light manufacturing"
                                    <ul>
                                        <li>Search expression: cannabis AND ("parcel delivery service" OR "light manufacturing")</li>
                                        <li>Application type filter: Conditional use</li>
                                        <li>Available documents filter: Comm. Motions & Executive Summaries</li>
                                        <li><a role="button" className="text-decoration-underline" onClick={() => initiateSampleBooleanSearch('cannabis')}>Try it out</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </p>
                    
                        <div class="mt-2 mb-2"><span className="fw-bold">Syntax</span></div>
                        <div>
                            <p><b>AND</b>: The AND operator matches documents where both terms exist. To search for documents that contain <i>affordable</i> and <i>housing</i> use the query:&nbsp;
                                <b>affordable AND housing</b>
                            </p>
                            <p><b>OR</b>: The OR operator matches documents where any of the terms exist. To search for documents that contain <i>affordable</i> or <i>housing</i> use the query:&nbsp;
                                <b>affordable OR housing</b>
                            </p>
                            <p><b>NOT</b>: The NOT operator excludes documents that contain the term after NOT. To search for documents that contain <i>affordable</i> but not <i>housing</i> use the query:&nbsp;
                                <b>affordable NOT housing</b>
                            </p>
                            <p><b>Phrases</b>: A Phrase is a group of words surrounded by double quotes such as "affordable housing". To search for documents that contain <i>affordable housing</i> use the query:&nbsp;
                                <b>"affordable housing"</b>
                            </p>
                            <p><b>Wildcards</b>: We support multiple character wildcard searches within single terms. To perform a multiple character wildcard search use the "*" symbol. Multiple character wildcard searches looks for 0 or more characters. 
                                For example, to search for <i>house</i>, <i>houses</i> or <i>housing</i>, you can use the query:&nbsp;
                                <b>hous*</b>
                            </p>
                            <p><b>Grouping</b>: We support using parentheses to group sub queries. For example, to search for the terms <i>affordable housing</i> and either one of <i>density</i> or <i>bonus</i>, you can use the query:&nbsp;
                                <b>"density bonus" AND (affordable OR housing)</b>
                            </p>
                        </div>
                        
                    </div>
                </div>
            }
{/*             
            {
                !expanded &&
                    <div class="row mt-2">
                        <div class="col">
                            <a role="button" className="text-decoration-underline" onClick={() => setExpanded(true)}>Show more</a>
                            &nbsp;<i class="fa-solid fa-angles-down"></i>
                        </div>
                    </div>
            } */}
            {/* {
                expanded &&
                <>
                    <div class="row mt-2 mb-1">
                        <div class="col fw-bold">About filter types</div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <ul>
                                <li className="mt-1">
                                    <span className="fw-semibold"><i>Date range:</i></span> Filter applications based on their submission date to the city.
                                </li>
                                <li className="mt-1">
                                    <span className="fw-semibold"><i>Application type:</i></span> Filter permit applications by type. Large projects may include multiple sub-application 
                                        types—for example, an addition project might require a variance and an environmental 
                                        application. This filter ensures projects with any of the selected application types are included in 
                                        the search.
                                </li>
                                <li className="mt-1">
                                    <span className="fw-semibold"><i>Available documents:</i></span> Filter permit applications based on the availability of specific document types. For example, 
                                    you can filter applications that contain variance decision letters, zoning decision letters, conditional use staff reports, etc.
                                </li>
                                <li className="mt-1">
                                    <span className="fw-semibold"><i>Use district, height district, special use districts:</i></span> Filter applications based on their use (zone), height, and special use districts.
                                </li>
                                <li className="mt-1">
                                    <span className="fw-semibold"><i>Current application status:</i></span> Filter permit applications by their current status (e.g., canceled, closed). Note that some project statuses may 
                                    be outdated if not updated by the city. We suggest to use this filter after using other filters to narrow the results.
                                </li>
                                <li className="mt-1">
                                    <span className="fw-semibold"><i>Appeals &amp; opposition:</i></span> Search for permit applications that were appealed or faced opposition.
                                </li>
                                <li className="mt-1">
                                    <span className="fw-semibold"><i>Distance:</i></span> Search for permit applications within a specified distance from your project's address. 
                                    You must specify a project address to enable this filter.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row mt-2 mb-1">
                        <div class="col fw-bold">Make sure to hit <b>Apply</b> after setting your filters.</div>
                    </div>
                    
                    <div class="row mt-2">
                        <div class="col">
                            <a role="button" className="text-decoration-underline" onClick={() => setExpanded(false)}>Show less</a>
                            &nbsp;<i class="fa-solid fa-angles-up"></i>
                        </div>
                    </div>
                </>
            } */}
            
        </div>
    );
}

class AddressSearchComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            select_button_enabled: false,
        }

        this.autocompleteInput = React.createRef();
        this.autocomplete = null;
        
        this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
        this.addressChanged = this.addressChanged.bind(this);
        this.resetAddressField = this.resetAddressField.bind(this);
    }
      
    addressChanged(e) {
        // this.setState({
        //     address_field: e.target.value,
        // });
        this.props.setTempPropertyAddress(null);
    }

    resetAddressField() {
        this.autocompleteInput.current.value = "";
        this.props.setTempPropertyAddress(null);
    }

    componentDidMount() {

        const center = { lat: 37.75953994968017, lng: -122.44697911726526 };

        // Create a bounding box with sides ~10km away from the center point
        const defaultBounds = {
            north: center.lat + 0.07,
            south: center.lat - 0.07,
            east: center.lng + 0.07,
            west: center.lng - 0.07,
        };
        this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
            {"types": ["geocode"], "fields": ["address_components", "formatted_address", "name", "geometry"], bounds: defaultBounds, strictBounds: true });

        this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
    }

    handlePlaceChanged(){
        const place = this.autocomplete.getPlace();
        let streetNumber = '';
        let streetName = ''
        let city = '';
        let state = '';
        let zipCode = '';

        let i = 0;
        let lat = place.geometry.location.lat();
        let lng = place.geometry.location.lng();

        for(; i < place.address_components.length; i++) {
            let component = place.address_components[i];
            if (component.types.includes("street_number")) {
                streetNumber = component.long_name;
            }
            else if (component.types.includes("route")) {
                streetName = component.long_name;
            }
            else if (component.types.includes("locality")) {
                city = component.long_name;
            }
            else if (component.types.includes("administrative_area_level_1")) {
                state = component.short_name;
            }
            else if (component.types.includes("postal_code")) {
                zipCode = component.long_name;
            }
        }

        this.props.setTempPropertyAddress({
            street_number: streetNumber,
            street_name: streetName,
            city: city,
            state: state,
            zip_code: zipCode,
            formatted_address: place.formatted_address,
            lat: lat,
            lng: lng,
        });
    }

    render() {

        return( 
            <>
                <div class="row mt-1">
                    <div class="col">
                        <div class="input-group">
                            <input type="text" class="form-control" placeHolder="Address or APN" ref={this.autocompleteInput}  id="autocomplete" onChange={ (e) => this.addressChanged(e) }/>
                                <Button className="bg-transparent text-dark clearButton" onClick={this.resetAddressField}>
                                    <i class="fa-solid fa-magnifying-glass"></i>
                                </Button>
                            
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

// need this reference to delete the circle when creating a new one. Could not find a better method.
let my_circle = null;
function MapComponent(props) {

    const [googleApiObj, setIsGoogleApiLoadedObj] = useState(null);
    const [active_marker, setActiveMarker] = useState(null);

    const {property_address, current_page, radius, pages} = props;

    useEffect(() => {
        if (googleApiObj) {
          const {map, maps} = googleApiObj;

            if (my_circle) {
                my_circle.setMap(null);
            }
            my_circle = getCircle(map, maps)
        }
    }, [googleApiObj, property_address, radius]);

    // re-center and re-zoom after the page changes. JSON.stringify is needed for cases when
    // the size of the array changes (e.g. going to the next page).
    useEffect(() => {
        if (googleApiObj) {
            const {map, maps} = googleApiObj;
            map.setZoom(11);
            map.setCenter({lat: 37.7617669, lng: -122.4473969 });
        }
    }, [JSON.stringify(pages), pages]);

    const reSetActiveMarker = (marker) => {
        setActiveMarker(marker);
    }

    const onChildClick = (e, childProps) => {
        setActiveMarker(childProps);
    }

    const getCircle = (map, maps) => { 
        
        let circle_radius = 0;
        let circle_center = {lat: 37.7617669, lng: -122.4473969}

        if (property_address && property_address.lat && property_address.lng) {
            circle_center = {lat: property_address.lat, lng: property_address.lng};
        }

        if (parseFloat(radius) > 0) {
            circle_radius = parseFloat(radius) * 1609.34;
        }

        return new maps.Circle({
            strokeColor: '#858080',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#ccc9c9',
            fillOpacity: 0.5,
            map,
            center: circle_center,
            radius: circle_radius,
            })
    }

    return(
        <div class="container propertiesMap">
        <Card className="mt-3">
            <Card.Body>
                <div class="row">
                    <div class="col-12 map p-0">
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: 'AIzaSyAyQMpPy1QPIieWCFecN0j8QGQpKTs82vk' }}
                            defaultCenter={{lat: 37.7617669, lng: -122.4473969 }}
                            defaultZoom={11}
                            zoom={11}
                            onChildClick={ onChildClick }
                            yesIWantToUseGoogleMapApiInternals={true}
                            onGoogleApiLoaded = {({map, maps}) => setIsGoogleApiLoadedObj({map, maps})}
                        >
                            {
                                current_page && current_page.map(entry =>{  return <ResultsMarker
                                                                                lat={entry.lat}
                                                                                lng={entry.lng}
                                                                                record_number={entry.record_number}
                                                                                record_id={entry.record_id}
                                                                                record_type={entry.record_type}
                                                                                description={entry.description}
                                                                                text="My Marker"/>
                                })
                            }
                            { active_marker &&
                                <MapMarker
                                    record_id={active_marker.record_id}
                                    record_number={active_marker.record_number}
                                    description={active_marker.description}
                                    record_type={active_marker.record_type}
                                    setActiveMarker={reSetActiveMarker}
                                /> 
                            }
                            {
                                property_address && property_address.lat && property_address.lng && 
                                    <PropertyMarker
                                        lat={property_address.lat}
                                        lng={property_address.lng}
                                        text="Marker"/>
                            }
                        </GoogleMapReact>
                    </div>
                </div>
            </Card.Body>
        </Card>
        </div>
    );
}

const ResultsMarker = (props) => {

    return(
        <div class="places" >
            <div class="placesIcon"> <i class="fa-solid fa-location-dot fa-xl" style={{color: "#d81313"}}></i></div>
        </div>

    )
}

const PropertyMarker = (props) => {

    return(
        <div class="places" >
            <div class="placesIcon"> <i class="fa-solid fa-location-dot fa-2xl" style={{color: "#00bde3"}}></i></div>
        </div>

    )
}

const MapMarker = (props) => {

    const getDetailUrl = (record_id) => {
        return "/code/sf_detail/" + record_id;
    }
    const infoWindowStyle = {
        position: 'relative',
        top: '-90px',
        left: '-120px',
        width: 200,
        backgroundColor: 'white',
        boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
        padding: 10,
        fontSize: 14,
        zIndex: 100,
    };

    const infoWindowClose = {
        float: 'right',
        fontSize: 11,
    };

    return (
        <>
        <div style={infoWindowStyle}>
            <div class="fw-light container gx-0">
                <div class="row" onClick={() => props.setActiveMarker(null)}>
                    <div class="col-10 fw-bold"><small>{props.record_number}</small></div>
                    <div class="col-2 text-end" onClick={() => props.setActiveMarker(null)} role="button"><i class="fa-solid fa-x fa-lg"></i></div>
                </div>
                {/* <div class="row mt-1"><div class="col"><small>{props.record_type}</small></div></div> */}
                <div class="row mt-1"><div class="col"><small>{truncateText(props.description, 80, true)}</small> </div></div>
                <div class="row mt-1"><div class="col"><small><a role="button" target="_blank" href={getDetailUrl(props.record_id)} className="text-decoration-underline">more <i class="fa-solid fa-arrow-up-right-from-square"></i></a></small> </div></div>
            </div>
        </div>
        </>
    );
}

function InsightComponent(props) {

    const {entry} = props;

    const showInsights = () =>
    {
        if (entry['has_appeal'] || entry['received_opposition']){
            return true;
        }
        return false;
    }

    // const joinList = (input_list_1, input_list_2) => {
        
    //     let combined = [];
    //     if (input_list_1 && input_list_1.length > 0) {
    //         combined = combined.concat(input_list_1);
    //     }

    //     if (input_list_2 && input_list_2.length > 0) {
    //         combined = combined.concat(input_list_2);
    //     }

    //     return combined.join(",")
    // }

    if (!showInsights()) {
        return null;
    }        

    return (
        <div class="derivedSection">
            <div class="row"><div class="col"><hr></hr></div></div>
            <div class="row mb-2">
               
                {
                    entry['has_appeal'] &&
                    <div class="col-auto"><i class="fa-solid fa-check" style={{"color": "#164734"}}></i>&nbsp;<small>Appealed</small></div>
                }
                {
                    entry['received_opposition'] &&
                    <div class="col-auto"><i class="fa-solid fa-check" style={{"color": "#164734"}}></i>&nbsp;<small>Received opposition</small></div>
                }
                {/* {
                    entry['var_sought'] && entry['var_sought'].length > 0 &&
                        <div class="col-auto"><i class="fa-solid fa-check" style={{"color": "#164734"}}></i>&nbsp;<small>{entry['var_sought']}</small></div>
                }
                {
                    entry['var_decision'] && entry['var_decision'].length > 0 &&
                        <div class="col-auto"><i class="fa-solid fa-check" style={{"color": "#164734"}}></i>&nbsp;<small>Variance decision: {entry['var_decision']}</small></div>
                }
                {
                    entry['cua_hearing_date'] && entry['cua_hearing_date'].length > 0 &&
                        <div class="col-auto"><i class="fa-solid fa-calendar-days" style={{"color": "#164734"}}></i>&nbsp;<small>CUA hearing date: {entry['cua_hearing_date']}</small></div>
                }
                {
                    entry['var_hearing_date'] && entry['var_hearing_date'].length > 0 &&
                        <div class="col-auto"><i class="fa-solid fa-calendar-days" style={{"color": "#164734"}}></i>&nbsp;<small>Var hearing date: {entry['var_hearing_date']}</small></div>
                } */}
            </div>
           
        </div>
    );
}

export default SfPlanningSearch;