import { Component } from "react";
import { SF_PLANNING_INSIGHTS } from 'utils/constants';
import { trackEvent, EventNames } from 'utils/mixpanel';
import { generic_get_api } from "api/generic_api";
import { basicAccountInfo } from "api/basic_account_info";

class SfPlanningInsights extends Component {
    
    constructor(props) {
        
        super(props);

        this.state = {
            insights: {},
            active_panel_map: {
                zad_panel: true,
            }
        };

        this.fetchInsights = this.fetchInsights.bind(this);
        this.setActivePanel = this.setActivePanel.bind(this);
    }

    setActivePanel(active_panel) {
        let cur_panel = this.state.active_panel_map;

        for (const [key, value] of Object.entries(cur_panel)) {
            cur_panel[key] = false;
        }
        cur_panel[active_panel] = true;

        this.setState({active_panel_map: cur_panel});
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'sf_planning_insights'});
        document.title = "San Francisco Planning Insights";

        basicAccountInfo()
        .then(
            data => {
                this.fetchInsights();
            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            }
        });
    }

    fetchInsights() {

        generic_get_api(SF_PLANNING_INSIGHTS, {})
        .then(
            data => { 
                this.setState({
                    insights: data,
                })
            }
        )
        .catch(error => {
        });
    }

    render() {
        const {active_panel_map} = this.state;

        const getButtonClass = (panel) =>{
            if (active_panel_map[panel]) {
                return "col text-center m-2 p-1 text-white bg-warning rounded";
            }
            else{
                return "col text-center m-2 p-1 text-white bg-secondary rounded";
            }
        }

        return(
            <div class="container sfPlanningInsights">
                <div class="row">
                    <div class={getButtonClass('zad_panel')} role="button" onClick={()=>this.setActivePanel('zad_panel')}>ZA determination</div>
                    <div class={getButtonClass('zav_panel')} role="button" onClick={()=>this.setActivePanel('zav_panel')}>ZA verification</div>
                    <div class={getButtonClass('var_panel')} role="button" onClick={()=>this.setActivePanel('var_panel')}>Variance</div>
                    <div class={getButtonClass('pcl_panel')} role="button" onClick={()=>this.setActivePanel('pcl_panel')}>Application deficiencies</div>
                </div>
                <div class="row"><div class="col"><hr></hr></div></div>
                {
                    active_panel_map.zad_panel &&
                    <Zad zad_data={this.state.insights.zad} />
                }
                {
                    active_panel_map['zav_panel'] &&
                    <Zav zav_data={this.state.insights.zav} />
                }
                {
                    active_panel_map['var_panel'] &&
                    <Var var_data={this.state.insights.var} var_detail_list={this.state.insights.var_detail_list}/>
                }
                {
                    active_panel_map['pcl_panel'] &&
                    <Pcl />
                }
                
            </div>
        )
    }
}

function Pcl(props) {

    const {pcl} = props;

    return(
        <div class="container mt-5">
            <div class="row"><div class="col fs-4 text-center">Application deficiencies</div></div>
            <div class="row mt-5"><div class="col text-center"><small>Coming soon</small></div></div>
        </div>
    );
}

function Zad(props) {

    const {zad_data} = props;

    if (!zad_data) {
        return null;
    }

    return(
        <div class="container mt-5">
            <div class="row"><div class="col fs-4 text-center">Zoning administrator determination processing times</div></div>
            <div class="row mt-1"><div class="col text-center"><small><i>Last updated: 1/28/2025</i></small></div></div>
            <div class="row mt-5"><div class="col text-center"><small>We analyzed the duration from the submission of an application to the issuance of the ZA determination letter.</small></div></div>

            <div class="row mt-3 border rounded p-3">
                <div class="col">
                    <div class="row"><div class="col text-center  text-secondary">Analyzed <br></br> records</div></div>
                    <div class="row mt-2"><div class="col text-center fw-bold">{zad_data.total}</div></div>
                </div>
                <div class="col">
                    <div class="row"><div class="col text-center text-secondary">Average <br></br>processing time</div></div>
                    <div class="row mt-2"><div class="col text-center fw-bold">{zad_data.avg} weeks</div></div>
                </div>
                <div class="col">
                    <div class="row"><div class="col text-center text-secondary">Shortest <br></br>processing time</div></div>
                    <div class="row mt-2"><div class="col text-center fw-bold">{zad_data.min} weeks</div></div>
                </div>
                <div class="col">
                    <div class="row"><div class="col text-center text-secondary">Longest <br></br>processing time</div></div>
                    <div class="row mt-2"><div class="col text-center fw-bold">{zad_data.max} weeks</div></div>
                </div>
            </div>
        </div>
    );
}

function Zav(props) {

    const {zav_data} = props;

    if (!zav_data) {
        return null;
    }

    return(
        <div class="container mt-5">
            <div class="row"><div class="col fs-4 text-center">Zoning administrator verification processing times</div></div>
            <div class="row mt-1"><div class="col text-center"><small><i>Last updated: 1/28/2025</i></small></div></div>
            <div class="row mt-5"><div class="col text-center"><small>We analyzed the duration from the submission of an application to the issuance of the ZA verification letter.</small></div></div>

            <div class="row mt-3 border rounded p-3">
                <div class="col">
                    <div class="row"><div class="col text-center  text-secondary">Analyzed <br></br> records</div></div>
                    <div class="row mt-2"><div class="col text-center fw-bold">{zav_data.total}</div></div>
                </div>
                <div class="col">
                    <div class="row"><div class="col text-center text-secondary">Average <br></br>processing time</div></div>
                    <div class="row mt-2"><div class="col text-center fw-bold">{zav_data.avg} weeks</div></div>
                </div>
                <div class="col">
                    <div class="row"><div class="col text-center text-secondary">Shortest <br></br>processing time</div></div>
                    <div class="row mt-2"><div class="col text-center fw-bold">{zav_data.min} weeks</div></div>
                </div>
                <div class="col">
                    <div class="row"><div class="col text-center text-secondary">Longest <br></br>processing time</div></div>
                    <div class="row mt-2"><div class="col text-center fw-bold">{zav_data.max} weeks</div></div>
                </div>
            </div>
        </div>
    );
}


function Var(props) {
    const {var_data, var_detail_list} = props;

    if (!var_data || !var_detail_list) {
        return null;
    }
    return(
        <div class="container mt-5">
            <div class="row"><div class="col fs-4 text-center">Variance processing times</div></div>
            <div class="row mt-1"><div class="col text-center"><small><i>Last updated: 1/28/2025</i></small></div></div>
            <div class="row mt-5"><div class="col text-center"><small>We analyzed the duration from the submission of a variance application to the issuance of the decision.</small></div></div>

            <div class="row mt-3 border rounded p-3">
                <div class="row"><div class="col text-center fw-semibold text-secondary">All variance applications</div></div>
                <div class="row mt-4">
                    <div class="col">
                        <div class="row"><div class="col text-center text-secondary"><small>Analyzed <br></br> records</small></div></div>
                        <div class="row mt-2"><div class="col text-center fw-semibold">{var_data.total}</div></div>
                    </div>
                    <div class="col">
                        <div class="row"><div class="col text-center text-secondary"><small>Average <br></br>processing time</small></div></div>
                        <div class="row mt-2"><div class="col text-center fw-semibold">{var_data.avg} weeks</div></div>
                    </div>
                    <div class="col">
                        <div class="row"><div class="col text-center text-secondary"><small>Shortest <br></br>processing time</small></div></div>
                        <div class="row mt-2"><div class="col text-center fw-semibold">{var_data.min} weeks</div></div>
                    </div>
                    <div class="col">
                        <div class="row"><div class="col text-center text-secondary"><small>Longest <br></br>processing time</small></div></div>
                        <div class="row mt-2"><div class="col text-center fw-semibold">{var_data.max} weeks</div></div>
                    </div>
                </div>
            </div>
            {
                var_detail_list.map(var_detail=>{
                    return  <div class="row mt-4 border rounded p-3">
                                <div class="row"><div class="col text-center fw-semibold text-secondary">{var_detail.name}</div></div>
                                <div class="row mt-4">
                                    <div class="col">
                                        <div class="row"><div class="col text-center text-secondary"><small>Average <br></br>processing time</small></div></div>
                                        <div class="row mt-2"><div class="col text-center fw-semibold">{var_detail.avg} weeks</div></div>
                                    </div>
                                    <div class="col">
                                        <div class="row"><div class="col text-center text-secondary"><small>Shortest <br></br>processing time</small></div></div>
                                        <div class="row mt-2"><div class="col text-center fw-semibold">{var_detail.min} weeks</div></div>
                                    </div>
                                    <div class="col">
                                        <div class="row"><div class="col text-center text-secondary"><small>Longest <br></br>processing time</small></div></div>
                                        <div class="row mt-2"><div class="col text-center fw-semibold">{var_detail.max} weeks</div></div>
                                    </div>
                                </div>
                            </div>
                })
            }
        </div>
    );
}

export default SfPlanningInsights;