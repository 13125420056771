import React from 'react';
// import video from 'assets/renolition.mp4';
import {trackEvent, EventNames} from 'utils/mixpanel';
import email_video_place_holder from 'assets/email_video_place_holder.png';

class Video extends React.Component {
    
    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'video'})
    }

    render() {
       
        return (
            <div class="container">
                <img src={email_video_place_holder} style={{display: "none"}} />
                <video width="80%" height="80%" controls>
                    {/* <source src={video} type="video/mp4" /> */}
                    Your browser does not support the video tag.
                </video>
            </div>
           );
      
    }
}

export default Video;