import {trackEvent, EventNames} from 'utils/mixpanel';

export const generic_put_api_file = (endpoint_url, data) => {
    
    let token = localStorage.getItem('token');

    const requestOptions = {
        method: 'PUT',
        headers: { Authorization: 'bearer ' + token },
        withCredentials: true,
        credentials: 'include',
        body: data
    };

    var url = new URL(endpoint_url);

    trackEvent(EventNames.API_REQUEST, {data_1: endpoint_url, data_2: 'PUT_FILE'});

    return fetch(url, requestOptions)
    .then(
        (result) => {
            if (result.status === 200 || result.status === 201 || result.status === 206 || result.status === 204) {
                return {status: result.status, data: result};  
            }
            else if (result.status == 401) {
                trackEvent(EventNames.TOKEN_ERROR, {data_1: endpoint_url});
                const error = new Error('Error in login');
                error.name = 'LOGIN_ERROR';
                throw error;
            } 
            else {
                throw new Error('Error');
            }
        }
    ).then(data=>data);
}

export const generic_post_api = (request, endpoint_url) => {
    
    let token = localStorage.getItem('token');

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: 'bearer ' + token },
        withCredentials: true,
        credentials: 'include',
        body: JSON.stringify(request)
    };

    var url = new URL(endpoint_url);

    trackEvent(EventNames.API_REQUEST, {data_1: endpoint_url, data_2: 'POST'});

    return fetch(url, requestOptions)
    .then(
        (result) => {
            if (result.status === 200 || result.status === 201 || result.status === 206 || result.status === 204 || result.status === 409) {
                return {status: result.status, data: result};  
            }
            else if (result.status == 401) {
                trackEvent(EventNames.TOKEN_ERROR, {data_1: endpoint_url})
                const error = new Error('Error in login');
                error.name = 'LOGIN_ERROR';
                throw error;
            }
            else if (result.status == 400) {
                trackEvent(EventNames.BAD_REQUEST, {data_1: endpoint_url})
                const error = new Error('Bad Request');
                error.name = 'BAD_REQUEST';
                throw error;
            } 
            else {
                throw new Error('Error');
            }
        }
    ).then(data=>data);
}

export const generic_get_api = (endpoint_url, search_params = {}) => {
    
    let token = localStorage.getItem('token');

    trackEvent(EventNames.API_REQUEST, { data_1: endpoint_url, data_2: 'GET', data_3: search_params});

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: 'bearer ' + token },
        withCredentials: true,
        credentials: 'include',
    };

    var url = new URL(endpoint_url);

    for (const [key, value] of Object.entries(search_params)) {
        url.searchParams.append([key], [value]);
    }

    return fetch(url, requestOptions)
    .then(
        (result) => {
            if (result.status === 200) {
                return result.json();  
            }
            else if (result.status == 401) {
                trackEvent(EventNames.TOKEN_ERROR, {data_1: endpoint_url})
                const error = new Error('Error in login');
                error.name = 'LOGIN_ERROR';
                throw error;
            } 
            else {
                throw new Error('Error');
            }
        }
    ).then(data=> { return data });
}

export const generic_put_api = (endpoint_url, request) => {
    
    let token = localStorage.getItem('token');

    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', Authorization: 'bearer ' + token },
        withCredentials: true,
        credentials: 'include',
        body: JSON.stringify(request)
    };

    var url = new URL(endpoint_url);

    trackEvent(EventNames.API_REQUEST, {data_1: endpoint_url, data_2: 'PUT'});

    return fetch(url, requestOptions)
    .then(
        (result) => {
            if (result.status === 200 || result.status === 204) {
                return {status: result.status, data: result};  
            }
            else if (result.status === 401) {
                trackEvent(EventNames.TOKEN_ERROR, {data_1: endpoint_url})
                const error = new Error('Error in login');
                error.name = 'LOGIN_ERROR';
                throw error;
            } 
            else if (result.status === 400) {
                return {status: result.status}; 
            } 
            else {
                throw new Error('Error');
            }
        }
    ).then(data=> {return data} );
}

export const generic_delete_api = (endpoint_url, request) => {
    
    let token = localStorage.getItem('token');

    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', Authorization: 'bearer ' + token },
        withCredentials: true,
        credentials: 'include',
        body: JSON.stringify(request)
    };

    var url = new URL(endpoint_url);

    trackEvent(EventNames.API_REQUEST, {data_1: endpoint_url, data_2: 'DELETE'});

    return fetch(url, requestOptions)
    .then(
        (result) => {
            if (result.status == 200 || result.status == 204) {
                return {status: result.status, data: result};  
            }
            else if (result.status == 401) {
                trackEvent(EventNames.TOKEN_ERROR, {data_1: endpoint_url})
                const error = new Error('Error in login');
                error.name = 'LOGIN_ERROR';
                throw error;
            } 
            else {
                throw new Error('Error');
            }
        }
    ).then(data=>data);
}