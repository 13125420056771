/* global google */
// do not delete above line. Needed for google address to function

import { basicAccountInfo } from "api/basic_account_info";
import { generic_get_api, generic_put_api } from "api/generic_api";
import { Component } from "react";
import { Button, Form } from 'react-bootstrap';
import { SF_PLANNING_ADMIN } from 'utils/constants';
import { EventNames, trackEvent } from 'utils/mixpanel';
import { SfPlanningFileTypeAdmin } from 'utils/sf_planning_constants';


class SfPlanningAdmin extends Component {

    constructor(props) {
        super(props);

        this.state = {
            description: '',
            files: [],
            show_all: false,
            record_type: null,
            session_ok: false,
            working: false,
        };
        this.setClassName = this.setClassName.bind(this);
        this.fetchNextFile = this.fetchNextFile.bind(this);
        this.saveFiles = this.saveFiles.bind(this);
        this.setTags = this.setTags.bind(this);
        this.resetEverything = this.resetEverything.bind(this);
        this.setOcr = this.setOcr.bind(this);
        this.textExtraction = this.textExtraction.bind(this);
        this.initiateFirstFetch = this.initiateFirstFetch.bind(this);
    }

    fetchNextFile() {
        this.setState({working: true });

        generic_get_api(SF_PLANNING_ADMIN, {record_type: this.state.record_type})
        .then(
            data => { 
                this.setState({
                    files: data.files,
                    description: data.description,
                    record_number: data.record_number,
                    record_id: data.record_id,
                    working: false,
                })
            }
        )
        .catch(error => {
        });
    }

    resetEverything() {
        this.setState({ files: [] }, this.fetchNextFile);
    }

    initiateFirstFetch(record_type){
        this.setState({ record_type: record_type }, this.fetchNextFile);
    
    }

    saveFiles() {
        this.setState({working: true });
        generic_put_api(SF_PLANNING_ADMIN, 
            { record_id: this.state.record_id, 
                files: this.state.files,
                record_type: this.state.record_type,
            })
            .then(
                (result) => {
                    if(result.status === 204) {
                        this.resetEverything();
                        this.setState({working: false });
                    }
                }
            ).catch(error => {
            });
    }

    setClassName(index, new_class_name, checked) {
        let cur_files = this.state.files;
        let temp_file = cur_files[index];
        temp_file['new_class_name'] = new_class_name;

        this.setState({files: cur_files});
    }

    setOcr(index, checked) {
        let cur_files = this.state.files;
        let temp_file = cur_files[index];
        temp_file['requires_ocr'] = checked;

        this.setState({files: cur_files});
    }

    textExtraction(index, checked) {
        let cur_files = this.state.files;
        let temp_file = cur_files[index];
        temp_file['text_extraction'] = checked;

        this.setState({files: cur_files});
    }

    setTags(index, tags) {
        let cur_files = this.state.files;
        let temp_file = cur_files[index];
        temp_file['tags'] = tags;

        this.setState({files: cur_files});
    }

    componentDidMount() {
        trackEvent(EventNames.PAGE_LOAD, {'data_1': 'planning_code_admin'});
        basicAccountInfo()
        .then(
            data => {
                this.setState({session_ok: true})
            }
        ).catch(error => {
            if (error?.name === 'LOGIN_ERROR') {
                this.props.history.push('/login');
            }
        });

    }

    render() {
        const {files, working} = this.state;

        return(
            <div class="container-fluid sfPlanningAdmin">
                {this.state.session_ok && 
                    <div class="row">
                        <div class="col-auto me-3"><Button variant="secondary" onClick={()=> this.initiateFirstFetch('ZAD')}>ZAD</Button></div>
                        <div class="col-auto me-3"><Button variant="secondary" onClick={()=> this.initiateFirstFetch('VAR')}>VAR</Button></div>
                        <div class="col-auto me-3"><Button variant="secondary" onClick={()=> this.initiateFirstFetch('CUA')}>CUA</Button></div>
                        <div class="col-auto me-3"><Button variant="secondary" onClick={()=> this.initiateFirstFetch('APPEAL')}>APPEAL</Button></div>
                        <div class="col-auto me-3"><Button variant="secondary" onClick={()=> this.initiateFirstFetch('OPP')}>OPP</Button></div>
                        <div class="col-auto me-3"><Button variant="secondary" onClick={()=> this.initiateFirstFetch('HIST')}>HIST</Button></div>
                        <div class="col-auto me-3"><Button variant="secondary" onClick={()=> this.initiateFirstFetch('MISC')}>MISC</Button></div>
                    </div>
                }
                {
                    files && files.length > 0 &&
                    <div class="row mt-4"><div class="col"><Button onClick={this.saveFiles}>Save</Button></div></div>
                }
                {
                    working && 
                    <div class="container mt-5 text-center">
                        <span class="spinner-border" aria-hidden="true"></span>
                    </div>
                }

                <div class="row"><div class="col">{this.state.record_id}</div></div>
                <div class="row"><div class="col">{this.state.record_number}</div></div>
                <div class="row mt-2"><div class="col"><small>{this.state.description}</small></div></div>
                {
                    this.state.files.map((file, file_index) =>{
                        return  <div class="row mt-5">
                                    <div class="col">
                                        <iframe
                                            title="PDF Viewer"
                                            src={file.url}
                                            width="100%"
                                            height="800px" 
                                        />
                                    </div>
                                    <div class="col">
                                        
                                        <div class="row"><div class="col fw-bold"><i class="fa-regular fa-circle-dot fa-sm"></i>&nbsp;&nbsp;{file.file_name}</div></div>
                                        <div class="row"><div class="col fw-bold"><i class="fa-regular fa-circle-dot fa-sm"></i>&nbsp;&nbsp;{file.class_name}</div></div>
                                        <div class="row"><div class="col fw-bold"><i class="fa-regular fa-circle-dot fa-sm"></i>Updated class name: &nbsp;&nbsp;{file.updated_class_name}</div></div>
                                        <div class="row"><div class="col fw-bold"><i class="fa-regular fa-circle-dot fa-sm"></i>&nbsp;&nbsp;{file.record_number}</div></div>
                                        <Form>
                                            <div class="row mt-3 border p-2">
                                                
                                                    { Object.entries(SfPlanningFileTypeAdmin).map( (file_type, index) => {
                                                        return  <div class="col-6">
                                                                    <Form.Check
                                                                        className="ms-2 text-nowrap"
                                                                        name={file_type[0]}
                                                                        type="radio"
                                                                        id={file_type[0]}
                                                                        label={file_type[0]}
                                                                        checked={file_type[0] == file.new_class_name}
                                                                        onChange={(e) => this.setClassName(file_index, file_type[0], e.currentTarget.checked)} 
                                                                        />
                                                                </div>
                                                    })}
                                                
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-6">
                                                    <input type="text" class="form-control" value={file.tags} onChange={(e) => this.setTags(file_index, e.target.value)}/> 
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value="" id="ocr" checked={file.requires_ocr}  onChange={(e) => this.setOcr(file_index, e.currentTarget.checked)}/>
                                                        <label class="form-check-label" for="ocr">
                                                                Requires OCR
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value="" id="text_extract" checked={file.text_extraction} onChange={(e) => this.textExtraction(file_index, e.currentTarget.checked)}/>
                                                        <label class="form-check-label" for="text_extract">
                                                            text_extraction
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                    })
                }
                {
                    files && files.length > 0 &&
                    <div class="row mt-4"><div class="col"><Button onClick={this.saveFiles}>Save</Button></div></div>
                }
            </div>
        )
    }
}

export default SfPlanningAdmin;